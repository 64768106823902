import { 
  useRef,
  useEffect
} from "react"

import "./Checkbox.css"

export const Checkbox = ({state=null, callback=()=>null}) => {
  const isMini = state != null
  const checkbox = useRef(null)
  useEffect(() => {
    if (isMini) {
      checkbox.current.checked = !!state
    }
  }, [state])
  return (
    <div className={`checkbox ${state === 1 ? "checkbox--half" : ""} ${isMini ? "checkbox--mini" : ""}`} onClick={isMini ? null : (event) => {// ${/* state === OTHER_STATE_VALUE ? "checkbox--zero" : "" */} 
      const target = event.currentTarget.firstElementChild
      event.preventDefault()
      target.checked = !target.checked
      callback(target.checked)
      }}>
      <input ref={checkbox} className="checkbox__toggle__checkbox" id="checkbox" type="checkbox"/>
      <label className="checkbox__toggle" htmlFor="checkbox"/>
    </div>
  )
}