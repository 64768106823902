import { 
	createContext, 
	useContext,
	useEffect,
	useState 
} from "react" 

const Globals = createContext()

// Equivalent to [multi-level] callbacks when activated (fully global scope otherwise)
// TODO (maybe): Set as a Util lib method instead
// const getNamesspace = () => window.location.pathname.replace(/[\/]/g, "") || "root"
const getNamesspace = () => window.location.href// -> Full URL as namespace for more granular contexts (e.g. pagination & filtering)

export const useProvideGlobals = (globalsValue) => {

	const [context, setGlobals] = useState(globalsValue)
	
	const set = (key, value, smartnamespace) => {
		let contextCopy = Object.assign({}, context)

		if (smartnamespace) {
			// const namespace = getNamesspace()
			const namespace = typeof smartnamespace == "string" ? smartnamespace : getNamesspace()

			if (!contextCopy.hasOwnProperty(namespace)) contextCopy[namespace] = {}
			contextCopy[namespace][key] = value
		} else {
			contextCopy[key] = value
		}

		// TODO: Support 'bypass re-render' optional set flag (so 'globals.context.campaigns02.checkboxed = [...]' instead of 'setGlobals(contextCopy)') 
		setGlobals(contextCopy)
	}
	const get = (key, smartnamespace) => {
		// if (!key) {// -> Same as 'globals?.context'
		// 	return context// Return the whole object (so it can be listened for changes from any component)
		// }
		const namespace = typeof smartnamespace == "string" ? smartnamespace : getNamesspace()
		return (context.hasOwnProperty(namespace) ? context[namespace] : context)[key]
	}
	
	return {
		context,
    set,
		get
	}
}

// <-- Provide
export const ProvideGlobals = ({ children, globals }) => {
	const context = useProvideGlobals(globals)
	return (
		<Globals.Provider value={context}>
		 {children}
		</Globals.Provider>
	)
}

// --> Use
export const useGlobals = () => useContext(Globals)