import {
  useEffect,
    useRef,
    useState
} from "react"

// Contexts
import {
    useGlobals
} from "contexts"

// Elements
import {
    Heartbeat
} from "elements"

// Hooks
import {
  useFetch
} from "hooks/fetch"

// Utils
import {
  ISO8601
} from "util_v2/UtilityFunctions"

// Routes
import {
  ROUTE_CONTENT,
  ROUTE_API// -> https://demo.gonucleus.io/api 
} from "util/Routes.const"

// import {
//   ROTANA_DEMO_MODE
// } from "util/const"

// Styles
import "./Dropdown.css"

const ROTANA_DEMO_MODE_ = true // -> Manual override

const default_selection = ROTANA_DEMO_MODE_ ? undefined : "_"

export const Dropdown = ({mode, disabled, restart}) => {
    const globals = useGlobals()
    const [opened, setOpened] = useState(0)
    const [loading, isLoading] = useState(false)

    const fetch = useFetch()

    const selection0 = useRef(default_selection)
    // const selection = useRef(default_selection)
    
    const selectedTier = useRef(globals?.context?.dropdown_selection || default_selection)
    const selection2 = useRef()
    const selection3 = useRef()
    const selection4 = useRef()
    const selection5 = useRef()
    const selection6 = useRef()
    const selection7 = useRef()

    // TODO: Relative dates [from "today"] w/ ISO8601()
    // const selectedTierOptions = [
    //   // Mode 0 - Timeframes
    //   {
    //     "_": "All timeframes",
    //     "l0": "Today",
    //     "l7": "Last week",
    //     "l30": "Last month",
    //     "l365": "Last year"
    //   },
    //   // Mode 1 - Groups (group distribution) 
    //   {
    //     "_": "All groups",
    //     "high": "High engagement",
    //     "medium": "Mid engagement",
    //     "low": "Low engagement"
    //   }
    // ][(isNaN(mode) || mode > 1) ? 1 : mode][ROTANA_DEMO_MODE_ ? selectedTier.current : "_"]

    // ...
    const filterName = {
      "tier": {
        "_": "All tiers",
        "high": "High engagement",
        "medium": "Mid engagement",
        "low": "Low engagement"
      }
    }

    const selectedTierOptions = [
      // Mode 1 - Tiers (group distribution) 
      {
        "_": "All tiers",
        "high": "High engagement",
        "medium": "Mid engagement",
        "low": "Low engagement"
      }
    ][0][ROTANA_DEMO_MODE_ ? selectedTier?.current : "_"]

    // TODO: Dynamically build from JSON data
    // const selectedCampaignName_legacyOptions = [
    //   {
    //     "_": "Campaign",
    //     "allcampaigns": "All campaigns",
    //     "justdontquit": "Just Don't Quit",
    //     "justdoit": "Low engagement",
    //     "defaults": "Lego: 2024 Campaign"
    //   }
    // ][0][globals?.context?.dropdown_selection_campaigns/* TODO: Check b/c most likely redundant */ || selectedCampaignName_legacy.current || "_"]

    const campaignsRegistry = globals?.context?.data_json_test?.postcampaigns?.registry || [{all: {title: "Campaign3"}}]
    const campaigns = campaignsRegistry ? Object?.keys(campaignsRegistry) : []

    const selectedCampaignName = useRef(campaignsRegistry?.[globals?.context?.dropdown_selection_campaigns]?.title || campaignsRegistry?.[globals?.context?.dropdown_selection_campaigns]?.dbref /*|| "Campaign0"*/) // campaignsRegistry?.[selectedCampaignName]?.title
    const selectedCampaignCode = useRef("all") // TODO: Merge w/ 'selectedCampaignName'

    // --- API loader (filters) -> Duplicated at Dropdown.jsx b/c we are waiting for the new GraphQL single-call solution suggested by Jeff
    const fetchAll = async () => {

      // Default params
      const params = `?from_date=${ISO8601([2024, 3, 31], 30)}&to_date=${ISO8601()}&tier=all&campaign_id=${selectedCampaignCode.current}&format_fe=1`
    
      // API calls
      const responses = {}
      const endpoints = [
        "overall",
        "devices/chart",
        "devices/table",
        "timing/chart",
        "timing/table",
        "categories/chart",
        "categories/table",
        "locations/chart",
        "locations/table"
      ]
      for (const endpoint of endpoints) {
        const response = await fetch({url: `${ROUTE_API/*${publicKey}*/}${"/post-campaign/" + endpoint}${params}`})
        const endpoint_parts = endpoint.split("/")
        const endpoint_local = endpoint_parts.join("_")
        const payload = response?.payload?.[endpoint_parts?.[0]]

        // ALT 1
        // globals.context.data_json_test.postcampaigns.defaults[endpoint_local] = payload

        // ALT 2 (1/2)
        responses[endpoint_local] = payload
      }
      // ALT 2 (2/2)
      globals.context.data_json_test.postcampaigns.defaults = responses

      return responses 
    }

    useEffect(() => {

      // Overview top campaigns test (TODO: Finish)
      if (globals?.context?.xxx) {
        globals.context.xxx = undefined 
        isLoading(true)
          fetchAll().then(() => {
            isLoading(false)
          })
      }
    }, [restart])

    return (

    // <div className={`dropdown ${loading ? "dropdown--await" : ""} ${!(ROTANA_DEMO_MODE_ && [0, 1, 2, 8].includes(mode)) || disabled ? "dropdown--disabled" : ""} ${ROTANA_DEMO_MODE_ ? "dropdown--temp" : ""} 
      
    <div className={`dropdown ${loading ? "dropdown--await" : ""} ${!ROTANA_DEMO_MODE_ || disabled ? "dropdown--disabled" : ""} ${ROTANA_DEMO_MODE_ ? "dropdown--temp" : ""} 

    dropdown--${(!ROTANA_DEMO_MODE_ ? (selectedTier.current) 
      : (mode === 0 ? "l30"/*<- HARDCODED_UNTIL_MORE_TIMEFRAMES_AVAILABLE) selection0.current*/ 
      : (mode === 8 ? selectedCampaignName.current : (mode === 1 ? selectedTier.current : "undefined"))
      ))} text-xs-b`} 
      onClick={() => setOpened(!opened)}
      onMouseLeave={() => setOpened(false)}
      // style={{"--__number-of-rows": "7"}}
      >
      <div className={"dropdown__gap"}></div>

      {/* Group distribution */}
      {
      mode === 0
      ? 
      <>
        <div className={`dropdown__flag dropdown__flag-${selection0.current}`}></div>

        <span>{ROTANA_DEMO_MODE_ ? (ROUTE_CONTENT ? "This week" : "Last 30 days available") : "..."}</span>
        <div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {/* <div className={`dropdown__flag dropdown__flag-${selection0.current}`}></div>
        <span>{ROTANA_DEMO_MODE_ ? (selection0.current ? selectedTierOptions0 : "Timeframe") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div> */}
        
        {opened ? <div className={`dropdown__table`}>
          
          {ROUTE_CONTENT 
          ?
          <>
            <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l0")}>
              Today
            </div>
            <div className="dropdown__table__row text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l7")}>
              This week
            </div>
            <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l30")}>
              This month
            </div>
            <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l365")}>
              This year
            </div>
          </>
          :
          <>
            <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l0")}>
              Today
            </div>
            <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l7")}>
              Last week
            </div>
            <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l30")}>
              Last month
            </div>
            <div className="text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l30")}>
              Last 30 days available
            </div>
            <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l365")}>
              Last year
            </div>
          </>
          }

        </div> 
        : ""}
      </>
      : 
      mode === 1
      ?
      <>
        <div className={`dropdown__flag dropdown__flag-${selectedTier.current}`}></div>
        <span>{ROTANA_DEMO_MODE_ ? (loading ? "Loading..." : (filterName["tier"][selectedTier.current] || "Tier")) : "Group distribution"}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {/* <span>{loading ? "Loading..." : (filterName["tier"][selectedTier.current] || "Tier")} */}
        {opened ? <div className={`dropdown__table`}>
          <div onClick={() => {
              isLoading(true)
              setTimeout(() => {// -> API call delay simulation (until BE fully ready for 'tier=all/high/medium/low')
                globals.set("dropdown_selection", selectedTier.current = "_")
                isLoading(false)
              }, 500)
            }}>
            <div></div><div className="text-xs">{filterName["tier"]["_"]}</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => {
              isLoading(true)
              setTimeout(() => {// -> API call delay simulation (until BE fully ready for 'tier=all/high/medium/low')
                globals.set("dropdown_selection", selectedTier.current = "high")
                isLoading(false)
              }, 500)
            }}>
            <div></div><div className="text-xs">{filterName["tier"]["high"]}</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => {
              isLoading(true)
              setTimeout(() => {// -> API call delay simulation (until BE fully ready for 'tier=all/high/medium/low')
                globals.set("dropdown_selection", selectedTier.current = "medium")
                isLoading(false)
              }, 500)
            }}>
            <div></div><div className="text-xs">{filterName["tier"]["medium"]}</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => {
              isLoading(true)
              setTimeout(() => {// -> API call delay simulation (until BE fully ready for 'tier=all/high/medium/low')
                globals.set("dropdown_selection", selectedTier.current = "low")
                isLoading(false)
              }, 500)
            }}>
            <div></div><div className="text-xs">{filterName["tier"]["low"]}</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div>
        </div> 
        : ""}
      </>
      : 
      mode === 2
      ?
      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection2.current}`}></div> */}
        <span>{ROTANA_DEMO_MODE_ ? (selection2.current ? selectedTierOptions : "Audience") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>

          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Lorem
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Ipsum
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Dolor
          </div>

        </div> 
        : ""}
      </>
      : 
      mode === 3
      ?
      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection3.current}`}></div> */}
        <span>{ROTANA_DEMO_MODE_ ? (selection3.current ? selectedTierOptions : "Location") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>

          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Lorem
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Ipsum
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Dolor
          </div>

        </div> 
        : ""}
      </>
      : 
      mode === 4
      ?
      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection4.current}`}></div> */}
        <span>{ROTANA_DEMO_MODE_ ? (selection4.current ? selectedTierOptions : "Time"/*OLD:Title*/) : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>

        <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Lorem
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Ipsum
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Dolor
          </div>

        </div> 
        : ""}
      </>
      : 
      mode === 5
      ?
      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection5.current}`}></div> */}
        <span>{ROTANA_DEMO_MODE_ ? (selection5.current ? selectedTierOptions : "IAB") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection5.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection5.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection5.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection5.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      mode === 6
      ?
      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection6.current}`}></div> */}
        <span>{ROTANA_DEMO_MODE_ ? (selection6.current ? selectedTierOptions : "Device") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>

        <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Lorem
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Ipsum
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Dolor
          </div>

        </div> 
        : ""}
      </>
      : 
      mode === 7
      ?
      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection7.current}`}></div> */}
        <span>{ROTANA_DEMO_MODE_ ? (selection7.current ? selectedTierOptions : "Platform") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection7.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection7.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection7.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection7.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      mode === 8
      ? 

      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selectedCampaignName_legacy.current}`}></div> */}
        {/* <span>{loading ? "Loading..." : selectedCampaignName_legacy.current || "Campaign"}</span> */}
        {/* <span>{loading ? "Loading..." : (campaignsRegistry?.[globals?.context?.dropdown_selection_campaigns]?.title || campaignsRegistry?.[globals?.context?.dropdown_selection_campaigns]?.dbref || "Campaign")}</span> */}
        <span>{loading ? "Loading..." : (selectedCampaignName.current || "Campaign")}</span>
        <div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {campaigns?.map((campaign, index) => {
            const label = campaignsRegistry?.[campaign]?.title || campaignsRegistry?.[campaign]?.dbref || campaign
            return (
              <div key={index} className="text-xs" 
                onClick={ () => {
                  isLoading(true)
                  selectedCampaignName.current = label
                  selectedCampaignCode.current = campaign
                  fetchAll().then(() => {
                    globals.set("dropdown_selection_campaigns", campaign)
                    isLoading(false)
                  })
                }
                }>
                {label}
              </div>
            )
          })}
        </div> 
        : ""}
      </>

      : 
      mode === 9
      ? 

      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection7.current}`}></div> */}
        <span>{ROTANA_DEMO_MODE_ ? (selection7.current ? selectedTierOptions : "Subscribers") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>

          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Lorem
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Ipsum
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "...")}>
            Dolor
          </div>

        </div> 
        : ""}
      </>

      : ""
      }
    </div>
    )
}