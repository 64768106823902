import {
    useRef,
    useEffect
} from "react"

import {
    useNavigate
} from "react-router-dom"

import { 
    Insert
} from "elements"

// Styling
import "./Details.css"

// TODO: Part of _adverty.json instead [as a CDN and/or base64 asset] of hardcoded url
// import hardcodedPic from "assets/_temp/campaigns/lego.png"
// import tempLogo from "assets/_temp/logos/Lego.png"
import undefinedPoster from "assets/_temp/campaigns/undefined.png"
import undefinedLogo from "assets/_temp/logos/undefined.png"

export const Details = ({ data, mode }) => {
    const navigate = useNavigate()

    const details = useRef()
    useEffect(() => {
        setTimeout(() => {
            //if (data?.dbref) {
            details.current.classList.add("details--show")
            //}
        }, 0)
    }, [data])


    return (
        <div ref={details} className={"details"}>
            {/* <img src={hardcodedPic} alt={data?.title}/> */}
            {/* <img className={"details-poster__"} src={data?.poster__ || undefinedPoster} alt={data?.title}/> */}
            <img src={data?.image || undefinedPoster} alt={data?.name}/>

            {
            // Classic placeholders for 'Campaigns'
            !mode 
            ? 
            <div className={"details-textbox"}>
                {/* <div className={"text-xxxl-b"}>{data?.brand + ": " + data?.name}</div> */}
                <div className={"text-xxxl-b"}>{data?.title || (data?.dbref?.slice(0, 20) + "...") || "No title"}</div>
                <div className="details-dyn-row details-dyn-row-up text-s-b">
                    <div className={"details-dyn-row-divisor"}>{(data?.period?.[0] || "Und") + " - " + (data?.period?.[1] || "Und") + " 0000"}</div>
                    <div className={"disabled text-s-bb"}>{(data?.gender?.[0] || "No women") + " %"}</div>
                    <div className={"disabled text-xs details-dyn-row-gender details-dyn-row-gender-f"}>F</div>
                    <div className={"disabled text-xs details-dyn-row-gender details-dyn-row-gender-m"}>M</div>
                    <div className={"disabled text-s-bb"}>{(data?.gender?.[1] || "No men") + " %"}</div>
                </div>
                <div className="details-dyn-row details-dyn-row-dn text-s-b">
                    <img src={data?.logo || undefinedLogo} alt={data?.title || "No title"}/>
                    <div className={"details-dyn-row-divisor"}>{data?.brand || "No brand"}</div>
                    {<Insert what={data?.region || "un"} size={18}/>}
                </div>
                <div className="disabled text-s">{data?.description || "No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description"}</div>
            </div>
            :

            // Content genres
            mode === 1 
            ?
            <div className={"details-textbox"}>
                <div className={"text-xxxl-b"}>{data?.title || (data?.dbref?.slice(0, 20) + "...") || "No title"}</div>
                <div className="details-dyn-row details-dyn-row-up text-s-b">
                    {
                    data?.children?.map((item, index) => {
                        return (
                            <div key={index} className={index < data?.children?.length - 1 ? "details-dyn-row-divisor" : ""}>{item}</div>
                        )
                    })
                    }
                </div>
                <div className="text-s">{data?.description || "No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description"}</div>
            </div>
            :

            // Content titles
            mode === 2
            ?
            <div className={"details-textbox"}>
                {/* <div className={"text-xxxl-b"}>{data?.brand + ": " + data?.name}</div> */}
                <div className={"text-xxxl-b"}>{data?.title || (data?.dbref?.slice(0, 20) + "...") || "No title"}</div>
                <div className="details-dyn-row details-dyn-row-up">
                    {
                    data?.parent?.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className={`details-dyn-row-link text-s-b`} onClick={() => {
                                    navigate("/content?genre=" + item.toLowerCase())
                                }}>{item}</div>
                                {index < data?.parent?.length - 1 ? <span>,&nbsp;</span> : <span className="details-dyn-row-divisor text-s-b">&nbsp;</span>}
                            </div>
                        )
                    })
                    }
                    <span className="details-dyn-row-divisor text-s">{data?.type}</span>
                    <span className="text-s">{data?.year}</span>
                </div>
                <div className="details-dyn-row details-dyn-row-dn text-s-b">
                    <img src={data?.networklogo || undefinedLogo} alt={data?.title || "No title"}/>
                    <div className={"details-dyn-row-divisor"}>{data?.network || "No network"}</div>
                    {<Insert what={data?.region || "un"} size={18}/>}
                </div>
                <div className="text-s">{data?.description || "No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description"}</div>
            </div>
            :

            // Audiences
            mode === 3
            ?
            <div className={"details-textbox"}>
                <div className={"text-xxxl-b"}>{data?.name || "No name"}</div>
                <div className="details-dyn-row details-dyn-row-up text-s-b">
                    <div className={"text-s-bb"}>{(data?.percentages?.[0] || "No pct0") + " %"}</div>
                    <div className={"text-xs details-dyn-row-gender details-dyn-row-gender-x"}>$</div>
                    <div className={"text-s-bb"}>{(data?.percentages?.[1] || "No pct1") + " %"}</div>
                    <div className={"text-xs details-dyn-row-gender details-dyn-row-gender-x"}>$</div>
                    <div className={"text-s-bb"}>{(data?.percentages?.[2] || "No pct2") + " %"}</div>
                    <div className={"text-xs details-dyn-row-gender details-dyn-row-gender-m"}>M</div>
                    <div></div>
                </div>
                <div className="text-s">{data?.description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis."}</div>
            </div>
            :

            // Locations
            mode === 4
            ?
            <div className={"details-textbox"}>
                <div className={"text-xxxl-b"}>{data?.name || "No location"}</div>
                <div className="details-dyn-row details-dyn-row-up text-s-b">
                    <div className={"text-s-bb"}>{(data?.gender?.[0] || "No women") + " %"}</div>
                    <div className={"text-xs details-dyn-row-gender details-dyn-row-gender-f"}>F</div>
                    <div className={"text-xs details-dyn-row-gender details-dyn-row-gender-m"}>M</div>
                    <div className={"text-s-bb"}>{(data?.gender?.[1] || "No men") + " %"}</div>
                </div>
                <div className="text-s">{data?.description || "No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description"}</div>
            </div>
            :

            // Timing
            mode === 5
            ?
            <div className={"details-textbox"}>
                {/* <div className={"text-xxxl-b"}>{data?.brand + ": " + data?.name}</div> */}
                <div className={"text-xxxl-b"}>{data?.name || "No time"}</div>
                <div className="details-dyn-row details-dyn-row-up text-s-b">
                    <div className={"details-dyn-row-divisor"}>{(data?.timezone || "Undefined timezone") + " 0000"}</div>
                    <div className={"text-s-bb"}>{(data?.gender?.[0] || "No women") + " %"}</div>
                    <div className={"text-xs details-dyn-row-gender details-dyn-row-gender-f"}>F</div>
                    <div className={"text-xs details-dyn-row-gender details-dyn-row-gender-m"}>M</div>
                    <div className={"text-s-bb"}>{(data?.gender?.[1] || "No men") + " %"}</div>
                </div>
                <div className="text-s">{data?.description || "No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description No description"}</div>
            </div>
            :

            // Devices
            mode === 6
            ?
            <div className={"details-textbox"}>
                <div className={"text-xxxl-b"}>{data?.name || "No name"}</div>
                <div className="details-dyn-row details-dyn-row-up text-s-b">
                    <div className={"text-s-bb"}>{(data?.percentages?.[0] || "No pct0") + " %"}</div>
                    <div className={"text-xs details-dyn-row-gender details-dyn-row-gender-x"}>$</div>
                    <div className={"text-s-bb"}>{(data?.percentages?.[1] || "No pct1") + " %"}</div>
                    <div className={"text-xs details-dyn-row-gender details-dyn-row-gender-x"}>$</div>
                    <div className={"text-s-bb"}>{(data?.percentages?.[2] || "No pct2") + " %"}</div>
                    <div className={"text-xs details-dyn-row-gender details-dyn-row-gender-m"}>M</div>
                    <div></div>
                </div>
                <div className="text-s">{data?.description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis."}</div>
            </div>
            :



            ""
            }
        </div>
    )
}





//     return (
//         <div ref={details} className={"details"}>
//             <img src={hardcodedPic/* data?.image */} alt={data?.name}/>
//             <div className={"details-textbox"}>
//                 {/* <div className={"text-xxxl-b"}>{data?.brand + ": " + data?.name}</div> */}
//                 <div className={"text-xxxl-b"}>{data?.name}</div>
//                 <div className="details-dyn-row details-dyn-row-up text-s-b">
//                     <div className={"details-dyn-row-divisor"}>{data?.period}</div>
//                     <div className={"disabled text-s-bb"}>{data?.gender?.[0] + " %"}</div>
//                     <div className={"disabled text-xs details-dyn-row-gender details-dyn-row-gender-f"}>F</div>
//                     <div className={"disabled text-xs details-dyn-row-gender details-dyn-row-gender-m"}>M</div>
//                     <div className={"disabled text-s-bb"}>{data?.gender?.[1] + " %"}</div>
//                 </div>
//                 <div className="details-dyn-row details-dyn-row-dn text-s-b">
//                     <img src={tempLogo} alt={data?.name}/>
//                     <div className={"details-dyn-row-divisor"}>{data?.brand }</div>
//                     <Insert what={data?.region} size={18}/>
//                 </div>
//                 <div className="disabled text-s">{data?.description}</div>
//             </div>
//         </div>
//     )
// }