// Contexts
import {
  useGlobals
} from "contexts"

// Containers
import { 
  Grid,
  Layout
} from "containers"

// Components
import {
  TableSort,
  ChartMap
} from "components"

import {
  ROTANA_DEMO_MODE
} from "util/const"

// Util
// import {
//   MAP_LOCATIONS
// } from "util/Mappings.const"

// Locations-wide styling
import "./CampaignsLocations.css"

// Locations assembly
export const CampaignsLocations = ({ready}) => {

  // --- Shared ---
  const globals = useGlobals() 

  // Unpack engagement grouping settings
  // const group = globals?.context?.selected?.[0] || "_"
  // const group = globals.get("dropdown_selection") || "_"
  const group = globals?.context?.dropdown_selection || "_"
  
  const jsondata = ROTANA_DEMO_MODE 
  ? 
  globals?.context?.data_json_test?.campaigns
  : 
  globals?.context?.data_json_test?.postcampaigns?.["defaults"/*campaign*/]

  // const picks = globals.get("checkboxed") || ["US", "MX", "IN", "ID", "BR", "FR", "DE", "GB", "IT", "CA", "NL", "CL", "AR", "AU", "BE", "AT", "CH", "AE", "AZ", "CO", "CR"]
  let picks = globals.get("checkboxed") 
  if (!picks) {
    // picks = ready ? Object.keys(MAP_LOCATIONS) : []

    // picks = ready ? jsondata?.locations_table?.[group]?.map((row) => ["Locations", "All", "null", "unknown"].includes(row[0]) ? "" : row[0]) : []
    // console.log("APICKS", apicks)
    
    picks = ready ? jsondata?.locations_table?.[group] : []
    picks = picks?.map((row) => row[0])
    picks = picks?.filter((code) => !["locations", "null"].includes(code))

  }

  let obj_sel = {}
  let arr_dat = []
  if (ready) {
    /*JSON*/let obj_all = ROTANA_DEMO_MODE ? jsondata?.locations_chart : jsondata?.locations_chart?.[group]
    for (let i = 0; i < picks?.length; i++) {

      let code = picks[i].replace("checkboxgroup-", "")//.toUpperCase()
      obj_sel[code] = obj_all[code]
    }

    //arr_dat = rows
    /*JSON*/arr_dat = [...jsondata?.locations_table?.[group]] 
    /*JSON*/// arr_dat = arr_dat?.filter((code) => !["_locations", "Locations", "null"].includes(code[0]))
  }
  // --- Shared ---

  return (

    <Layout header={{
      "title": "Locations",
      "search": ""
    }}>

      {!ROTANA_DEMO_MODE && ![undefined, "allcampaigns"].includes(globals?.context?.dropdown_selection_campaigns) ? <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">
        <span onClick={() => {
          globals.set("dropdown_selection_campaigns", "allcampaigns")
        }}>Locations</span>
        {/* <span>{globals?.context?.data_json_test?.postcampaigns?.overall?.details?.name}</span>
        <span>{obj_top?.name}</span> */}
        {/* <span>{globals?.context?.data_json_test?.postcampaigns?.defaults?.overall?.[group]?.details?.name}</span> */}
        <span>{globals?.context?.data_json_test?.postcampaigns?.defaults?.details?.name}</span>

      </div> : <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">Locations</div>}

      <Grid
        content={[
          <ChartMap 
            data={obj_sel}
            mode={0}
            groupIndex={{"high": 0, "medium": 1, "low": 2}[group]}
            />,
        ]}
        template={`
          0
        `}
        cellSize={{
          w: 800, 
          h: 500
        }}
      />

      <Grid
        content={[
          <TableSort 
            data={arr_dat}
            stick={!!group}
            sort={!!group}
            undim={{"high": 4, "medium": 5, "low": 6}[group]}
            mode={ROTANA_DEMO_MODE ? 2 : 2}
          />
          ]}
        template={`
            0
            `}
        cellSize={{
          w: 800, 
          h: arr_dat?.length * 56 // 950 /* TODO: Number of rows from dataset length [len * rows] instead (vs hardcoded) */
        }}
      />

    </Layout>
)}