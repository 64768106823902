// Elements
import { 
  Counter,
  MeterBar
} from "elements"

// Contexts
// import { 
//   useGlobals
// } from "contexts"

import { ReactComponent as IconClipboard } from "assets/icons_mini/clipboard.svg"

import "./FilterMonitor.css"

export const FilterMonitor = ({sessions=[0, 0, 0], uniques=[0, 0, 0], disabled=false}) => {
  // const globals = useGlobals()

  const campaignID = "ID6f7847f626hg4"// -> Demo only

  // ...
  // const reachData = globals?.context?.data?.reach
  // const [isReady, setReadiness] = useState(false)
  // useEffect(() => {
  //   setReadiness(!!reachData)
  // }, [globals])

  // const [isReady, setReadiness] = useState(false)
  // useEffect(() => {
  //   // setReadiness(!isReady)// -> globals?.context?.data?.reach
  // }, [sessions, uniques])

  return (
    <div className={`filtermonitor ${disabled ? "filtermonitor--disable" : ""}`}>
      <div className="filtermonitor__headerless"></div>
      <div className="filtermonitor__content">
        <div className="filtermonitor__content__header">
          <div>
            <div className="filtermonitor__content__header__subtitle text-s">Estimated results</div>
            <div className="text-m-bb">Reach</div>
          </div>
          <div>
            <div className="filtermonitor__content__header__subtitle text-s">Campaign ID</div>
            <div className="filtermonitor__content__header__clipboard" 
              onMouseDown={(event) => {
                event.preventDefault()
                event.target.classList.add("filtermonitor__content__header__clipboard--active")
                navigator.clipboard.writeText(campaignID)
              }}
              onMouseUp={(event) => {
                event.preventDefault()
                event.target.classList.remove("filtermonitor__content__header__clipboard--active")
              }}
              >
              <div className="filtermonitor__content__header__clipboard__uid text-m-bb">{campaignID} </div>
              <IconClipboard className="filtermonitor__content__header__clipboard__icon"/>
            </div>
          </div>
        </div>
        <div className="filtermonitor__content__body">
          <div className="filtermonitor__counter">
            <div className="text-m">Impressions <i className="filtermonitor__counter__tip">(reach)</i></div>
            <div className="filtermonitor__counter__range text-xxl-b">
              <Counter value={sessions[0]} duration={300}/> - <Counter value={sessions[1]} duration={300}/>
            </div>
          </div>
          <MeterBar value={sessions[1]} benchmark={sessions[2]}/>
          <div className="filtermonitor__counter">
            <div className="text-m">Unique users <i className="filtermonitor__counter__tip">(net reach)</i></div>
            <div className="filtermonitor__counter__range text-xxl-b">
              <Counter value={uniques[0]} duration={300}/> - <Counter value={uniques[1]} duration={300}/>
            </div>
          </div>
        </div>
        <div className="filtermonitor__content__footer filtermonitor__content__footer--disable">
          <div className="filtermonitor__content__footer__subtitle text-xs-b">Campaign name <i className="filtermonitor__counter__tip">(optional)</i></div>
          <input type="text" className="filtermonitor__content__footer__input text-xs" placeholder="e.g. Nike campaign February" 
            // onInput={(event) => console.log("...to namespaced globals...")}
        />
        </div>
      </div>
    </div>
  )
}