/*
  v240513 - Franc Jerez
*/

// Core
import {
  useEffect,
  useState,
  useRef
} from "react"

// Styling
import "./Counter.css"

/* TODO: 
   - ...
*/
// Component
export const Counter = ({value=0, meta, duration=300, delay=0, callback}) => {
  const [counter, setCounter] = useState(0)
  const suffix = useRef("")
  const prefix = useRef("")
  useEffect(() => {
    setTimeout(() => {

      // Animated counter
      const end = parseFloat((value + "").replace(/[^0-9.,-]+/g, ""))
      const start = 0
      let startTimestamp = null
      if (isNaN(+value)) {
        suffix.current = ("" + value).split(end).pop()
        prefix.current = ("" + value).split(end).shift()
      }
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp
        const progress = Math.min((timestamp - startTimestamp) / duration, 1)
        setCounter((progress * (end - start) + start).toFixed(end % 1 ? 1 : 0))
        if (progress < 1) {
          window.requestAnimationFrame(step)
        } else {

          /* Count end callback */
          callback && callback(true)
        }
      }
      window.requestAnimationFrame(step)
    }, delay)

  }, [value, callback, duration, delay])

  return (
    <span className={`counter ${suffix.current ? "counter--smaller" : ""} counter-${meta}`}>
      {prefix.current + Math.abs(counter).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + suffix.current}
    </span>
)}
/* Example
*/