/*
  v240515 - Franc Jerez
*/

import {
  Counter
} from "elements"

// Styling
import "./Score.css"

// Icons
import { ReactComponent as Iconheartbeat } from "assets/icons/small/heartbeat.svg"

/* TODO: 
   - ...
*/
// Component
export const Score = ({value, size=1}) => {
  return (
    <div className={`score score--${value === undefined ? "flip" : ""} score--zoom${size}`}>
      {size ? <Iconheartbeat className="score-icon"/> : ""}
      {value ? <span className={`score__value ${size ? "score__value--pad" : ""} text-xs-b`}>{<Counter value={Math.ceil(value)}/>}</span> : ""}
    </div>
  )
}
/* Example
*/