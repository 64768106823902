/*
  v240513 - Franc Jerez
  v240521 - Franc Jerez - Deep flip fixes
  v240528 - Franc Jerez - Better infix support
*/

// Core
import { 
    useRef,
    useState,
    useEffect
} from "react"

// Elements
import {
    Counter
} from "elements_v2"

// Styling
import "./Bar.css"

// Utils
import {
    Shorten,
    Typeize
} from "util_v2/UtilityFunctions"

/* TODO: 
   - Clip 'setPercentage' to prevent to-narrow-to-round situations (e.g. near-0 & near-100 values)
*/
// Component
export const Bar = ({value, benchmark, animation=500, flip=true}) => {
    const bar = useRef()
    const digit = Typeize(value)
    // const [prefix, suffix] = typeof value === "string" ? value?.split(digit) : ["", ""]
    const [percentage, setPercentage] = useState(false)
        
    useEffect(() => {
        setPercentage((digit / (benchmark || 100)) * 100)
    }, [value, benchmark])

    const format = (number) => {
        const realValue = Shorten(number, 0) + "%"// -> ALT (if smart prefix/suffix support): prefix + Shorten(number, 0) + suffix
        return animation ? <Counter value={realValue}/> : realValue
    }

    const isHalfFull = flip && percentage > 50
    return (
        <div className={`bar-wrapper ${flip ? "bar-wrapper--flip" : ""} ${isHalfFull ? "bar-wrapper--flip-deep" : ""}`}>
            {flip ? <span className={`bar-label text-s`}>{Math.floor(isHalfFull ? percentage : 100 - percentage) + "%"}</span> : ""}
            <div ref={bar} className={`bar`}>
                <span className={`bar__fill ${percentage < 1 ? "bar__fill--hide" : ""}`} style={{
                    "width": percentage + "%",
                    "transitionDuration": animation + "ms"
                }}></span>
                <span className={`bar__base`}></span>
            </div>
            {!flip ? <span className={"bar-label text-s"}>{format(digit)}<span className={`bar-label__total ${!benchmark ? "bar-label__total--hide" : ""}`}> / {format(benchmark)}</span></span> : ""}
        </div>
    )
}
/* Example
  <Bar 
    value={42} 
    benchmark={100}
    animation={500}
    flip={false}
  />
*/