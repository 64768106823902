import { useCallback } from "react"

export const useFetch = () => {
    // useCallback to prevent this function recreated when the component re-renders
    return useCallback(async ({ url, method = "GET", token, body = null, type = "json" }) => {
        try {

            // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
            const rawResponse = await fetch(url, {
                method,
                headers: {
                    //"Content-Type": "text/csv",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: body ? JSON.stringify(body) : null
            })
            if (type === "json") {
                return rawResponse.json()
            } else 
            if (type === "text") {
                return rawResponse.text()
            } else 
            if (type === "blob") {
                return rawResponse.blob()
            } else 
            if (type === "raw") {
                return rawResponse 
            } else {// Just download the file (as a blob)
                rawResponse.blob().then((blob) => {
                    const aElement = document.createElement("a")
                    aElement.href = URL.createObjectURL(blob)
                    aElement.download = `${type}.csv`

                    // vB
                    aElement.click()

                    // // vA (not needed)
                    // aElement.style.display = "none"
                    // document.body.appendChild(a)
                    // aElement.click()
                    // document.body.removeChild(a)
                    // window.URL.revokeObjectURL(url)
                }).catch((error) => console.error("error", error))
            }

        } catch (error) {
            console.error("error", error)
        }
    }, [])
}