import {
  useRef,
  useState,
  useEffect
} from "react"

// import {
//   useNavigate,
//   useSearchParams
// } from "react-router-dom"

// Containers
import { 
  Layout,
  Grid
} from "containers"

// Components
// import { 
//   Topbar,
//   Wheel,
//   ChartBars,
//   TableSort,
//   Details,
//   PieArc,
//   WheelAltFull,
//   ChartHistory,
//   ChartLine
// } from "components"
import { 
  Table
} from "components_v2"

// Elements
import {
  CheckboxGroup
} from "elements"
import { 
  Tick,
  Score,
  Orb,
  Bar
} from "elements_v2"

// Contexts
import {
  useGlobals
} from "contexts"

// Utils
import {
  Commaize,
  Upperize
} from "util_v2/UtilityFunctions"

// Home-wide styling
import "./Summary.css"

// Icons
import { ReactComponent as TweakIcon } from "assets/icons_mini/tweak.svg"

// Campaigns assembly
export const Summary = ({ready, query}) => {
  // const [tableConfigurator, showTableConfigurator] = useState()
  // const [searchParams, setSearchParams] = useSearchParams()
  // const [more, seeMore] = useState([false, false])

  const [tabIndex, setTab] = useState()
  const tabs = ["Title", "Location", "Audience", "Genre", "IAB", "Timing", "Device"]
  const tab_ = tabs?.[tabIndex] || ""
  const cols = [tab_/* <- This one depends */, "Total visits", "Unique visits", "Engagement score", "Avg watch time", "New subscribers", "Paid users", "Performance"]

  // Control how autoscroll behaves
  const previousUrl = useRef()
  useEffect(() => {
    previousUrl.current = window.location.href
    setTab(0)
  }, [window.location.href])
  const urlChange =  window.location.href != previousUrl.current

  // Get raw response data
  const globals = useGlobals()
  const rawRows = tabIndex !== undefined ? globals?.context?.data_json_test?.summary?.[tab_?.toLowerCase() + "s"] : []
  const rawColsLabels = cols?.map(col => col?.replaceAll(" ", "_").toLowerCase())

  // Recall user chosen columns (all by default)
  const picks = {}
  for (const section of ["summary_table"]) {
    picks[section] = globals.get("checkboxed", section)?.map((pick) => pick.replace("checkboxgroup-", "")) || [...rawColsLabels].slice(1)
  }

  // Enrich raw response data
  const formattedRows = []

  // ...
  const pickedCols = picks?.summary_table
  const isPicked = (itemIndex) => {
    return pickedCols?.includes(rawColsLabels[itemIndex])
  }


  // TODO: Check enabled cols once (globally vs per row)
  rawRows?.map((item, index) => {
    formattedRows.push([
      <Orb 
        what={{
          name: item?.name,
          link: item?.link,
          icon: item?.icon
      }}/>, 
      !isPicked(1) ? null : Commaize(200283), 
      !isPicked(2) ? null : Commaize(200283), 
      !isPicked(3) ? null : <Score 
        value={28}
        animation={true}
      />, 
      !isPicked(4) ? null : 180 + "s", 
      !isPicked(5) ? null : 164 + "%",
      !isPicked(6) ? null : <Bar 
        value={52 + "%"}
        flip={false}
      />,
      !isPicked(7) ? null : <Tick 
        value={"Good"}
        tag={4}
        dot={true}
      />
    ].filter(Boolean))
  })

  // TODO: Build a reusable 'report' wrapper template out of this
  return (

      <Layout autoscroll={urlChange} header={{
        "title": [""], 
        "search": "breadcrumbs-temp"
      }}>

        <Grid
          content={[
            <div></div>
          ]}
          template={`
              0
              `}
          cellSize={{
            w: 320,
            h: 240
          }}
        />

        {/*!undefinedSummary ||*/ false ? "" : <Grid
        content={[
          <div className="summary__minibox-wrapper">
            <div className="summary__minibox-tabs text-m">
              {tabs.map((title, index) => <span key={index} className={`summary__minibox-tabs__tab--${index === tabIndex ? "on" : "off"}`} 
                onClick={() => setTab(index)}
              >{title + "s"}</span>)}
            </div>
            <div className="summary__minibox-head text-xs-b">
              <span className="summary__minibox-head__text">{tab_} performance</span>
              <div className="summary__minibox-head__icon" 
                onClick={(event) => {
                  // event.preventDefault()
                  // event.stopPropagation()
                  event.currentTarget.lastElementChild.classList.toggle("summary__table--show")// ALT: 'showTableConfigurator(!tableConfigurator)' w/ '${tableConfigurator ? "summary__table--show" : ""}'
                }}
              >
                <TweakIcon/>
                <div className={`summary__table summary__table__sub text-xs`}
                  onMouseLeave={(event) => {
                    event.currentTarget.classList.remove("summary__table--show")
                  }}
                >
                  <div className="summary__table__pick-meta">
                    <TweakIcon/>
                    <span className="text-xs-b">Customize table</span>
                  </div>
                  <div  
                    className={"summary__table__pick-head text-xs-b"}
                    onClick={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      // event.currentTarget.classList.toggle("summary__table-arrow--flip")
                      // event.currentTarget.nextElementSibling.classList.toggle("summary__table__pick-body--fold")
                      // event.currentTarget.parentElement.classList.toggle("summary__table--fold")
                    }}>
                      <span>Columns</span>
                      {/* <div className={"summary__table-arrow summary__table-arrow-" + (true ? "up" : "dn")}></div> */}
                    </div>
                  <div className={"summary__table__pick-body"}>
                    {cols.map((item, index) => index ? <CheckboxGroup 
                      key={index} 
                      className="summary__table__row" 
                      label={cols[index]} 
                      checked={isPicked(index)} 
                      namespace={"summary_table"} 
                      highlight={1}
                    /> : ""
                  )}
                  </div>
                </div>
              </div>
            </div>
            <Table
              head={{
                // "ticks": [],
                "width": [.2].concat(new Array(pickedCols?.length).fill(.7 / pickedCols?.length)),//[.2, .1, .1, .1, .1, .1, .1, .1],
                "place": (new Array(pickedCols?.length).fill("l")).concat(["r"]),//["l", "l", "l", "l", "l", "l", "l", "r"],
                "names": [tab_].concat(rawColsLabels.map((label, index) => pickedCols.includes(label) ? cols[index] : null)).filter(Boolean)//cols
              }}
              body={{
                "stick": false,
                // "lines": ready ? formattedRows : [],
                "lines": formattedRows,
                "limit": tabIndex === 3 ? 3 : 1
              }}
              foot={{
                "tails": 2
              }}
            />
          </div>
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 320,
          h: 0
        }}
      />}

    </Layout>
)}