import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

import {
  ENABLE_STRICT_MODE,
  APP_TYPE
} from "util/const"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(

  // Double rendering if StrictMode on [while on development mode]
  ENABLE_STRICT_MODE 
    ? 
    <React.StrictMode>  
      <App />
    </React.StrictMode>
    : 
    <App />
  )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()