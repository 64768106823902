/*
  v240516 - Franc Jerez
  v240521 - Franc Jerez - Multiple minor fixes
  v240530 - Franc Jerez - Pagination system revamp (as per new Irene's design)
*/

// Core
import {
  useRef,
  useState,
  useEffect
} from "react"

// Elements
import {
  Tick,
  Button
} from "elements_v2"

// Utils
import {
  CSSize,
  Typeize
} from "util_v2/UtilityFunctions"

// Styling
import "./Table.css"

// Pagination references set
const pages = {}

/* TODO:
   - Mobile support
   - Support for add/del rows from within the component's DOM (instead of rebuilding everything from the outside)
   - Consider 'useMemo' instead of external 'pages' object reference
*/
// Component
export const Table = ({head, body, foot}) => {
  const [_, reRender] = useState()
  const tableElement = useRef()
  const maxRows = body?.limit
  const maxTail = foot?.tails

  // Feature 240516: Pagination
  const switchPage = (direction) => {
    const liveRows = body?.lines?.length
    if (!maxRows) return
    if (!liveRows) pages.current = 1
    
    // How many pages do we need
    const neededPages = Math.ceil(liveRows / maxRows) || 1

    // Build range for the current table view
    const init = direction 

      // On navigation [prev/next] button clicked
      ? pages.init + (maxRows * direction)

      // On rowset change (from 'body.lines')
      : maxRows * ((pages.init == liveRows/*[1]*/ ? neededPages : pages.current) - 1)

    const end = Math.min(init + maxRows, liveRows)
    // [1] Last row available [after deletion] equals start of range (so current page is empty/overflown now [& needs to rewind/forward 'maxRows'])
    
    // Pagination states
    pages.current = (init / maxRows) + 1
    pages.total = neededPages
    pages.init = init
    pages.end = end

    // Reload states
    reRender(Date.now())
  }

  // Onload & on rowset change
  useEffect(() => {
    switchPage()
  // }, [foot.split, body.lines])
  }, [body.limit, body.lines])

  // Feature 240514: Sorting
  const sortThisColumn = (tdIndex) => {
    const [tHead, tBody, tFoot] = tableElement.current?.children
    const headTDs = tHead?.firstElementChild.children

    // Set default states for every column
    const thisTD = headTDs[tdIndex]
    for (const td of headTDs) {
      const deselected = td !== thisTD
      td.classList[deselected  ? "remove" : "add"]("table__thead__tr__td--show")

      // Forget previous sorting state (remove this line to remember instead) 
      if (deselected) td.classList.remove("table__thead__tr__td--flip")
    }
    let isSorted = thisTD.classList.toggle("table__thead__tr__td--flip")

    // Flip shown sorting criteria if numbers (i.e. always start with the highest)
    if (typeof Typeize(tBody.firstElementChild?.children?.[tdIndex]?.textContent) === "number") {
      isSorted = !isSorted
    }

    // Sort row nodes
    let tBodyChildren = [...tBody.children/*Rows*/]
    body?.stick && tBodyChildren.shift()// -> Ignore the first 'sticky' row
    tBodyChildren.sort((a, b) => {

      // Get sortable cell contents (& set the right type to be sorted at the same time)
      const a_= Typeize(a.children[tdIndex/*Cell*/].textContent) 
      const b_= Typeize(b.children[tdIndex/*Cell*/].textContent)

      // Sort any type of content (direction depends of previous state)
      const [_a, _b] = isSorted ? [-1, 1] : [1, -1]
      return (a_ === b_ ? 0 : (a_ < b_ ? _a : _b)) 

    // Populate the DOM
    }).forEach(item => tBody.appendChild(item))
  }

  return (
    <div className={"table-wrapper"}>
      <div ref={tableElement} className={`table ${body?.stick ? "table--stick" : ""}`}>
        {/* Optional [yet limited and/or quirky as in no-pct-for-width styling] */}
        {/* {head?.map((_, index) => 
          <div key={index} className="table__column"/> // style={{"sizes": sizes?.[index] < 1 ? sizes?.[index] * 100 : (sizes?.[index])}}></div>
        )} */}

        {/* Head */}
        <div className="table__thead text-s-b">
          <div className="table__tr table__thead__tr">
            {head?.names?.map((item, index) => {
              const position = head?.place[index]
              return (
              <div 
                key={index} 
                onClick={() => sortThisColumn(index)}
                className={`table__td table__thead__tr__td`} 
                style={{"--table-column-width": CSSize(/*sizes?*/head?.width?.[index])}}>
                <div className={`table__lining table__thead__tr__td__lining table__thead__tr__td__lining--${position} text-s`}>
                    <div className="table__thead__tr__td__lining__text">
                      {position === "l" ? <span>{item}</span> : ""}
                      <span className="table__thead__tr__td__lining__u2d text-xs"> ↓ </span>
                      <span className="table__thead__tr__td__lining__d2u text-xs"> ↑ </span>
                      {position === "r" ? <span>{item}</span> : ""}
                    </div>
                    {typeof head?.ticks?.[index] === "number"
                    ?
                    <div className="table__thead__tr__td__lining__tick">
                      <Tick value={head?.ticks?.[index] + "%"}/>
                    </div> 
                    : 
                    ""
                    }
                </div>
              </div>
            )
          })}
          </div>
        </div>

        {/* Body */}
        <div className="table__tbody">
        {(maxRows ? body?.lines?.slice(pages?.init, pages?.end) : body?.lines).map((item, index) => { 
            return index > pages.start ? "" : (
              <div key={index} className="table__tr table__tbody__tr text-m">
                {item?.map((subitem, subindex) => 
                  <div key={subindex} className={`table__td table__tbody__tr__td`}>
                    <div className={`table__lining table__tbody__tr__td__lining table__tbody__tr__td__lining--${head?.place[subindex]}`}>{subitem}</div>
                  </div>
                )}
              </div>
            )})}
        </div>

        {/* Foot */}
        <div className="table__tfoot text-s">
          <div className={`table__tr table__tfoot__tr ${maxRows ? "" : "table__tfoot__tr--hide"}`}>
            {(new Array(body?.limit).fill("Page " + pages?.current + " of " + pages?.total))?.map((item, index) => 
              <div key={index} className="table__td table__tfoot__tr__td">
                <div className={"table__lining table__tfoot__tr__td__lining"}>
                  <div><Button text={"← Prev"} 
                    inverted={true} 
                    shrunken={true} 
                    disabled={pages?.current == 1}
                    cbClick={() => {switchPage(-1)}}
                  /></div>
                  <span className={"table__tfoot__tr__td__lining__text"}>
                    {!maxTail ? <span>{item}</span> : (new Array(pages?.total).fill(pages?.current).map((itm, idx) => {
                      const inumber = idx + 1
                      const current = inumber == itm
                      const nonpage = idx !== Math.floor(pages?.total * .5)// -> Only show the "..." ellipsis middle mark if page number hidden
                      return true //idx < maxTail || idx >= (pages?.total - maxTail) 
                        
                        // Default natural behaviour
                        ? 
                        <span key={idx} 
                          className={`table__tfoot__tr__td__lining__text__unit ${current ? "table__tfoot__tr__td__lining__text__unit--selected" : ""}`}
                          onClick={() => current ? null : switchPage(inumber - pages.current)}
                        >
                        {inumber}
                        </span> 

                        // Ellipsis support for long page collections
                        : 
                        <span key={idx} 
                          className={`table__tfoot__tr__td__lining__text__unit ${nonpage ? "table__tfoot__tr__td__lining__text__unit--disabled" : ""}`}>
                          ...
                        </span>
                    }))}
                  </span>
                  <div><Button 
                    text={"Next →"} 
                    inverted={true} 
                    shrunken={true}
                    disabled={pages?.current == pages?.total}
                    cbClick={() => {switchPage(1)}}
                  /></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
/* Example
  <Table
    head={{
      "ticks": [42, null, -42, null, 0],
      "width": [200, 80, 120, .75, 110],
      "place": ["l", "r", "c", "l", "r"],
      "names": ["Lorem", "Ipsum", "Dolor", "Sit", "Amet"]
    }}
    body={{
      "stick": true,
      "lines": ready ? [...] : []
      "limit": 3
    }}
    foot={{
      "tails": Infinity
    }}
  />
*/




















// // Legacy
// /*
//   v240516 - Franc Jerez
//   v240521 - Franc Jerez - Multiple minor fixes
// */

// // Core
// import {
//   useRef,
//   useState,
//   useEffect
// } from "react"

// // Elements
// import {
//   Tick,
//   Button
// } from "elements_v2"

// // Utils
// import {
//   CSSize,
//   Typeize
// } from "util_v2/UtilityFunctions"

// // Styling
// import "./Table.css"

// // Pagination references set
// const pages = {
//   current: 1,
//   total: 1,
//   init: 1,
//   end: 1
// }

// /* TODO:
//    - Mobile support
//    - Support for add/del rows from within the component's DOM (instead of rebuilding everything from the outside)
//    - Consider 'useMemo' instead of external 'pages' object reference
// */
// // Component
// export const Table = ({head, body, foot}) => {
//   const [_, reRender] = useState()
//   const tableElement = useRef()

//   // Feature 240516: Pagination
//   const switchPage = (direction) => {
//     const maxRows = body?.limit
//     const liveRows = body?.lines?.length
//     if (!maxRows || !liveRows || !foot?.split) return

//     // How many pages do we need
//     const neededPages = Math.ceil(liveRows / maxRows) 

//     // Build range for the current table view
//     const init = direction 

//       // On navigation [prev/next] button clicked
//       ? pages.init + (maxRows * direction) 

//       // On rowset change (from 'body.lines')
//       : maxRows * ((pages.init == liveRows/*[1]*/ ? neededPages : pages.current) - 1)

//     const end = Math.min(init + maxRows, liveRows)
//     // [1] Last row available [after deletion] equals start of range (so current page is empty/overflown now [& needs to rewind/forward 'maxRows'])
    
//     // Pagination states
//     pages.current = (init / maxRows) + 1
//     pages.total = neededPages
//     pages.init = init
//     pages.end = end

//     // Reload states
//     reRender(Date.now())
//   }

//   // Onload & on rowset change
//   useEffect(() => {
//     switchPage(0)
//   }, [foot.split, body.lines])

//   // Feature 240514: Sorting
//   const sortThisColumn = (tdIndex) => {
//     const [tHead, tBody, tFoot] = tableElement.current?.children
//     const headTDs = tHead?.firstElementChild.children

//     // Set default states for every column
//     const thisTD = headTDs[tdIndex]
//     for (const td of headTDs) {
//       const deselected = td !== thisTD
//       td.classList[deselected  ? "remove" : "add"]("table__thead__tr__td--show")

//       // Forget previous sorting state (remove this line to remember instead) 
//       if (deselected) td.classList.remove("table__thead__tr__td--flip")
//     }
//     let isSorted = thisTD.classList.toggle("table__thead__tr__td--flip")

//     // Flip shown sorting criteria if numbers (i.e. always start with the highest)
//     if (typeof Typeize(tBody.firstElementChild?.children?.[tdIndex]?.textContent) === "number") {
//       isSorted = !isSorted
//     }

//     // Sort row nodes
//     let tBodyChildren = [...tBody.children/*Rows*/]
//     body?.stick && tBodyChildren.shift()// -> Ignore the first 'sticky' row
//     tBodyChildren.sort((a, b) => {

//       // Get sortable cell contents (& set the right type to be sorted at the same time)
//       const a_= Typeize(a.children[tdIndex/*Cell*/].textContent) 
//       const b_= Typeize(b.children[tdIndex/*Cell*/].textContent)

//       // Sort any type of content (direction depends of previous state)
//       const [_a, _b] = isSorted ? [-1, 1] : [1, -1]
//       return (a_ === b_ ? 0 : (a_ < b_ ? _a : _b)) 

//     // Populate the DOM
//     }).forEach(item => tBody.appendChild(item))
//   }

//   return (
//     <div className={"table-wrapper"}>
//       <div ref={tableElement} className={`table ${body?.stick ? "table--stick" : ""}`}>
//         {/* Optional [yet limited and/or quirky as in no-pct-for-width styling] */}
//         {/* {head?.map((_, index) => 
//           <div key={index} className="table__column"/> // style={{"sizes": sizes?.[index] < 1 ? sizes?.[index] * 100 : (sizes?.[index])}}></div>
//         )} */}

//         {/* Head */}
//         <div className="table__thead text-s-b">
//           <div className="table__tr table__thead__tr">
//             {head?.names?.map((item, index) => {
//               const position = head?.place[index]
//               return (
//               <div 
//                 key={index} 
//                 onClick={() => sortThisColumn(index)}
//                 className={`table__td table__thead__tr__td`} 
//                 style={{"--table-column-width": CSSize(/*sizes?*/head?.width?.[index])}}>
//                 <div className={`table__lining table__thead__tr__td__lining table__thead__tr__td__lining--${position} text-s`}>
//                     <div className="table__thead__tr__td__lining__text">
//                       {position === "l" ? <span>{item}</span> : ""}
//                       <span className="table__thead__tr__td__lining__u2d text-xs"> ↓ </span>
//                       <span className="table__thead__tr__td__lining__d2u text-xs"> ↑ </span>
//                       {position === "r" ? <span>{item}</span> : ""}
//                     </div>
//                     {typeof head?.ticks?.[index] === "number"
//                     ?
//                     <div className="table__thead__tr__td__lining__tick">
//                       <Tick value={head?.ticks?.[index] + "%"}/>
//                     </div> 
//                     : 
//                     ""
//                     }
//                 </div>
//               </div>
//             )
//           })}
//           </div>
//         </div>

//         {/* Body */}
//         <div className="table__tbody">
//         {(foot?.split ? body?.lines?.slice(pages?.init, pages?.end) : body?.lines).map((item, index) => { 
//             return index > pages.start ? "" : (
//               <div key={index} className="table__tr table__tbody__tr text-m">
//                 {item?.map((subitem, subindex) => 
//                   <div key={subindex} className={`table__td table__tbody__tr__td`}>
//                     <div className={`table__lining table__tbody__tr__td__lining table__tbody__tr__td__lining--${head?.place[subindex]}`}>{subitem}</div>
//                   </div>
//                 )}
//               </div>
//             )})}
//         </div>

//         {/* Foot */}
//         <div className="table__tfoot text-s">
//           <div className={`table__tr table__tfoot__tr ${foot?.split ? "" : "table__tfoot__tr--hide"}`}>
            
            
            
//             {(new Array(body?.limit).fill(        "Page " + pages?.current + " of " + pages?.total          )     )?.map((item, index) => 
//               <div key={index} className="table__td table__tfoot__tr__td">
//                 <div className={"table__lining table__tfoot__tr__td__lining"}>
//                   <div><Button text={"← Prev"} 
//                     inverted={true} 
//                     shrunken={true} 
//                     disabled={pages?.current == 1}
//                     cbClick={() => {switchPage(-1)}}
//                   /></div>

//                   {/* mode 2 */}
//                   <span className={"table__tfoot__tr__td__lining__text"}>
//                     {(new Array(pages?.total).fill(pages?.current).map((itm, idx) => {
//                       const sidesSize = 2
//                       return ((pages?.total * .5) % 2) && (idx >= (pages?.total - sidesSize) || idx < sidesSize) ? <span>{(idx + 1) + "(" + itm + ")"}</span> : ((idx == Math.floor(pages?.total * .5))) ? "..." : ""
//                     }))}
//                   </span>

//                   <div><Button 
//                     text={"Next →"} 
//                     inverted={true} 
//                     shrunken={true}
//                     disabled={pages?.current == pages?.total}
//                     cbClick={() => {switchPage(1)}}
//                   /></div>
//                 </div>
//               </div>
//             )}



//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
// /* Example
//   <Table
//     head={{
//       "ticks": [42, null, -42, null, 0],
//       "width": [200, 80, 120, .75, 110],
//       "place": ["l", "r", "c", "l", "r"],
//       "names": ["Lorem", "Ipsum", "Dolor", "Sit", "Amet"]
//     }}
//     body={{
//       "stick": true,
//       "lines": ready ? [...] : []
//       "limit": 4
//     }}
//     foot={{
//       "split": true
//     }}
//   />
// */

// // // Legacy
// // {(new Array(body?.limit).fill("Page " + pages?.current + " of " + pages?.total))?.map((item, index) => 
// //   <div key={index} className="table__td table__tfoot__tr__td">
// //     <div className={"table__lining table__tfoot__tr__td__lining"}>
// //       {/* <div className={`${pages?.current == 1 ? "table__tfoot__tr__td__lining__page--disable_" : ""}`}><Button text={"← Prev"}  */}
// //       <div><Button text={"← Prev"} 
// //         inverted={true} 
// //         shrunken={true} 
// //         disabled={pages?.current == 1}
// //         cbClick={() => {switchPage(-1)}}
// //         /></div>
// //       <span>{item}</span>
// //       {/* <div className={`${pages?.current == pages?.total ? "table__tfoot__tr__td__lining__page--disable_" : ""}`}><Button  */}
// //       <div><Button 
// //         text={"Next →"} 
// //         inverted={true} 
// //         shrunken={true}
// //         disabled={pages?.current == pages?.total}
// //         cbClick={() => {switchPage(1)}}
// //         /></div>
// //     </div>
// //   </div>
// // )}
