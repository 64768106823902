import "./Breadcrumbs.css"

export const Breadcrumbs = ({sequence, step}) => {
    return (
        <div className={"breadcrumbs text-s"}>
            {sequence.map((label, index) => {
                return (
                    <div key={index} className={`breadcrumbs__step breadcrumbs__step--${(index === step) ? "active" : (index > step ? "disabled" : "")}`}>
                        <div><div></div></div>
                        <div>{label}</div>
                    </div>
                )
            })}
        </div>
    )
}