import { 
    useRef,
    useState,
    useEffect
} from "react"

// Styles
import "./MeterBar.css"

let percentage, label, total
export const MeterBar = ({value, benchmark}) => {
    percentage = (value / (benchmark || (value * 2))) * 100

    // TODO: Clean/refactor (make smarter)
    if (!percentage) {label = ""; total = 0}
    else if (percentage < 25) {label = "Narrow"; total = 25}
    else if (percentage < 50) {label = "Fairly narrow"; total = 50}
    else if (percentage < 75) {label = "Fairly broad"; total = 75}
    else {label = "Broad"; total = 100}

    const meterBar = useRef()
    // const [_, setReadiness] = useState(false)
    useEffect(() => {
        // setReadiness(value && benchmark)

        setTimeout(() => {
          meterBar.current.style.setProperty("--__gradientbar__after__width", total + "%")
        })
      }, [value, benchmark])

    // OLD: label = percentage < 25 ? "Narrow" : (percentage < 50 ? "Fairly narrow" : (percentage < 75 ? "Fairly broad" : "Broad"))

    return (
        <div ref={meterBar} className="meterbar">
            <div className={`meterbar__gradient ${total ? "" : "meterbar__gradient--hide"}`}></div>
            <div className={`meterbar__flag ${total === 100 ? "meterbar__flag__limit" : ""} text-xs`}>{label}</div>
        </div>
    )
}