import { 
  Counter,
  Tick
} from "elements"

import "./Topbar.css"

// Get float even if the string'd number starts with a non-digit character
const getFloat = (value) => parseFloat((value + "").replace(/[^0-9.,-]+/g, ""))

// Compute deltas (dummy/preliminary version)
const getDelta = (item) => {
  const num1 = getFloat(item?.data?.[0])
  const num2 = getFloat(item?.data?.[item?.data?.length - 1])

  // v2 - Ticks are always expected to be "%"
  return Math.floor((num1 - num2) / (num2 / 100))
  
  // v1
  // if (!item.unit || item.unit === "%") {
  //   return Math.floor((num1 - num2) / (num2 / 100))
  // }
  // return num1 - num2
}
// let delta = 0

// ------------------------
// <Topbar data={[
//   {
//     "name": "Chip label",
//     "data": [78553/* new */, 71411 /* pre */],// -> Strings (as in "$24") are supported if so the tick unit can be processed separately
//     "unit": "$"// -> Or "%" (default value for the ticket if empty) or "M" (for millions) and so on
//   }
// ]}/>
// ------------------------
export const Topbar = ({data}) => {
  return (
    <div className="topbar">
      {data?.map((item, index) => {
        const prefix = ["$"].includes(item.unit) ? item.unit : ""
        const suffix = ["%", "M", "s"].includes(item.unit) ? item.unit : ""
        const delta = getDelta(item) || 0
        return (
          <div key={index} className="topbar__chip">
            <div className="topbar__chip__label text-xs-b">
              {item.name}
            </div>
            <div className="topbar__chip__data">
              <span className="text-xxl-b">
                {/* <Counter value={prefix + item?.data?.[1] + suffix} meta={!delta ? undefined : delta > 0}/> */}
                <Counter value={prefix + item?.data?.[1] + suffix} meta={undefined}/>
              </span>
              {/* {![undefined, null].includes(item?.data?.[0]) ? <span><Tick value={prefix + delta + suffix + (!item.unit ? "%" : "")}/></span> : ""} */}
              {/* {![undefined, null].includes(item?.data?.[0]) ? <span><Tick value={prefix + delta + suffix}/></span> : ""} */}
              {delta ? <span><Tick value={prefix + delta + "%"}/></span> : ""}
            </div>
          </div>
        )
      })}
    </div>
  )
}