// Contexts
import {
  useGlobals
} from "contexts"

// Containers
import { 
    Grid,
    Layout
} from "containers"

// Components
import {
  TableSort,
  Chart
} from "components"

import {
  ROTANA_DEMO_MODE
} from "util/const"

// Devices-wide styling
import "./CampaignsDevices.css"

// Remember first access (& avoid re-rendering)
// let firstAccess = true

// Devices assembly
export const CampaignsDevices = ({ready}) => {

  // --- Shared ---
  const globals = useGlobals() 

  // Unpack engagement grouping settings
  // const group = globals?.context?.selected?.[0] || "_"
  // const group = globals.get("dropdown_selection") || "_"
  const group = globals?.context?.dropdown_selection || "_"

  // TODO: Prevent making this mapping redundant (we should either use "Foo Bar" formats at all times for labeling or have some kind of external mapping file for all components)
  const names = {
    // "windows_pc": "Windows PC",
    "android": "Android",
    "other_device": "Other device",
    "iphone": "iPhone",
    "windows_pc": "Windows PC",
    "unknown_device": "Unknown Device",
    "macos": "MacOS"
  }


  const jsondata = ROTANA_DEMO_MODE 
  ? 
  globals?.context?.data_json_test?.campaigns
  : 
  globals?.context?.data_json_test?.postcampaigns?.["defaults"/*campaign*/]


  // const arr_all_obj = ROTANA_DEMO_MODE ? jsondata?.categories_chart?.[group] : jsondata?.categories_chart
  const arr_all_obj = jsondata?.devices_chart?.[group] || []
  const picks = globals.get("checkboxed") || (

      /* First access */
      ROTANA_DEMO_MODE 
      ?
      ["android", "macos", "unknown_device", "windows_pc", "iphone"]
      :
      // ["android", "other_device", "iphone"]
      Object.keys(arr_all_obj)
      )

  let txt_sel = []
  let arr_sel = []
  let arr_dat = []
  if (ready) {
    // /*JSON*/const arr_all_obj = ROTANA_DEMO_MODE ? jsondata?.devices_chart?.[group] : jsondata?.devices_chart
    /*JSON*///const arr_all_obj = jsondata?.devices_chart?.[group]

    for (let i = 0; i < picks?.length; i++) {
      let text = names[picks[i].replaceAll("checkboxgroup-", "")]
      txt_sel.push(text)
      arr_sel.push(arr_all_obj[text?.toLowerCase().replaceAll(" ", "_")])
    }
    // arr_dat = rows
    /*JSON*/ arr_dat = [...jsondata?.devices_table?.[group]]
  }
  // --- Shared ---

  return (

    <Layout header={{
      "title": "Devices",
      "search": ""
    }}>

      {!ROTANA_DEMO_MODE && ![undefined, "allcampaigns"].includes(globals?.context?.dropdown_selection_campaigns) ? <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">
        <span onClick={() => {
          globals.set("dropdown_selection_campaigns", "allcampaigns")
        }}>Devices</span>
        {/* <span>{globals?.context?.data_json_test?.postcampaigns?.overall?.details?.name}</span>
        <span>{obj_top?.name}</span> */}
        {/* <span>{globals?.context?.data_json_test?.postcampaigns?.defaults?.overall?.[group]?.details?.name}</span> */}
        <span>{globals?.context?.data_json_test?.postcampaigns?.defaults?.details?.name}</span>
      </div> : <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">Devices</div>}

      <Grid
        content={[
          ready/* Here too to animate bars on 1st load */ ? <Chart 
          text={{
            // title: "Revenue Impact",
            // subtitle: "Statistics",
            //x_label: "",
            x_flags: txt_sel,
            y_label: ROTANA_DEMO_MODE ? "Visits" : "Reach"
          }}
          data={arr_sel}
          mode={7}
        /> : "",
        ]}
        template={`
          0
        `}
        cellSize={{
          w: 800, 
          h: 400
        }}
      />
      <Grid
        content={[
          <TableSort 
            data={arr_dat}
            stick={true}
            undim={{"high": 4, "medium": 5, "low": 6}[group]}
            mode={2}
          />
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 800, 
          h: arr_dat?.length * 56 //270
        }}
      />

    </Layout>
)}