import {
  useRef,
  useState,
  useEffect
} from "react"

import {
  TitleCard,
} from "elements"

import "./ChartLines.css"

// Make sure the listener is registered once per SPA/module session
// TODO: Use the low-footprint/modern ResizeObserver interface instead when fully available (2023: ~95%)
let resizeCallback = () => {}
window.addEventListener("resize", () => {
  resizeCallback()
})

export const ChartLines = ({data, head=false}) => {

  let [timeframe, setTimeframe] = useState("m")
  // DEMO: Timeframe change
  // setTimeout(() => {
  //   setTimeframe("d")
  // }, 3000)

  const numberOfSets = data.curves.length
  const numberOfDivs = data.scale[timeframe].length// -> TODO: Smart segmentation from 'timeframe' (raw data is always 'days')

  const [mode, setMode] = useState(1)
  const previousCurves = useRef([])
  const svgElement = useRef(null)
  const previousSegment = useRef(null)
  const previousDensity = useRef(null)
  const flagIndex = useRef(0)
  const clientX = useRef(0/* Default pointer+line+flags position */)
  const eMin = useRef(0/* Engagement score floor value */)
  const eMax = useRef(100/* Engagement score ceil value */)
  const labels = useRef(null)
  const flags = useRef(null)
  const once = useRef(false)
  const lock = useRef(true)
  const time = useRef(1000)

  // Prevent SVG gradient animations from running while the component is being mounted 1/2
  const modePre = useRef(true)
  useEffect(() => {
    modePre.current = mode
  }, [mode])

  // On data/view change
  useEffect(() => {

    // Prevent chart view click trigger while opening animation already running
    svgElement.current.querySelector(".chartlines-curve > animate").addEventListener("endEvent", () => {
      lock.current = false
    }, {once: true})
  }, [data])

  // On timeframe change
  useEffect(() => {
    resizeCallback = computeIntersection// -> Per switch b/c of inherited 'timeframe' state/s change

    // Restart SVG animation/s after a new range is selected/filtered (instant b/c of bad/dizzy UX otherwise & b/c of lack of asymetric point-to-point SVG animations support unless same x-axis density)
    if (once.current) {// -> Skip first run (preserve the opening animation)
      for (const animate of svgElement.current.querySelectorAll("animate")) {
        animate.setAttribute("dur", time.current = 0.001)
        animate.beginElement()
      }
    }
    once.current = true
  }, [timeframe])

  const computeBaselineCurve = (index) => {
    
    // Get flat curves as the starting point for SVG animations
    const at = (index) * (100 / numberOfSets)
    const viewBoxWidth = 120/* -> svgElement.viewBox.baseVal.width */
    const incSegment = viewBoxWidth / numberOfDivs
    let next
    const curves = (new Array(numberOfDivs).fill(0)).map((sum => () => {
      next = `${sum} ${at} ${sum} ${at} C ${sum} ${at}`
      sum += incSegment
      return next
    })(incSegment * .5))
    curves.unshift(`${at} C 0 ${at}`)
    curves.push(`${viewBoxWidth} ${at} ${viewBoxWidth} ${at}`)
    return curves.join(" ")
  }
  const computeIntersection = event => {

    // Ignore further animation calls if trigger/current view is hot-swapped 
    const svg = svgElement.current // -> 'event.currentTarget' not always available (e.g. callback-less beyond 'mouseOver')
    // TODO: Block/buffer while the SVG animation is active too (e.g. via 'animationend') to prevent unexpected behaviour/s (e.g. labeling the x-axis, glitch while clicking the opening sequence, ...)
    if (!svg) return 

    // X step [sub]size (check 'pre-flight segment variable' under useEffect[/"onload"] for further details) -> Same performance regardless the value
    /* 10 -> Same as the original segment (e.g. [for 120 SVG width / 12 months] jumps from one month line to the next)
        1 -> 1/10 a segment (still discreet movements)
       .1 -> 1/100 segment (smooth/pseudo-continuous movements) */
    const step = .1

    // Convert [and then remember] mouse coordinates to SVG coordinates
    if (event) {
      clientX.current = Math.round(new DOMPointReadOnly(event?.clientX, 0).matrixTransform(svg.getScreenCTM().inverse()).x * (1 / step)) * step
    } 
    // Prevent delta-jump beyond limits (this would force the loop below to either exhaust its 500 threshold b/c no SVG/user-units point above 120 can be found or to mess w/ segments measurements) while computing SVG point/s
    let x = Math.max(Math.min(clientX.current, 119.99), 0.01)

    // Compute viewbox/viewport factor for further use
    const svgWidth = svg.clientWidth
    const w = svgWidth / 120/* As in 'svg.getBBox().width' */
    const h = svg.clientHeight / 100

    // Compute curve-line intersections (and place some [dot & label] content on it afterwards) -> Can't use SVGElement's getIntersectionList()/getIntersectionList instead b/c zero FF support
    const curves = svg.querySelectorAll(".chartlines-curve")
    const dotsBg = svg.querySelectorAll(".chartlines-dot-bg")
    const dotsFg = svg.querySelectorAll(".chartlines-dot-fg")
    let point, delta, lastDiff, lastFlag
    for (let [i, path] of curves.entries()) { 

      // Get the point
      for (let j = -.01; j < 500; j += .01/* ~0.01 'user units' = ~0.1px (for safe rounding) */) {// -> Being '500 steps' [for a very wavy path] the ~longest (dots left behind if too short) 
        
        // Fine stepping
        point = path.getPointAtLength(x + j)// -> This is the true bottleneck while computing SVG intersections
        if (point.x >= x) {
          break
        }
        // Coarse stepping
        if ((delta = x - point.x) > -step) {
          j += delta
        } 
      }
      // Set the point
      const bg = dotsBg[i]
      bg.setAttribute("x1", point.x)
      bg.setAttribute("x2", point.x)
      bg.setAttribute("y2", point.y - .001/* Responsive dot hack */)
      bg.setAttribute("y1", point.y)
      const fg = dotsFg[i]
      fg.setAttribute("x1", point.x)
      fg.setAttribute("y1", point.y)
      fg.setAttribute("x2", point.x)
      fg.setAttribute("y2", point.y - .001/* Responsive dot hack */)

      // Set flag position
      const flag = flags.current.children[i]
      flag.style.left = (point.x * w) + "px" 
      flag.style.top = (point.y * h) + "px" 
      flag.style.zIndex = flagIndex.current == i ? 1 : 0
      // TODO: Change 'z-index[-ish]' somehow for the [SVG] dots too (most likely we should get rid of the SVG dots & share/combine them with the DIV flags instead)

      // Set flag values
      if (mode) {// -> Overlapped
        flag.textContent = Math.round(((100 - point.y) / (-100 / (eMin.current - eMax.current))) + eMin.current)
      } else {// -> Stacked
        const diff = Math.round(100 - point.y)
        if (i) {
          if (i == numberOfSets - 1) {
            flag.textContent = diff
          } 
          lastFlag.textContent = (lastDiff - diff) 
        }
        lastDiff = diff
        lastFlag = flag
      }
    } 

    // Perfectly sync pointer-line with latest [aligned] dot
    for (const line of svg.querySelectorAll(".chartlines-line")) {
      line.setAttribute("x1", point.x)
      line.setAttribute("x2", point.x)
    }
    // Clip label when necessary
    // TODO: Lower the footprint in GC (e.g. by using SVG elements instead)
    const labelSize = {
      "m": svgWidth < 300 ? 1 : (svgWidth < 400 ? 2 : 3),
      "d": svgWidth < 300 ? 2 : 3
    }[timeframe]
    if ((timeframe + labelSize) != previousDensity.current) {
      // TODO: Block/buffer while the SVG animation is active
      for (const label of labels.current.children) {
        label.textContent = data.scale[timeframe][label.id].slice(0, labelSize)
      }
    }
    previousDensity.current = timeframe + labelSize

    // Highlight selected segment
    // TODO: Lower the footprint in GC (e.g. by using SVG elements instead)
    const segment = Math.floor(numberOfDivs * (point.x / 120))
    if (segment != previousSegment.current) {
      for (const label of labels.current.children) {
        label.classList[label.id == segment ? "add" : "remove"]("chartlines__labels--highlight")
      }
    }
    previousSegment.current = segment
  }
  const computePaths = (values = data) => {

    // Restart SVG animation
    const svg = svgElement?.current// -> Null on first render
    if (svg) {
      for (const animate of svg.querySelectorAll("animate")) {
        animate.setAttribute("dur", time.current = 0.25)
        animate.beginElement()
      }
    }
    // Stick dots to curves during SVG [re]animation
    let start
    const step = timestamp => {
      computeIntersection()
      if (((timestamp - (start = start || timestamp)) / (time.current * 1000)) < 1) {
        window.requestAnimationFrame(step)
      }
    }
    window.requestAnimationFrame(step)
 
    // Extract and stack all historical data
    const sets = values?.curves
    const plots = new Array(numberOfSets - 1).fill(new Array(numberOfDivs + 2).fill(0))
    let subSet
    switch(mode) {

      // Stacked
      case 0:
        for (let i = 0; i < (numberOfSets - 1/* -> We don't need to show the all-at-100% series (it would match the the x axis line) */); i++) {
          subSet = sets[i].slice(0, numberOfDivs).map((item, j) => (item + plots[i][j + 1]))
          plots[i + 1] = [subSet[0], ...subSet, subSet[numberOfDivs - 1]]
        }
        break

      // Overlapped
      case 1:
        let historyMax = 0
        let historyMin = 100
        for (const group of sets) {
          historyMin = group.slice(0, numberOfDivs).reduce((accumulator, current) => Math.min(accumulator, current), historyMin)
          historyMax = group.slice(0, numberOfDivs).reduce((accumulator, current) => Math.max(accumulator, current), historyMax)
        }
        const factor = 100 / (historyMin - historyMax)
        eMin.current = historyMin
        eMax.current = historyMax
        for (let i = 0; i < numberOfSets; i++) {
          subSet = sets[i].slice(0, numberOfDivs).map((item) => Math.floor((item - historyMax) * factor))
          plots[i + 0] = [subSet[0], ...subSet, subSet[numberOfDivs - 1]]
        }
        break
    }

    // True curve interpolation (w/ cubic polynomial Catmull-Rom splines)
    function solve(series, smooth = 1) {
      const size = series.length
      const last = size - 4    
      let path = series[1]// -> Original (to be managed from 'path' b/c of gradients): "M" + [series[0], series[1]]
      let x0, y0, x1, y1, x2, y2, x3, y3, cp1x, cp1y, cp2x, cp2y
      for (let i = 0; i < size - 2; i += 2) {
        x0 = i ? series[i - 2] : series[0]
        y0 = i ? series[i - 1] : series[1]
        x1 = series[i + 0]
        y1 = series[i + 1]
        x2 = series[i + 2]
        y2 = series[i + 3]
        x3 = i !== last ? series[i + 4] : x2
        y3 = i !== last ? series[i + 5] : y2
        cp1x = x1 + (((x2 - x0) / 6) * smooth)
        cp1y = y1 + (((y2 - y0) / 6) * smooth)
        cp2x = x2 - (((x3 - x1) / 6) * smooth)
        cp2y = y2 - (((y3 - y1) / 6) * smooth)

        // TODO: We should fit the curves after interpolation (b/c the line below is just an ugly/temp 'low pass filter' patch) to prevent out-of-scale values after accidental interpolation "stretching")
        cp1y = cp1y > 100 ? 100 : cp1y

        path += " C " + [cp1x, cp1y, cp2x, cp2y, x2, y2].join(" ")
      } 
      return path
    }
    // Pre-flight interpolation (so we can compute any non-standard function/algorithm in advance)
    let viewBoxWidth = 120
    let segment = viewBoxWidth / numberOfDivs
    let curves = []
    for (const subplot of plots) {
      let length = subplot.length
      let step = viewBoxWidth
      while (length) {
        subplot.splice(--length, 0, step >= 0 ? step : 0/* Offset in */)
        step -= (segment * ((step == viewBoxWidth) ? .5/* Offset out */ : 1))
      } 
      curves.push(solve(subplot))
    }
    // Post-flight interpolation (previous "pre-flight" block can be omitted if only the raw data is needed)
    return curves
  }

  return (
    <>
      {/* <span className="chartlines-title text-s">
        Title or filter goes here
      </span> */}
      {head ? <TitleCard title="Trends" subtitle="Compare datasets"/> : ""}
      <div className={`chartlines-wrapper ${head ? "" : "chartlines-wrapper--headless"}`}>
        <svg ref={svgElement} className="chartlines" viewBox="0 0 120 100" preserveAspectRatio="none" 
          onMouseMove={computeIntersection} 
          onClick={() => /*(clientX.current <= 0 || clientX.current >= 120) ||*/ setMode(mode == 0 && !lock.current ? 1 : 0)}>
          {computePaths().map((value, i) => {         
            const baselineCurve = previousCurves.current[i] || computeBaselineCurve(i)/* First time only */
            previousCurves.current[i] = value
            const id = i + 1
            const computedOffset = (1 / numberOfSets) * id

            // Prevent SVG gradient animations from running while the component is being mounted 2/2
            const aniTo = mode ? 1 : computedOffset
            const aniFrom = mode ? computedOffset : 1

            return (
              <g key={id}>
                <linearGradient id={`Gradient${id}`} x1="0" x2="0" y1="0" y2="1">
                  <stop className="chartlines__gradient" offset="0%"/* 100% to disable gradient */ stopColor={`var(--color-purple-${id})`} stopOpacity={mode ? .5 : .1 * id}/>
                  <stop className="chartlines__gradient" stopColor="white" stopOpacity={mode ? .75 : .5}>
                    <animate /* -> Because no CSS support for the 'offset' property */
                      attributeName="offset"
                      from={modePre.current === mode ? aniTo : aniFrom}
                      to={aniTo}
                      dur="0.001"// -> Default 0.001 (instant)
                      fill="freeze"
                      repeatCount="1"/>
                  </stop>
                </linearGradient>
                <path 
                  d={`M 0 100 C 0 0 0 0 0 ${value} L 120 100`}
                  fill={`url(#Gradient${id})`}>
                  <animate 
                    dur="0.5" 
                    repeatCount="1"
                    attributeName="d" 
                    calcMode="spline" 
                    keySplines="0.2 0 0.2 0.1" 
                    values={`
                      M 0 100 C 0 0 0 0 0 ${baselineCurve} L 120 100;
                      M 0 100 C 0 0 0 0 0 ${value} L 120 100`}/>
                </path>
                <path 
                  className="chartlines-curve"
                  d={`M 0 ${value}`}
                  vectorEffect="non-scaling-stroke" 
                  stroke={`var(--color-purple-${id})`}>
                  <animate 
                    dur="0.5"
                    repeatCount="1" 
                    attributeName="d" 
                    calcMode="spline" 
                    keySplines="0.2 0 0.2 0.1" 
                    values={`
                      M 0 ${baselineCurve};
                      M 0 ${value}`}/> 
                </path>
              </g>
            )}
          )}
          <g>
            {/* <line className="chartlines-line chartlines-vline-bg" x1="-100" x2="-100" y1="0" y2="100" strokeWidth={10}/> *//* Same as X division/step/gap size */}
            <line className="chartlines-line chartlines-vline-fg" x1="-100" x2="-100" y1="0" y2="100" vectorEffect="non-scaling-stroke"/>
            <line className="chartlines-hline" x1="0" x2="120" y1="100" y2="100" vectorEffect="non-scaling-stroke"/>
          </g>
          {[...new Array(numberOfSets)].map((_, index) => {
            return (
              <g key={index} onMouseOver={() => flagIndex.current = index}>
                <line className="chartlines-dot-bg" x1="0" x2="0" y1="-100" y2="-100" vectorEffect="non-scaling-stroke"/>
                <line className="chartlines-dot-fg" x1="0" x2="0" y1="-100" y2="-100" stroke={`var(--color-purple-${index + 1})`} vectorEffect="non-scaling-stroke"/>
              </g>
          )})}
        </svg>
        <div ref={labels} className="chartlines__labels text-xs">
          {[...new Array(numberOfDivs)].map((_, index) => <span 
            id={index}
            key={index}
            className={`chartlines__labels-index-${index}`} 
            >{/*langStrings.en.scale.max.m[0]*/}</span>)}
        </div>
        <div ref={flags} className="chartlines__flags text-xs">
          {data.curves.map((_, index) => <div 
            key={index} 
            style={{color: `var(--color-purple-${index + 1})`}} //, zIndex: foo.current != index ? -1 : 1}}
            >{/*value.history[0]*/}</div>)}
        </div>
      </div>
    </>
)}

