import {
  useEffect,
  useMemo,
  useState,
  useRef
} from "react"

// Elements
import {
  CheckboxGroup,
  Insert,
  Tick,
  Orb,
  Heartbeat
} from "elements"

// Utils
import {
  Commaize
} from "util_v2/UtilityFunctions"

// TODO: This will be part of the 'Insert' component (next to the block below is called from)
import { ReactComponent as IconClipboard } from "assets/icons_mini/clipboard.svg"
import { ReactComponent as TrashClipboard } from "assets/icons_mini/trash.svg"
import { ReactComponent as PencilClipboard } from "assets/icons_mini/pencil.svg"

import {ReactComponent as IconHB} from "assets/icons/small/heartbeat.svg"
import {ReactComponent as IconAA} from "assets/icons/small/adattention.svg"

import "./TableSort.css"
import { ROTANA_DEMO_MODE } from "util/const"

// Cell type manager (ALT to 'templates')
// const getComponentFromType = (type, value, index=null) => {
//   return {
//     "default": value,
//     "checkboxgroup": <CheckboxGroup label={value}/>,
//     "insert": <Insert what={value}/>,
//     "tick": <Tick value={value} tag={index}/>,

//     // TODO: To be converted into util (same as w/ "true [epsilon-fix] round w/ decimals support")
//     "_commas": Math.abs(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
//   }[type || "default"]
// }

/* TODO: Put into the Insert component */
const formatId = (id) => {
  return id === "-" ? "-" : <div className="insert-id__clipboard"
    onMouseDown={(event) => {
      event.preventDefault()
      event.target.classList.add("insert-id__clipboard--active")
      navigator.clipboard.writeText(id)
    }}
    onMouseUp={(event) => {
      event.preventDefault()
      event.target.classList.remove("insert-id__clipboard--active")
    }}
  >
    <IconClipboard className="insert-id__clipboard__icon"/>
    <div className="insert-id__clipboard__uid text-m">{id/*.replace(/[_-]/g, "")*/}</div>
  </div>
}

// Abbreviate [single/ranges-of] numbers
// TODO: Make an 'util' component/custom-Format-class-with-methods out of this
const numberAbbreviate = (numbers, decimals=1) => {
  const tempArray = []
  for (const number of [numbers].flat()) {//ALT: Array.isArray(numbers) ? numbers : [numbers]'
    const numberLength = Math.ceil(/* -> Change to floor for 'thousands' instead */ Math.log10(number + 1))// LEGACY (~99% [vs ~97%] support): Math.ceil(Math.log(number) / Math.LN10 ) + 1// -> WHY: 'number.toString().length' has a limit of 22 digits b/c the number is collapsed into exponential notation before its bytes are counted as a string
    const wholeLength = (numberLength % 3) || 3// ALT: ((numberLength - 1) % 3) + 1
    const wholeSuffix = {
      "3": "K",
      "6": "M",
      "9": "B",
      "12": "T"
    }[numberLength - wholeLength] || ""
    tempArray.push((number / (10**(numberLength - wholeLength))).toFixed(decimals) + wholeSuffix)
  }
  return tempArray.join(" - ")// ALT: tempArray.toString().replace(",", " - ")
} 

export const TableSort = ({data, headerLabels, undim, delta, label, split, stick/* TODO (1/3): Detect from data (e.g. Insert components) instead (if we can sort its contents we can read them) */=false, sort=true, slice=[], mode=0, callback=()=>null}) => {
  delta = ROTANA_DEMO_MODE
  
  const tHead = useRef()
  const tBody = useRef({"children": []})
  const lastSelection = useRef()


  // Count how many cells are valid for averaging (b/c they contain a non-null value)
  let totalValidRows = []
  data?.[0]?.map((_, i) => {
    totalValidRows.push(data.reduce((sum, x) => sum + (!(x[i] === null)), -1))// ALT (less space-efficient b/c O(N) vs O(1)): data.filter((x) => !x[1])//.length
  })

  const counters = useMemo(/* Compute once (unless 'data' changes) */() => {
    if (data) return new Array(data?.[0]?.length).fill(0)
  }, [data])



  // // Page splitting (pending...) 
  // const indexToSplit = split || data.length
  // const shift1row = +stick
  // const pages = [// Two static pages by default (example)
  //   [...data].slice(0, indexToSplit + shift1row),// -> The 'split' value only applies to the 1st page for the time being
  //   // [data[0]].concat([...data].slice(indexToSplit + 1 - shift1row, data.length))
  //   [["times", 0, 0, 0, 0, 0]].concat([...data].slice(indexToSplit + 1 - shift1row, data.length))

  // ]
 
  const data_all = data//.reverse() //slice.length ? pages[slice[0] ? 1 : 0] : data




  useEffect(() => {
    if (data_all?.length) {

      // TODO: 
      // - Build table tree (from tabular arrays)
      // - Fix the FF "jumping [into a too-short last row]" behavior after adding this class
      if (!slice.length) tHead.current?.parentElement.classList.add("tablesort-footless")

      // Set default [onload] sorting criteria (first column ascending instead of meaningless data_all FIFO order) if none was previously set
      if (lastSelection.current === undefined) sortData(0)

      // TODO: This is a temp/ugly workaround made to re-render the table after deleting a row (w/o suffering from non-async skips)
      if (mode === 1 || mode === 2) {
        setTimeout(() => {
          sortData()
          setTimeout(() => sortData(), 0)
        }, 0)
      }
    }
  }, [data_all])
  if (!data_all?.length) return 

  const sortData = (index) => {
    

    // if (!data_all?.length) return
    // if (index === undefined) return 
    
    // Reset previous column sorting selection
    const headCells = tHead.current.firstElementChild.children
    let sameColumn = lastSelection.current === index
    lastSelection.current = index
    if (!sameColumn) {
      for (const th of headCells) {
        th.className = ""
      }
    }

    // Abort if no column was really selected (e.g. first [flush] load)
    if (isNaN(index)) return// -> ALT: if (index === null) return

    // Normalize cell values (to be able to sort them)
    const getNumber = (string) => {

      // TODO (2/3): Detect from data_all (e.g. Insert components) instead (if we can sort its contents we can read them)
      // TODO:?Expand back shortened numbers (e.g. 10K) before sorting
      // TODO: Try to not use "replace" (pack it all within the regex instead)
      
      // v1
      // return ["-", "Total", "All"].includes(string) ? -Infinity/* -> Always on top [~ALT: 0] */ : (string).match(/^[-$]?\d*\.?\d+/g, "")?.[0]?.replace("$", "") // ALT (simpler yet always sorts mixed 'llnlnn' strings by number [vs alphanumerically] & has sorting problems w/ textual hyphens unless '‐' or '‑' is used): (string).replace(/[^0-9-]/g, "") OR ALT (catches & group all numbers): (string).match(/^(?=[$]?)?-?\d*\.?\d+|-?\d*\.?\d+/g)

      // v2
      return ["-", "Total", "All"].includes(string) ? -Infinity/* -> Always on top [~ALT: 0] */ : (string.replace(/[,$]*/g, "")).match(/^-?\d*\.?\d+/g, "")?.[0]// ALT (simpler yet always sorts mixed 'llnlnn' strings by number [vs alphanumerically] & has sorting problems w/ textual hyphens unless '‐' or '‑' is used): (string).replace(/[^0-9-]/g, "") OR ALT (catches & group all numbers): (string).match(/^(?=[$]?)?-?\d*\.?\d+|-?\d*\.?\d+/g)
    }
 
    // Extract columns from the existing table (it doesn't matter what [DOM] table builder was used on first load)
    let value
    let count = 0
    const rows = []

    // TODO (3/3): Detect from data_all (e.g. Insert components) instead (if we can sort its contents we can read them)
    // let sticky = stick //true
    for (const tr of tBody.current.children) {

      // Legacy (1/2):if (!sticky) {// -> Meaning 'second time/row & beyond' [if 'sticky = false' therefore '!sticky' after 1st round]
      if (!tr.classList.contains("tablesort__body-row__all")) {
        value = tr.children[index].textContent
        count = -(value) ? count + 1 : count
        rows.push([value, tr])
      }
      //Legacy (2/2): sticky = false

    }

    // v1 (legacy)
    // Sort (and style the header accordingly)
    // const rowsTemp = [...rows]
    // rows.sort(count < (rows.length * .5/* Sweet spot */) 
    //   ? /* Lexicographic order */undefined 
    //   : /* Numeric order */ (a, b) => {

    //     return getNumber(a[0]) - getNumber(b[0])
    //   }
    // )

    // v2
    rows.sort(!getNumber(rows?.[0]?.[0]) ? undefined : (a, b) => getNumber(a[0]) - getNumber(b[0]))

    const order = headCells[index].classList
    if (order.contains("tablesort__head-cell--ascend")) {
      order.remove("tablesort__head-cell--ascend")
      order.add("tablesort__head-cell--descend")
      rows.reverse()
    } else {
      order.remove("tablesort__head-cell--descend")
      order.add("tablesort__head-cell--ascend")

      // Prevents "every two clicks" problem while sorting columns w/ identical cells
      if (rows.length, rows[0]?.[0] === rows[rows.length - 1]?.[0]) rows.reverse()// ALT (w/ implicit if/else above included): 'if (rows[0] === rowsTemp[0])'
    }

    // Refill the table body (the old element is first removed if already in the tree)
    rows.map((tr, idx) => {  

    //!TODO: Clean-up & merge the logic (this is just a very poorly-thought-out/trial-n-error workaround)
    // if (slice.length) {
    //   if (index) {
    //     tr?.[1]?.classList?.["add"]("tablesort__body-row--odd-patch")
    //     tr?.[1]?.classList?.["remove"]("tablesort__body-row--odd")
    //     tr?.[1]?.classList?.["remove"]("tablesort__body-row--odd-auto")
        
    //     // --- The final version should be limited to this line (perfect for always start w/ a white row too)
    //     tr?.[1]?.classList?.[idx % 2 ? "remove" : "add"]("tablesort__body-row--odd__")
    //     // ---
    //   } else {
    //     tr?.[1]?.classList?.["remove"]("tablesort__body-row--odd-patch")
    //     tr?.[1]?.classList?.["remove"]("tablesort__body-row--odd__")
    //     tr?.[1]?.classList?.["add"]("tablesort__body-row--odd")
    //   }
    // }



      tBody.current.appendChild(tr.pop())
    })

    // reRender(!render)


  

    // if (index === 0) {
    //   for (const tr of tBody.current.children) {
    //     tr.classList["remove"]("tablesort__body-row--odd2")
    //   }

    // } 

    // Fill odd rows
    let count_ = 0
    //if (tBody.current.children.length) {
    for (const tr of tBody.current.children) {
      tr.classList["remove"]("tablesort__body-row--odd1")
      // tr.classList["remove"]("tablesort__body-row--odd")
      // tr.classList["remove"]("tablesort__body-row--even")

      tr.classList[++count_ % 2 && index/* workaround for using the legacy row-painting method for the 1st column */ ? "add" : "remove"]("tablesort__body-row--odd2")
      tr.classList[!(count_ % 2) && index ? "add" : "remove"]("tablesort__body-row--even2")
    }
    //}




  }

  const dimmedCols = ([[4, 5, 6], []][mode]) || []

  return (
    <table className={`${slice.length ? "tablesort__body-row--has-slice" : ""}        tablesort tablesort--mode-${mode} tablesort--${stick ? "stick"/* Just 'mode' after sure everything works */ : ""} text-m__`}>
      <thead ref={tHead}>
        <tr className={`tablesort__head-row ${delta ? "tablesort__head-row--delta" : ""} ${sort ? "tablesort__head-row--sort" : ""}`}>
          {data_all[0]?.map((entry, index) => {

            return (
          
            <td 
              key={index} 
              onClick={() => sort ? sortData(index) : null}
              >
              <div className={`${undim && dimmedCols.includes(index) && index !== undim ? "tablesort__head-cell__lining--dim" : ""} tablesort__head-cell__lining`}>{/* -> TODO: This wouldn't be necessary if the table was already a modern div-powered one (to be converted) */}
                <span className="tablesort__head-cell-txt">{
                  [
                    // Template 1 (data_all-viz tables)
                    [
                      String(entry).charAt(0).toUpperCase() + String(entry).slice(1),
                      "Avg heartbeat",
                      "Reach",
                      "% - Reach",
                      "% - High",
                      "% - Medium",
                      "% - Low",
                      "Avg session len",
                      "% - Viewability"
                    ][index],
                
                    // Template 2 (campaigns table) 
                    // TODO: Templates should be defined from the outside (as we are testing here below)
                    headerLabels?.[index],

                    // Template 3 (locations table)
                    // [
                    //   String(entry).charAt(0).toUpperCase() + String(entry).slice(1),
                    //   "Avg heartbeat",
                    //   "Visits",
                    //   "% - Visits",
                    //   "Unique visits",
                    //   ROTANA_DEMO_MODE ? "Seconds - Avg session" : "Viewability"
                    // ][index],
                    [
                      String(entry).charAt(0).toUpperCase() + String(entry).slice(1),
                      <div className={`tablesort__head-cell-txt__icon`}><Heartbeat/><span>Engagement score</span></div>,
                      // <Heartbeat/>,
                      // "Engagement score",
                      <div className={`tablesort__head-cell-txt__icon`}><IconAA/><span>Ad attention score</span></div>,
                      // "Ad attention score",
                      "Reach",
                      "Avg view time",
                      ROTANA_DEMO_MODE ? "Seconds - Avg session" : "Viewability"
                    ][index],

                    // Template 4 (mini tables)
                    [
                    label,
                    "Engagement",
                    "Ad attention"
                    ][index],
                
                    // Template 2 (campaigns table) 
                    // TODO: Templates should be defined from the outside (as we are testing here below)
                    headerLabels?.[index],

                    // Template 4 (locations table)
                    [
                      String(entry).charAt(0).toUpperCase() + String(entry).slice(1),
                      headerLabels?.[0] || "...",
                      headerLabels?.[1] || "...",
                      headerLabels?.[2] || "...",
                      headerLabels?.[3] || "...",
                      headerLabels?.[4] || "...",
                      headerLabels?.[5] || "...",
                    ][index]

                  ][mode]


                }
                </span>
                <span className="tablesort__head-cell-l2h">&nbsp;↓</span>{/* -> The [sorting] arrow means 'reading order' */}
                <span className="tablesort__head-cell-h2l">&nbsp;↑</span>
              </div>

              <div className={`${undim && dimmedCols.includes(index) && index !== undim ? "tablesort__head-cell__lining--dim" : ""} tablesort__head-cell__lining`}>{/* -> TODO: This wouldn't be necessary if the table was already a modern div-powered one (to be converted) */}
                {/* {Array.isArray(entry) ? entry[entry.length - 1] : ""} */}

                {(mode == 5 && index !== 0) || ((mode === 0 || mode === 2) && delta && index !== 0/*-> Skip first cell */) ? <Tick value={entry + "%"}/> : ""}
              </div> 

            </td>
            )}
          )}
        </tr>
      </thead>
      <tbody ref={tBody}>
        {

          // Blueprint mode (v24/01 - Adverty) -> TODO: 'Blueprint/Template/mode' support (e.g. "template: 'adverty24_01')
          (label === "Name"/* TODO (the right way by decoupling data & headers from the JSON file): Get rid of this temporary workaround to prevent flip-flip ordering while changing filters in Overview mode */ ? data_all : data_all.reverse()/* TODO: Not here (otherwise any event/refresh will trigger it) */).map((tr_item, tr_index) => { 

            // Sum column values up for every column
            counters.map((_, index) => {
              if (Array.isArray(tr_item[index])) {
                counters[index] = [(counters[index][0] || 0) + tr_item[index][0], (counters[index][1] || 0) + tr_item[index][1]] 
              } else {
                counters[index] += tr_item[index]
              }
            })

            // Build rich rows
            const totalRows = data_all.length - 1
            const stuck_row = stick && (tr_index === totalRows)

            const avgByIndex = (index, sumInstead) => {

              if (stuck_row) {
                const divisor = sumInstead ? 1 : totalValidRows[index] //ALT: totalRows
                let average 
                // const nonEmptyRows = totalRows - count "-"
                if (Array.isArray(counters[index])) { 
                  // v1
                  // const item0 = Math.floor((counters[index]?.[0]) / divisor) || 0// TODO: This is a workaround (give support for true 0 instead [b/c now NaN])
                  // const item1 = Math.floor((counters[index]?.[1]) / divisor) || 0
                  
                  // v2
                  const item0 = +((counters[index]?.[0]) / divisor).toFixed(1)// TODO: This is a workaround (give support for true 0 instead [b/c now NaN])
                  const item1 = +((counters[index]?.[1]) / divisor).toFixed(1)
                  
                  average = [item0, item1] 
                } else {
                  average = +(counters[index] / divisor).toFixed(1)// OLD: Math.floor(counters / divisor)

                  

                }
                return index > 0 ? (average || "-") : ("All " + (mode === 1 ? headerLabels[0]/* New approach */ : tr_item[0]/* Old approach (TODO: Adapt to new) */).toLowerCase()) || 0
              } else {
                return tr_item[index] || 0
              }
            }
            // const rich_rows = [
            //   <CheckboxGroup label={avgByIndex(0)}/>,
            //   // <Insert what={avgByIndex(1)}/>, 
            //   <Insert what={avgByIndex(1)}/>, 
            //   // TODO: To be converted into util (same as w/ "true [epsilon-fix] round w/ decimals support")
            //   Math.abs(avgByIndex(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //   avgByIndex(3), 
            //   <Tick value={avgByIndex(4)} tag={1}/>, 
            //   <Tick value={avgByIndex(5)} tag={2}/>, 
            //   <Tick value={avgByIndex(6)} tag={3}/>, 
            //   avgByIndex(7), 
            //   avgByIndex(8)
            // ]

            const temp = Commaize(avgByIndex(5))
            const rich_rows = [

               // Template 1 (data_all-viz tables)
              [
                <CheckboxGroup label={avgByIndex(0)}/>,
                <Insert what={Math.round(avgByIndex(1))}/>, 
                
                // TODO: To be converted into util (same as w/ "true [epsilon-fix] round w/ decimals support")
                Commaize(avgByIndex(2)),

                avgByIndex(3), 
                avgByIndex(4), 
                avgByIndex(5)

                // <Tick value={avgByIndex(4)} tag={1}/>, 
                // <Tick value={avgByIndex(5)} tag={2}/>, 
                // <Tick value={avgByIndex(6)} tag={3}/>, 
                // avgByIndex(7), 
                // avgByIndex(8)
                
              ],

              // Template 2 (campaigns table) 
              [
                avgByIndex(0),
                // formatId([...avgByIndex(1)].reverse().join("")),
                formatId(avgByIndex(1)),
                numberAbbreviate(avgByIndex(2)),
                numberAbbreviate(avgByIndex(3)),
                avgByIndex(4) + " month" + (avgByIndex(4) === 1 ? "" : "s"),
                isNaN(temp) ? "-" : "$" + temp,// ALT (for "-" instead of "$0"): // (avgByIndex(5) ? "$" + ... : "-"

                // TODO: To be part of the 'Insert' component too
                <div className="insert-actions" onClick={
                  (event) => {
                    if (event.target === event.currentTarget.firstElementChild) {
                      callback(totalRows - tr_index) // - (mode === 2 ? 1 : 0))
                    } else 
                    if (event.target === event.currentTarget.lastElementChild) {
                      callback(tr_item[1])
                    }
                  }}>
                  {tr_index === totalRows// TODO: This should be part of avgByIndex
                    ?
                    "-"
                    :
                    <>
                      <TrashClipboard/>
                      <PencilClipboard/>
                    </>
                  }
                </div>
              ],

              // // Template 3 (locations table)
              // [
              //   <CheckboxGroup label={avgByIndex(0)}/>,
              //   <Insert what={Math.round(avgByIndex(1))}/>, 
                
              //   // TODO: To be converted into util (same as w/ "true [epsilon-fix] round w/ decimals support")
              //   Commaize(avgByIndex(2, true)),

              //   avgByIndex(3, true), 
              //   Commaize(avgByIndex(4, true)), 
              //   avgByIndex(5)
              // ],
              // Template 3 (locations table)
              [
                <CheckboxGroup label={avgByIndex(0)}/>,
                <Tick value={Math.round(avgByIndex(1))} tag={1}/>, 
                <Tick value={Math.round(avgByIndex(2))} tag={3}/>, 
                Commaize(avgByIndex(3, true)), 
                (avgByIndex(4, false)) + "s", 
                (avgByIndex(5, false)) + "%"
              ],


              // Template 4 (mini tables)
              [
                <Insert what={avgByIndex(0)} /* size={38} *//>,
                <Insert what={avgByIndex(1)}/>,
                <Insert what={avgByIndex(2)} mode={1}/>
              ],

              // Template ???
              [

              ],
              // Template 5 (STC demo main tables)
              [
                <CheckboxGroup label={avgByIndex(0)}/>,
                <Insert what={Math.round(avgByIndex(1))}/>,
                avgByIndex(2), 
                Commaize(avgByIndex(3)),
                avgByIndex(4) + "%", 
                avgByIndex(5) + "%",
                avgByIndex(6)
              ],
            ][mode]

            // // TODO: Use loop instead (& make sure the length is taken into account from everywhere)
            // // ALT to 'templates' (see 'getComponentFromType' above)
            // const dummy_arr = ["checkboxgroup"]
            // const rich_rows = [
            //   getComponentFromType("checkboxgroup", avg(0)),
            //   getComponentFromType("insert", avg(1)),
            //   getComponentFromType("_commas", avg(2)), 
            //   getComponentFromType("", avg(3)), 
            //   getComponentFromType("tick", avg(4), 1), 
            //   getComponentFromType("tick", avg(5), 2), 
            //   getComponentFromType("tick", avg(6), 3),
            //   getComponentFromType("", avg(7)), 
            //   getComponentFromType("", avg(8))
            // ]
      
            return <tr //tr_index > (slice?.[0] - 1) && tr_index < slice?.[1]) ? "" : <tr 

            // Template as a function (with "stuck_row" flags)
            // TODO: Harmonize odd-painting rows class (ugly hack for the time being to make it compatible w/ hidding rows)

            className={`${tr_index > (slice?.[0] - 1) && tr_index < slice?.[1] ? "tablesort__body-row--hide" : ""} ${!slice.length || tr_index % 2 ? "tablesort__body-row--even" : "tablesort__body-row--odd1"} ${slice.length ? "" : "tablesort__body-row--odd-auto"} tablesort__body-row ${stuck_row ? "tablesort__body-row__all" : ""}`}



            // className={`${tr_index > (slice?.[0] - 1) && tr_index < slice?.[1] ? "tablesort__body-row--hide" : ""} ${!slice.length || tr_index % 2 ? "" : "tablesort__body-row--odd"} ${slice.length ? "" : "tablesort__body-row--odd-auto"} tablesort__body-row ${stuck_row ? "tablesort__body-row__all" : ""}`}



            //className={`tablesort__body-row ${stuck_row ? "tablesort__body-row__all" : ""}`}




            // className={`${tr_index > (slice?.[0] - 1) && tr_index < slice?.[1] ? "tablesort__body-row--hide" : ""} ${!slice.length || tr_index % 2 ? "" : "tablesort__body-row--odd"} ${slice.length ? "" : "tablesort__body-row--odd-auto"} tablesort__body-row ${stuck_row ? "tablesort__body-row__all" : ""}`}
            // className={`${!slice.length || tr_index % 2 ? "" : "tablesort__body-row--odd"} ${slice.length ? "" : "tablesort__body-row--odd-auto"} tablesort__body-row ${stuck_row ? "tablesort__body-row__all" : ""}`}

            key={tr_index}
            >{rich_rows.map((td_item, td_index) => {
              return <td 
                className={`tablesort__body-cell ${undim && dimmedCols.includes(td_index) && td_index !== undim ? "tablesort__body-cell--dim" : ""}`}
                key={td_index}>{td_item}</td>
            })
          }</tr>

       })

      }
      </tbody>
      <tfoot>
        <tr className="tablesort__foot-row">
          <td colSpan={data_all[0]?.length - (ROTANA_DEMO_MODE ? 1 : 0)}><div>{slice[0] + 1}-{slice[1]}</div>of {data_all.length - 1}</td>
        </tr>
      </tfoot>
    </table>
  )
}