// Containers
import {
    Grid
} from "containers"

// Containers
import {
  // Wheel,
  // Sortable,
  // Chart, 
  ChartMap,
  // ChartBars,
  // ChartLines,
  // ChartFunnel,
  // Topbar
} from "components"

// Elements
import {
  // Tick,
  // Insert,
  Checkbox,
  // CheckboxGroup,
  // Persona
} from "elements"

// Contexts
import { 
  useGlobals
} from "contexts"

// Catalog-wide styling
import "./Catalog.css"

// import { ReactComponent as IconTravel } from "assets/icons/big/category/travel_padded.svg"
// import { ReactComponent as IconScience } from "assets/icons/big/category/science_padded.svg"
// import { ReactComponent as IconDetroit } from "assets/icons/big/location/detroit_padded.svg"

// Catalog grid
export const Catalog = () => {
  const globals = useGlobals()
  return (
    <>
      <Checkbox callback={(state) => globals.set("theme", state ? "dark" : "light")}/>
      <Grid 
        content={[
          <ChartMap 
            data={{
              "US": [10, 20, 30],
              "MX": [10, 20, 30],
              "IN": [10, 20, 30],
              "ID": [10, 20, 30],
              "BR": [10, 20, 30],
              "FR": [10, 20, 30],
              "DE": [10, 20, 30],
              "GB": [10, 20, 30],
              "IT": [10, 20, 30],
              "CA": [10, 20, 30],
              "NL": [10, 20, 30]
            }}
            mode={0}
            />,
            
            // TODO: Include rest of the components for Catalog
        //   <Sortable data={{
        //     // "head": ["Engagement cluster", "Total users (%)", "Impressions", "CPM (USD)", "Suggested new CPM (USD)", "Current revenue (USD)", "Projected revenue (USD)", "Revenue impact (USD)"],
        //     "head": [["A", <Tick value={"12%"}/>], ["B", <Tick value={"12%"}/>], ["C", <Tick value={"12%"}/>]],
        //     "body": [
        //       [<CheckboxGroup label={"UN"}/>, <Insert what={"UN"}/>, <Tick value={"$100,000"}/>],
        //       [<CheckboxGroup label={"ES"}/>, <Insert what={"ES"}/>, <Tick value={"$-60,000"}/>],       
        //       [<CheckboxGroup label={"US"}/>, <Insert what={["UN", "DK", "ES"]}/>, <Tick value={"$50,000"}/>],
        //       [<CheckboxGroup label={11}/>, <Insert what={42}/>, <Tick value={"$-40,000"}/>],
        //       [<CheckboxGroup label={"SE"}/>, <Insert what={[42, 24]}/>, <Tick value={"10:23"} tag="1"/>],
        //       [<CheckboxGroup label={"BR"}/>, <Insert what={"f25c"}/>,<Tick value={"foo2"} tag="2"/>],       
        //       [<CheckboxGroup label={"CZ"}/>, <Insert what={["m35c", "m25c", "f55c"]}/>, <Tick value={"foo3"} tag="3"/>],
        //       [<CheckboxGroup label={23}/>, <Insert what={["m35c", "m25c", "f55c"]}/>, <Tick value={"foo3"} tag="3"/>]
        //     ]
        //     }}/>,

        //     <Chart 
        //     text={{
        //       title: "",
        //       subtitle: "",
        //       //x_label: "",
        //       x_flags: ["Action", "Drama", "Horror", "Documentaries", "Comedies", "Romantic", "Sci-Fi", "Sports", "Thrillers", "Kids"],
        //       y_label: "Reach", 
        //       y_flags: ["20", "40", "60", "80", "100"]
        //     }}
        //     data={[72, 40, 45, 71, 85, 65, 20, 72, 40, 45]}
        //     mode={6}
        //   />,
          
        //   <Chart 
        //     text={{
        //       title: "Performance",
        //       subtitle: "Statistics",
        //       //x_label: "",
        //       x_flags: ["Action", "Drama", "Horror", "Documentaries", "Comedies", "Romantic", "Sci-Fi"],
        //       //y_label: "", 
        //       y_flags: ["20K", "40K", "60K", "80K", "100K"]
        //     }}
        //     data={[
        //       [26, 72/* Total */],
        //       [40, 40],
        //       [30, 45],
        //       [24, 71],
        //       [15, 85],
        //       [10, 65],
        //       [3, 20]
        //     ]}
        //     mode={5}
        //   />,

        //   <Wheel 
        //     text={{
        //       title: "",
        //       subtitle: ""
        //     }}
        //     data={[
        //       [12000, -76],
        //       [3000, -82],
        //       [15000, 83]
        //       // [12000, -82],
        //       // [3000, -82],
        //       // [15000, 80]
        //     ]}
        //     mode={2}
        //   />,

        //   <Chart 
        //     text={{
        //       title: "Top audiences",
        //       subtitle: "",
        //       x_label: "See more →",// TODO: ["Label", "https://..."] instead for links
        //       x_flags: ["Travel", "Science", "Detroit", "Sarah Brown", "Michael Williams", "David Jones", "Sunday", "Monday", "Thrusday"],
        //       //y_label: "", 
        //       // y_flags: ["", "", "", "", ""],
        //     }}
        //     data={[

        //       // SVG alt 3: Original (w/o enclosing circle) -> Preferred
        //       // [<IconMacOS/>, 5, 0, 0, 100],
        //       // [<IconIPhone/>, 9, 0, 0, 100],
        //       // [<IconAndroid/>, 2, 0, 0, 100],

        //       // SVG alt 2: Padded (in order to have responsive resizing w/o aspect-ratio))
        //       [<IconTravel/>, 5, 0, 0, 100],
        //       [<IconScience/>, 9, 0, 0, 100],
        //       [<IconDetroit/>, 2, 0, 0, 100],

        //       // SVG alt 1: As img src
        //       // [IconMacOS1, 5, 0, 0, 100],
        //       // [IconIPhone2, 9, 0, 0, 100],
        //       // [IconAndroid3, 2, 0, 0, 100],

        //       [<Persona id={"m25c"}/>, 9, 0, 0, 100],
        //       [<Persona id={"m30c"}/>, 7, 0, 0, 100],
        //       [<Persona id={"m35c"}/>, 8, 0, 0, 100],
        //       ["18h-19h", 7, 0, 0, 100],
        //       ["11h-12h", 6, 0, 0, 100],
        //       ["10h-11h", 7, 0, 0, 100]
        //     ]}
        //     mode={4}
        //   />,

        //   <Chart 
        //     text={{
        //       title: "Audiences",
        //       subtitle: "",
        //       x_label: "See more →",
        //       x_flags: ["Jessica Miller", "Emily Johnson", "Sarah Brown", "Michael Williams", "David Jones", "James Smith", "Jennifer Taylor", "Ashley Doe", "Jane Wilson", "Robert Davis"],
        //       //y_label: "", 
        //       // y_flags: ["", "", "", "", ""],
        //     }}
        //     data={[
        //       [<Persona id={"m25c"}/>, 9, 25, 25, 50],
        //       [<Persona id={"m30c"}/>, 9, 15, 36, 49],
        //       [<Persona id={"m35c"}/>, 9, 10, 40, 50],
        //       [<Persona id={"m40c"}/>, 9, 20, 38, 42],
        //       [<Persona id={"m45c"}/>, 9, 15, 36, 49],
        //       [<Persona id={"f25c"}/>, 9, 30, 45, 25],
        //       [<Persona id={"f30c"}/>, 9, 20, 38, 42],
        //       [<Persona id={"f35c"}/>, 9, 15, 36, 49],
        //       [<Persona id={"f40c"}/>, 9, 30, 45, 25],
        //       [<Persona id={"f45c"}/>, 9, 20, 38, 42]
        //     ]}
        //     mode={4}
        //   />,

        //   <ChartFunnel 
        //     text={{
        //       title: "Conversion Funnel",
        //       flags: ["Enter site", "Create account", "Play video", "Subscribe to Premium"]
        //     }}
        //     data={[36000, 30000, 18000, 9000]}

        //     // DEMO ALTs
        //     // data={[37000, 36000, 18000, 9000]}
        //     // data={[37000, 36000, 35000, 34000]}
        //     // data={[37000, 35300, 200, 100]}

        //     mode={1}
        //   />,

        //   <Chart 
        //     text={{
        //       title: "Timing",
        //       // subtitle: "",
        //       x_label: "Time of day",
        //       x_flags: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"], 
        //       y_label: "Day of week",
        //       y_flags: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
        //     }}
        //     data={[
        //       [2, 2, 2, 2, 2, 2, 3],
        //       [3, 3, 3, 3, 3, 3, 2],
        //       [4, 4, 4, 4, 3, 5, 3],
        //       [2, 2, 1, 2, 2, 2, 2],
        //       [2, 2, 4, 1, 1, 2, 2],
        //       [3, 3, 3, 3, 3, 4, 4],
        //       [2, 4, 4, 2, 4, 2, 2],
        //       [2, 2, 2, 2, 4, 4, 4],
        //       [1, 2, 4, 2, 4, 2, 3],
        //       [2, 4, 2, 4, 4, 2, 3],
        //       [2, 4, 1, 2, 4, 4, 4],
        //       [4, 2, 4, 4, 3, 2, 3],
        //       [2, 2, 4, 2, 2, 2, 2],
        //       [3, 3, 3, 3, 3, 4, 2],
        //       [1, 4, 4, 2, 4, 4, 4],
        //       [3, 4, 2, 5, 2, 2, 2],
        //       [3, 4, 2, 5, 2, 2, 2],
        //       [3, 4, 2, 4, 4, 2, 2],
        //       [2, 2, 4, 2, 2, 2, 2],
        //       [2, 2, 5, 2, 2, 2, 2],
        //       [2, 2, 5, 2, 2, 2, 2],
        //       [5, 4, 4, 2, 4, 4, 2],
        //       [3, 4, 2, 4, 4, 2, 2],
        //       [3, 3, 4, 4, 3, 2, 4]
        //     ]}
        //     mode={3} 
        //   />,

        //   <Topbar data={[
        //     {
        //       "name": "Impressions",
        //       "data": [42, 24],
        //       "unit": "M"
        //     },
        //     {
        //       "name": "CTR",
        //       "data": [24, 42],
        //       "unit": "%"
        //     },
        //     {
        //       "name": "VTR",
        //       "data": [42, 24],
        //       "unit": "%"
        //     },
        //     {
        //       "name": "Cost",
        //       "data": [24, 42],
        //       "unit": "%"
        //     },
        //     {
        //       "name": "CPM",
        //       "data": [42, 24],
        //       "unit": "$"
        //     },
        //     {
        //       "name": "Conversion Rate",
        //       "data": [42, 24],
        //       "unit": "%"
        //     }
        //   ]}/>,
          
        //   <Chart 
        //     text={{
        //       title: "Impressions by Engagement",
        //       // subtitle: "",
        //       x_label: "Engagement",
        //       // x_flags: new Array(100),
        //       //y_label: ""
        //       y_flags: ["5,000", "10,000", "15,000", "20,000", "25,000", "30,000"], 
        //     }}
        //     data={{
        //       8:  [21000, 15, <Persona id={"m25c"}/>],
        //       10: [6000,  25, <Persona id={"m30c"}/>],
        //       16: [18000, 25, <Persona id={"m35c"}/>],
        //       30: [10000, 45, <Persona id={"m40c"}/>],
        //       48: [6000,  25, <Persona id={"m45c"}/>],
        //       52: [17000, 15, <Persona id={"m50c"}/>],
        //       58: [6000,  25, <Persona id={"m55c"}/>],
        //       66: [16000, 15, <Persona id={"f25c"}/>],
        //       69: [4000,  35, <Persona id={"f30c"}/>],
        //       73: [18000, 35, <Persona id={"f35c"}/>],
        //       77: [11000, 15, <Persona id={"f40c"}/>],
        //       85: [14000, 15, <Persona id={"f45c"}/>],
        //       91: [26000, 35, <Persona id={"f50c"}/>],
        //       96: [7000,  15, <Persona id={"f55c"}/>]
        //     }}
        //     mode={2}
        //   />,

        //   <Chart 
        //     text={{
        //       title: "Revenue Impact",
        //       subtitle: "Statistics",
        //       //x_label: "",
        //       x_flags: ["Cluster 1", "Cluster 2", "Cluster 3", "Cluster 4", "Cluster 5", "Cluster 6", "Cluster 7", "Cluster 8", "Cluster 9", "Cluster 10"],
        //       //y_label: "", 
        //       y_flags: ["$150K", "$300K", "$450K", "$600K", "$750K"]
        //     }}
        //     data={[
        //       [25, 95,  93],
        //       [32, 155, 87],
        //       [30, 225, 82],
        //       [24, 260, 65],
        //       [15, 300, 89],
        //       [0,  400, 45],
        //       [0,  420, 61],
        //       [0,  550, 50],
        //       [0,  600, 41],
        //       [0,  675, 68]
        //     ]}
        //     mode={1}
        //   />,
  
        //   <Chart 
        //     text={{
        //       title: "CPM Impact",
        //       subtitle: "Statistics",
        //       x_label: "Label X",
        //       x_flags: ["Cluster 1", "Cluster 2", "Cluster 3", "Cluster 4", "Cluster 5", "Cluster 6", "Cluster 7", "Cluster 8", "Cluster 9", "Cluster 10"],
        //       y_label: "Label Y",
        //       y_flags: ["$5", "$10", "$15", "$20", "$25"] 
        //     }}
        //     data={[
        //       [25,   12.5, 93],
        //       [22.5, 12.5, 87],
        //       [20,   12.5, 82],
        //       [17.5, 12.5, 65],
        //       [15,   12.5, 89],
        //       [12.5, 12.5, 45],
        //       [12.5, 12.5, 61],
        //       [12.5, 12.5, 50],
        //       [12.5, 12.5, 41],
        //       [12.5, 12.5, 68],
        //     ]}
        //     mode={0} 
        //   />,
                 
        //   <Sortable data={{
        //     "head": ["Engagement cluster", "...", "...", "Total users (%)", "Impressions", "CPM", "Suggested new CPM", "Current revenue", "Projected revenue", "Revenue impact"],
        //     "body": [
        //       [<CheckboxGroup label={"Total"}/>, "Total", "aaa", "-100%", "100,000,000", "-", "-", "$2,000,000", "$2,186,000", <Tick value={"$186,000"}/>],
        //       [<CheckboxGroup label={"Cluster 1"}/>, "aaa", "ccc", "-5%", "5,000,000", "$20", "$25", "$100,000", "$125,000", <Tick value={"$25,000"}/>],
        //       [<CheckboxGroup label={"Cluster 2"}/>, "bbb", "zzz", "-8%", "8,000,000", "$20", "$24", "$160,000", "$192,000", <Tick value={"$32,000"}/>],
        //       [<CheckboxGroup label={"Cluster 3"}/>, "-", "hhh", "-10%", "10,000,000", "$20", "$23", "$200,000", "$230,000", <Tick value={"$30,000"}/>],              [<CheckboxGroup label={"Cluster 9"}/>, "fff", "sss", "-30%", "30,000,000", "$20", "$20", "$600,000", "$600,000", "-"],
        //       [<CheckboxGroup label={"Cluster 10"}/>, "aaa", "aaa", "35%", "35,000,000", "$20", "$20", "$700,000", "$700,000", "-"],
        //     ]
        //   }}/>,

        //   <ChartLines data={/* TODO: Decouple text from data (the same way we do for the newer components) */
        //     {
        //       scale: {// -> English by default (TODO: lang variants should probably be part of a different request)
        //         m: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        //         d: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
        //       },
        //       curves: [
        //           [14, 12, 14, 18, 15, 14, 18, 24, 24, 21, 16, 10],
        //           [25, 32, 26, 18, 17, 23, 23, 19, 18, 18, 24, 27],
        //           [19, 18, 22, 20, 21, 24, 19, 13, 13, 18, 17, 24],
        //           [24, 19, 20, 23, 20, 14, 23, 28, 25, 17, 14, 13],
        //           [18, 19, 18, 21, 27, 25, 17, 16, 20, 26, 29, 26]
        //         ]
        //     }
        //   }/>,

          /* Pattern repeater for demo-ing purposes */
    //       <ChartBars 
    //         text={{
    //           title: "History",
    //           subtitle: "Your audience engagement over time"
    //         }}
    //         data={
    //           Array(10).fill([72, 47, 55, 57, 70, 71, 72, 76, 68, 72, 89, 77, 79, 69, 70, 73, 73, 81, 65, 67, 64, 76, 56]).flat()
    //         }/>,

    //       <Wheel 
    //         text={{
    //           title: "Score",
    //           subtitle: "Learn how engaged your audience is this week"
    //         }}
    //         data={[
    //           [42, 0],
    //           [46, 0]
    //         ]}
    //         mode={1}
    //       />,

    //       <Wheel 
    //         text={{
    //           title: "",
    //           subtitle: ""
    //         }}
    //         data={[
    //           [75, 0]
    //         ]}
    //         mode={0}
    //       />

        ]}
        template={`
            0
            1
            2
            3
            4
            5
            6
            7
            8
            9
            10
            11
            12
            13
            14
            15
            16
            17
            `}
        cellSize={{
          w: 640, //960, 
          h: 380 // Table: 30 + (rows.length * 50) + 30/* TODO: Make the row height parametric */
        }}
      />
    </>
  )
}