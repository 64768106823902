/*
  v240513 - Franc Jerez
  v240529 - Franc Jerez - Disabled button fix
*/

// Styling
import "./Button.css"

/* TODO: 
   - ...
*/
// Component
export const Button = ({text, type="button", loading=false, disabled=false, inverted=false, shrunken=false, cbClick, cbFocus}) => {
    return (
        <button 
            className={`button 
                ${loading ? "button--await" : ""} 
                ${inverted ? "button--inverted" : ""}
                ${shrunken ? "button--shrunken" : ""}
            `} 
            type={type} 
            onClick={cbClick} 
            onFocus={cbFocus} 
            disabled={disabled}
            >
            <span className={`button__label ${shrunken ? "text-s" : "text-m-b"}`}>{text}</span>
        </button>
    )
}
/* Example
  <Button 
    text={"Label"} 
    cbClick={() => console.log("do something")}/>
*/