import {
  useRef,
  useState,
  useEffect
} from "react"

import {
  useNavigate,
  useSearchParams
} from "react-router-dom"

// Containers
import { 
  Layout,
  Grid
} from "containers"

// Components
import { 
  Topbar,
  Wheel,
  ChartBars,
  TableSort,
  Details,
  PieArc,
  WheelAltFull,
  ChartHistory,
  ChartLine
} from "components"
import { 
  Table
} from "components_v2"

// Elements
import { 
  Tick,
  Score,
  Orb,
  Bar
} from "elements_v2"

// Contexts
import {
  useGlobals
} from "contexts"

// Utils
import {
  ROTANA_DEMO_MODE
} from "util/const"
import {
  Commaize,
  Upperize
} from "util_v2/UtilityFunctions"

// Home-wide styling
import "./Content.css"

// import hardcodedPic0 from "assets/_temp/campaigns/lego.png"
// import hardcodedPic1 from "assets/_temp/campaigns/justdontquit.png"
// import hardcodedPic2 from "assets/_temp/campaigns/olympics.png"
// import hardcodedPic3 from "assets/_temp/campaigns/absolutperfection.png"
// import hardcodedPic4 from "assets/_temp/campaigns/baby.png"
// import hardcodedPic5 from "assets/_temp/campaigns/thinkdifferent.png"

import hardcodedPic0 from "assets/_temp/campaigns/undefined.png"
import hardcodedPic1 from "assets/_temp/campaigns/undefined.png"
import hardcodedPic2 from "assets/_temp/campaigns/undefined.png"
import hardcodedPic3 from "assets/_temp/campaigns/undefined.png"
import hardcodedPic4 from "assets/_temp/campaigns/undefined.png"



// Campaigns assembly
export const Content = ({ready, query}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [more, seeMore] = useState([false, false])

  const previousUrl = useRef()
  useEffect(() => {
    previousUrl.current = window.location.href
  }, [window.location.href])
  const urlChange =  window.location.href != previousUrl.current

  const navigate = useNavigate()
  const globals = useGlobals()
  const group = globals?.context?.dropdown_selection || "_"
  
  let arr_arc = []
  let arr_bar = []
  let arr_top = new Array(5).fill([0, 0])
  let arr_dat_mini = []
  let obj_top = {}
  // ---
  let obj_top_new = {}
  // ---
  //let obj_frt = []
  let obj_val1 = []
  let obj_val2 = []
  let obj_val3 = []


  const selectedCampaign = globals?.context?.dropdown_selection_campaigns || "all"
  const undefinedCampaign = [undefined, "allcampaigns", "all"].includes(selectedCampaign)
  const selectedSubview = globals?.context?.selected_subview || "all"

  if (ready) {

    let data = ROTANA_DEMO_MODE 
    ? 
    globals?.context?.data_json_test?.overall 
    : 
    globals?.context?.data_json_test?.postcampaigns?.defaults?.overall?.[group]  

    
    // All by default

    arr_bar = ROTANA_DEMO_MODE ? data?.scores : data?.engagement
    arr_arc = [[arr_bar[0], -1]/*, [35, 0]*/]
    const cards = data.cards
    arr_top = ROTANA_DEMO_MODE 
      ? [cards.reach, cards.sessions, cards.session_len, cards.viz_pct]
      // : [cards.sessions, cards.reach, cards.avg_view_time, cards.viewability, cards.cpm]
      : [cards.impressions, cards.unique_reach, cards.frequency, cards.avg_view_time, cards.viewability]
    arr_dat_mini = ROTANA_DEMO_MODE ? data.table : data.minitables


    // obj_top = data.details
    obj_top = globals?.context?.data_json_test?.postcampaigns?.defaults?.details
    // ---
    // obj_top_new = globals?.context?.data_json_test?.postcampaigns?.registry?.[selectedCampaign]
    // console.log("REGISTRY #2", globals?.context?.data_json_test?.postcampaigns?.registry, obj_top_new?.[selectedCampaign])

    //console.log("DETAILS...", globals?.context?.data_json_test?.postcampaigns?.registry?.[globals?.context?.dropdown_selection_campaigns])

    obj_top_new = globals?.context?.data_json_test?.postcampaigns?.registry?.[globals?.context?.dropdown_selection_campaigns]

    // ---
    // obj_frt = data.fillrate
    // obj_val1 = [data?.engagement, data?.ad_attention]
    obj_val1 = /* Get last values from the historical series */[data?.engagement?.[data?.engagement?.length - 1], data?.ad_attention?.[data?.ad_attention?.length - 1]]
    obj_val2 = [data?.ads_sent?.[0], data?.ads_requested?.[0], data?.fill_rate?.[0]]

    // Make sure the target component always get the needed amount of data (and in the right order)
    obj_val3 = (new Array(/* (7days * 4weeks) + 1trailingday = */29).fill(0).concat(data?.total_sessions.sort((a, b) => a - b/* sort numerically [vs alphabetically] */))).slice(data?.total_sessions.length)
  }
  // if (!arr_dat_mini?.length) return

  // ---------------- DUPLICATED (to be converted into a separated layout component)

  // if (!ROTANA_DEMO_MODE) {
  //   obj_top.name = {
  //     "allcampaigns": "All campaigns",
  //     "justdoit": "Just Do It",
  //     "justdontquit": "Just Don't Quit"
  //   }[selectedCampaign]
  // }
  // ---------------- DUPLICATED (to be converted into a separated layout component)

  const title = searchParams.get("title")// ALT (vanilla JS): (new URL(document.location).searchParams).get("title")
  const genre = searchParams.get("genre") || {// TODO: Map somewhere else
    "squid_game": "drama",
    "game_of_thrones": "thriller",
    "breaking_bad": "thriller"
  }[title]

  // --- Dummy date (temp)
  const details = {
    "genre": {
      "drama": {
        "title": "Drama",
        "children": ["323 movies", "124 TV shows", "124 docuentaries"],
        "image": "https://visualpharm.com/assets/974/Theatre%20Mask-595b40b85ba036ed117dac28.svg",
        "description": "These film and TV series portray realistic characters in conflict with either themselves, others, or forces of nature. They usually focus on character and how these people arc over time."
      },
      "thriller": {
        "title": "Thriller",
        "children": ["323 movies", "124 TV shows", "124 docuentaries"],
        "image": "https://visualpharm.com/assets/338/Thriller-595b40b85ba036ed117dac42.svg",
        "description": "The Thriller genre is characterized by suspense, tension, and excitement, often involving a protagonist facing dangerous situations or uncovering dark secrets."
      },
      "romance": {
        "title": "Romance",
        "children": ["323 movies", "124 TV shows", "124 docuentaries"],
        "image": "https://png.pngtree.com/png-clipart/20230823/original/pngtree-romantic-movie-tickets-cinema-film-picture-image_8203172.png",
        "description": "The Romance genre focuses on love stories, exploring the emotional and relational journey between characters as they navigate their feelings and obstacles to achieve a happy ending."
      }
    },
    "title": {
      "squid_game": {
        "type": "TV Show",
        "network": "Netflix",
        "networklogo": "https://upload.wikimedia.org/wikipedia/commons/f/ff/Netflix-new-icon.png",
        "title": "Squid Game",
        "region": "kr",
        "parent": ["Drama", "Thriller"],
        "year": "2021",
        "image": "https://upload.wikimedia.org/wikipedia/en/d/dd/Squid_Game.jpg",
        "description": "Hundreds of cash-strapped contestants accept an invitation to compete in children's games for a tempting prize, but the stakes are deadly."
      },
      "game_of_thrones": {
        "type": "TV Show",
        "network": "HBO",
        "networklogo": "https://i.pinimg.com/736x/8b/02/00/8b020050690f955ccb306cdf51324aea.jpg",
        "title": "Game of Thrones",
        "region": "us",
        "parent": ["Drama", "Thriller"],
        "year": "2011",
        "image": "https://upload.wikimedia.org/wikipedia/en/9/92/Game_of_Thrones_Season_7.png",
        "description": "An epic fantasy saga where noble families vie for control of the Iron Throne and dominion over the Seven Kingdoms, amidst political intrigue, betrayal, and ancient supernatural forces."
      },
      "breaking_bad": {
        "type": "TV Show",
        "network": "AMC",
        "networklogo": "https://www.pngall.com/wp-content/uploads/15/AMC-Logo-PNG-File.png",
        "title": "Breaking Bad",
        "region": "us",
        "parent": ["Drama", "Thriller"],
        "year": "2008",
        "image": "https://upload.wikimedia.org/wikipedia/pt/1/15/Breaking_bad_4_temporada_poster.jpg",
        "description": "A compelling drama following a high school chemistry teacher turned methamphetamine manufacturer as he descends into the criminal underworld to secure his family's financial future, facing moral dilemmas and violent adversaries."
      }
    }
  }
  // --- Dummy date (temp)

  // TODO: Build a reusable 'report' wrapper template out of this
  return (
    <Layout autoscroll={urlChange} header={{
      "title": "Ads Overview",
      "search": ""
    }}>

      {/* Breadcrumbs header (defaults to "All campaigns") */}
      {(title || genre) ? <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">
        <span onClick={() => {
          navigate("/content")// ALT (w/ non-shareable global vars instead): globals.set("dropdown_selection_campaigns", "allcampaigns")
        }}>Overview</span>
        <span onClick={() => {
          navigate("/content?genre=" + genre)// ALT (w/ non-shareable global vars instead): globals.set("dropdown_selection_campaigns", "allcampaigns")
        }}>{Upperize(genre)}</span>
        {title ? <span>{Upperize(title)}</span> : ""}
      </div> : <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">Overview</div>}

      {/* Grid of boxes */}
      {(genre || title) ? <Grid
        content={[
          // <Details data={obj_top_new}/>
          <Details data={title ? details?.title?.[title] : details?.genre?.[genre]} mode={title ? 2 :/*implict:genre*/1}/>
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 0,
          h: title ? 320 : 240
        }}
        flat={true}
      /> : ""
      }

      <Grid
        content={[
          ROTANA_DEMO_MODE 
          ? 
          <Wheel
            text={{
              title: "Heartbeat",
              subtitle: ""
            }}
            data={arr_arc}
            mode={1}
          /> : <div className="minibox-wrapper">
              <div className="minibox-head">
                <span className="text-xs-b">Engagement score</span>
                <Tick value={"10%"}/>
              </div>
              <div className={"minibox-body text-xs"}>
                {ready ? <Wheel
                  data={[[69,-1]]}
                  mode={1}
                /> : ""}
              </div>  
            </div>,


          ROTANA_DEMO_MODE ? "" : <div className="minibox-wrapper minibox-wrapper-alt">
            <div className="minibox-head">
              <span className="text-xs-b">Total sessions</span>
              <Tick value={"4%"}/>
            </div>
            <div className={"minibox-body text-xs"}>
              {/* <ChartHistory data={[]}/> */}


              <ChartLine data={/* TODO: Decouple text from data (the same way we do for the newer components) */
                {
                  scale: ["W1", "", "", "", "", "", "", "W2", "", "", "", "", "", "", "W3", "", "", "", "", "", "", "W4", "", "", "", "", "", "", "W5"],
                  curve: !ready ? (new Array(29).fill(-1)) : obj_val3
                }
              }/>



            </div>
          </div>,
          
            
          ready/* Here too to animate bars on 1st load */ ? 
            ROTANA_DEMO_MODE ? <ChartBars 
              text={{
                title: "History"
                // subtitle: "Your audience engagement over time"
              }}
              data={arr_bar}
              disabled={!!ROTANA_DEMO_MODE}
            /> : 
            
            // TODO: Make a layout wrapper component out of this (best as part of the Grid's boxes)
            <div className="minibox-wrapper">
              <div className="minibox-head">
                <span className="text-xs-b">Subscribers</span>
                <Tick value={"11%"}/>
              </div>
              <div className={"minibox-body"}>
                <PieArc /* pct={obj_frt[0]} val={obj_frt[1]} */ values={["62%","38%"]} percentage={obj_val2?.[2]} mode={2}/>
              </div>
            </div>
          
          : ""
        ]} 
        template={ROTANA_DEMO_MODE 
          ? `
            0 1 1
            `
          : `
            0 1 2
            `}
        cellSize={{
          w: 255,
          h: 320
        }}
      />

      {!undefinedCampaign || genre || title ? "" : <Grid
        content={[
          <div className="minibox-wrapper">
            <div className="minibox-head text-xs-b">
              <span>Top genres</span>
              <div className="minibox-head__more" onClick={() => {
                seeMore([!more?.[0], more?.[1]])
              }}>{!more?.[0] 
              ? 
                <span>See more</span> 
              : 
                <>
                  <input type="text" className="text-xs" placeholder="Search" disabled/>
                  <span>See less</span>
                </>
              }</div>  
            </div>
            <Table
              head={{
                "ticks": [],
                "width": [.19, .11, .13, .13, .13, .13, .19],
                "place": ["l", "r", "r", "r", "r", "r", "r"],
                "names": ["Titles", "Engagement", "Unique visits", "Avg watch time", "New subscribers", "Visits frequency", "Paid / Promo"]
              }}
              body={{
                "stick": false,
                "lines": !more?.[0]
                ?
                [
                  [<Orb what={{name: "Drama", link: "/content?genre=drama", icon: "https://visualpharm.com/assets/338/Drama-595b40b65ba036ed117d1b62.svg"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Thriller", link: "/content?genre=thriller", icon: "https://visualpharm.com/assets/936/Thriller-595b40b65ba036ed117d25dc.svg"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Romance", link: "/content?genre=romance", icon: "https://visualpharm.com/assets/690/Novel-595b40b65ba036ed117d20b7.svg"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>]
                ]
                :
                [
                  [<Orb what={{name: "Drama", link: "/content?genre=drama", icon: "https://visualpharm.com/assets/338/Drama-595b40b65ba036ed117d1b62.svg"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Thriller", link: "/content?genre=thriller", icon: "https://visualpharm.com/assets/936/Thriller-595b40b65ba036ed117d25dc.svg"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Romance", link: "/content?genre=romance", icon: "https://visualpharm.com/assets/690/Novel-595b40b65ba036ed117d20b7.svg"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>],
                  [<Orb what={{name: "Drama", link: "/content?genre=drama", icon: "https://visualpharm.com/assets/338/Drama-595b40b65ba036ed117d1b62.svg"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Thriller", link: "/content?genre=thriller", icon: "https://visualpharm.com/assets/936/Thriller-595b40b65ba036ed117d25dc.svg"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Romance", link: "/content?genre=romance", icon: "https://visualpharm.com/assets/690/Novel-595b40b65ba036ed117d20b7.svg"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>]
                ]
                ,
                "limit": 0 //more?.[0] ? 0 : 1
              }}
              foot={{
                "tails": Infinity
              }}
            />
          </div>
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 320,
          h: 0
        }}
      />}

      {!undefinedCampaign || title ? "" : <Grid
        content={[
          <div className="minibox-wrapper">
            <div className="minibox-head text-xs-b">
              <span>Top titles</span>
              <div className="minibox-head__more" onClick={() => {
                  seeMore([more?.[0], !more?.[1]])
              }}>{!more?.[1] 
              ? 
                <span>See more</span> 
              : 
                <>
                  <input type="text" className="text-xs" placeholder="Search" disabled/>
                  <span>See less</span>
                </>
              }</div>  
            </div>
            <Table
              head={{
                "ticks": [],
                "width": [.19, .10, .13, .13, .13, .13, .19],
                "place": ["l", "r", "r", "r", "r", "r", "r"],
                "names": ["Titles", "Engagement", "Unique visits", "Avg watch time", "New subscribers", "Visits frequency", "Paid / Promo"]
              }}
              body={{
                "stick": false,
                "lines": !more?.[1]
                ? 
                [
                  [<Orb what={{name: "Squid Game", link: "/content?title=squid_game", icon: "https://www.looper.com/img/gallery/the-surprising-origins-of-squid-games-iconic-doll/intro-1634060083.webp"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Game of Thrones", link: "/content?title=game_of_thrones", icon: "https://a1cf74336522e87f135f-2f21ace9a6cf0052456644b80fa06d4f.ssl.cf2.rackcdn.com/images/characters/large/2800/Tyrion-Lannister.Game-of-Thrones.webp"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Breaking Bad", link: "/content?title=breaking_bad", icon: "https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2018/01/breaking_bad_vince_gilligan_amc.jpg?w=1000&quality=55&strip=all&ssl=1"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>]
                ]
                :
                [
                  [<Orb what={{name: "Squid Game", link: "/content?title=squid_game", icon: "https://www.looper.com/img/gallery/the-surprising-origins-of-squid-games-iconic-doll/intro-1634060083.webp"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Game of Thrones", link: "/content?title=game_of_thrones", icon: "https://a1cf74336522e87f135f-2f21ace9a6cf0052456644b80fa06d4f.ssl.cf2.rackcdn.com/images/characters/large/2800/Tyrion-Lannister.Game-of-Thrones.webp"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Breaking Bad", link: "/content?title=breaking_bad", icon: "https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2018/01/breaking_bad_vince_gilligan_amc.jpg?w=1000&quality=55&strip=all&ssl=1"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>],
                  [<Orb what={{name: "Squid Game", link: "/content?title=squid_game", icon: "https://www.looper.com/img/gallery/the-surprising-origins-of-squid-games-iconic-doll/intro-1634060083.webp"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Game of Thrones", link: "/content?title=game_of_thrones", icon: "https://a1cf74336522e87f135f-2f21ace9a6cf0052456644b80fa06d4f.ssl.cf2.rackcdn.com/images/characters/large/2800/Tyrion-Lannister.Game-of-Thrones.webp"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Breaking Bad", link: "/content?title=breaking_bad", icon: "https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2018/01/breaking_bad_vince_gilligan_amc.jpg?w=1000&quality=55&strip=all&ssl=1"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>]
                ]
                ,
                "limit": 0 //more?.[0] ? 0 : 1
              }}
              foot={{
                "tails": Infinity
              }}
            />
          </div>
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 320,
          h: 0
        }}
      />}
     
      {ROTANA_DEMO_MODE 
      ? 
      <Grid
        content={[
          <Topbar 
            data={[
              {
                "name": "Total sessions",
                "data": arr_top[1],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Unique visits",
                "data": arr_top[0],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Avg session length",
                "data": arr_top[2],
                "unit": "s"
              }
            ]}
          />
        ]}
        template={`
            0 1 2
            `}
        cellSize={{
          w: 146,
          h: 80
        }}
      />
      : 
      <Grid
        content={[
          <Topbar 
            data={[
              {
                "name": "Unique visits",
                "data": [79000, 71411],//arr_top[0],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Avg watch time",
                "data": [89, 91],//arr_top[1],
                "unit": "s"//""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Avg content visited",
                "data": [3.31, 3],//arr_top[2],
                "unit": ""
              }
            ]}

          />,
          <Topbar 
            data={[
              {
                "name": "Frequency of visits",
                "data": [1.66, 1.5],//arr_top[3],
                "unit": ""//s
              }
            ]}

          />
        ]}
        template={`
            0 1 2 3
            `}
        cellSize={{
          w: 1,
          h: 80
        }}
      />
      }
      
      {!ROTANA_DEMO_MODE // && ready
      ?
      <Grid
        content={[
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top audiences</span>
              <span className="minibox-head__more text-l" onClick={() => navigate("/content/01")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.audiences} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top locations</span>
              <span className="minibox-head__more text-l" onClick={() => navigate("/content/02")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.locations} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top category</span>
              <span className="minibox-head__more text-l" onClick={() => navigate("/content/03")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.category} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top times</span>
              <span className="minibox-head__more text-l" onClick={() => navigate("/content/05")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.times} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top platforms</span>
              <span className="minibox-head__more text-l" onClick={() => navigate("/content/04")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.platforms} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top devices</span>
              <span className="minibox-head__more text-l" onClick={() => navigate("/content/06")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.devices} mode={3} stick={false} label={"Name"}/>
          </div>,
        ]}
        template={`
            0 1!2
            3!4 5
            `}
        cellSize={{
          w: 1, 
          h: 300
        }}
      /> : ""
      }

    </Layout>
)}