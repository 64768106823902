/* TODO: 
   - Make a highly efficient/flat reversed index (e.g. {"f35c": "persona"}, ...)

*/

/*
 ===========================================================
 COUNTRIES
 ===========================================================
*/
// Countries (ISO 3166-1 alpha 2 and/orISO  3166-2)
import {ReactComponent as IconAD} from "assets/flags/AD.svg"
import {ReactComponent as IconAE} from "assets/flags/AE.svg"
import {ReactComponent as IconAF} from "assets/flags/AF.svg"
import {ReactComponent as IconAG} from "assets/flags/AG.svg"
import {ReactComponent as IconAI} from "assets/flags/AI.svg"
import {ReactComponent as IconAL} from "assets/flags/AL.svg"
import {ReactComponent as IconAM} from "assets/flags/AM.svg"
import {ReactComponent as IconAO} from "assets/flags/AO.svg"
import {ReactComponent as IconAR} from "assets/flags/AR.svg"
import {ReactComponent as IconAS} from "assets/flags/AS.svg"
import {ReactComponent as IconAT} from "assets/flags/AT.svg"
import {ReactComponent as IconAU} from "assets/flags/AU.svg"
import {ReactComponent as IconAW} from "assets/flags/AW.svg"
import {ReactComponent as IconAX} from "assets/flags/AX.svg"
import {ReactComponent as IconAZ} from "assets/flags/AZ.svg"
import {ReactComponent as IconBA} from "assets/flags/BA.svg"
import {ReactComponent as IconBB} from "assets/flags/BB.svg"
import {ReactComponent as IconBD} from "assets/flags/BD.svg"
import {ReactComponent as IconBE} from "assets/flags/BE.svg"
import {ReactComponent as IconBF} from "assets/flags/BF.svg"
import {ReactComponent as IconBG} from "assets/flags/BG.svg"
import {ReactComponent as IconBH} from "assets/flags/BH.svg"
import {ReactComponent as IconBI} from "assets/flags/BI.svg"
import {ReactComponent as IconBJ} from "assets/flags/BJ.svg"
import {ReactComponent as IconBL} from "assets/flags/BL.svg"
import {ReactComponent as IconBM} from "assets/flags/BM.svg"
import {ReactComponent as IconBN} from "assets/flags/BN.svg"
import {ReactComponent as IconBO} from "assets/flags/BO.svg"
import {ReactComponent as IconBQ} from "assets/flags/BQ.svg"
import {ReactComponent as IconBR} from "assets/flags/BR.svg"
import {ReactComponent as IconBS} from "assets/flags/BS.svg"
import {ReactComponent as IconBT} from "assets/flags/BT.svg"
import {ReactComponent as IconBW} from "assets/flags/BW.svg"
import {ReactComponent as IconBY} from "assets/flags/BY.svg"
import {ReactComponent as IconBZ} from "assets/flags/BZ.svg"
import {ReactComponent as IconCA} from "assets/flags/CA.svg"
import {ReactComponent as IconCC} from "assets/flags/CC.svg"
import {ReactComponent as IconCD} from "assets/flags/CD.svg"
import {ReactComponent as IconCF} from "assets/flags/CF.svg"
import {ReactComponent as IconCG} from "assets/flags/CG.svg"
import {ReactComponent as IconCH} from "assets/flags/CH.svg"
import {ReactComponent as IconCK} from "assets/flags/CK.svg"
import {ReactComponent as IconCL} from "assets/flags/CL.svg"
import {ReactComponent as IconCM} from "assets/flags/CM.svg"
import {ReactComponent as IconCN} from "assets/flags/CN.svg"
import {ReactComponent as IconCO} from "assets/flags/CO.svg"
import {ReactComponent as IconCR} from "assets/flags/CR.svg"
import {ReactComponent as IconCU} from "assets/flags/CU.svg"
import {ReactComponent as IconCW} from "assets/flags/CW.svg"
import {ReactComponent as IconCX} from "assets/flags/CX.svg"
import {ReactComponent as IconCY} from "assets/flags/CY.svg"
import {ReactComponent as IconCZ} from "assets/flags/CZ.svg"
import {ReactComponent as IconDE} from "assets/flags/DE.svg"
import {ReactComponent as IconDJ} from "assets/flags/DJ.svg"
import {ReactComponent as IconDK} from "assets/flags/DK.svg"
import {ReactComponent as IconDM} from "assets/flags/DM.svg"
import {ReactComponent as IconDO} from "assets/flags/DO.svg"
import {ReactComponent as IconSD} from "assets/flags/SD.svg"
import {ReactComponent as IconDZ} from "assets/flags/DZ.svg"
import {ReactComponent as IconEC} from "assets/flags/EC.svg"
import {ReactComponent as IconEE} from "assets/flags/EE.svg"
import {ReactComponent as IconEG} from "assets/flags/EG.svg"
import {ReactComponent as IconEH} from "assets/flags/EH.svg"
import {ReactComponent as IconER} from "assets/flags/ER.svg"
import {ReactComponent as IconES} from "assets/flags/ES.svg"
import {ReactComponent as IconET} from "assets/flags/ET.svg"
import {ReactComponent as IconFI} from "assets/flags/FI.svg"
import {ReactComponent as IconFJ} from "assets/flags/FJ.svg"
import {ReactComponent as IconFK} from "assets/flags/FK.svg"
import {ReactComponent as IconFM} from "assets/flags/FM.svg"
import {ReactComponent as IconFO} from "assets/flags/FO.svg"
import {ReactComponent as IconFR} from "assets/flags/FR.svg"
import {ReactComponent as IconGA} from "assets/flags/GA.svg"
import {ReactComponent as IconGB} from "assets/flags/GB.svg"
import {ReactComponent as IconGD} from "assets/flags/GD.svg"
import {ReactComponent as IconGE} from "assets/flags/GE.svg"
import {ReactComponent as IconGG} from "assets/flags/GG.svg"
import {ReactComponent as IconGH} from "assets/flags/GH.svg"
import {ReactComponent as IconGI} from "assets/flags/GI.svg"
import {ReactComponent as IconGL} from "assets/flags/GL.svg"
import {ReactComponent as IconGM} from "assets/flags/GM.svg"
import {ReactComponent as IconGN} from "assets/flags/GN.svg"
import {ReactComponent as IconGQ} from "assets/flags/GQ.svg"
import {ReactComponent as IconGR} from "assets/flags/GR.svg"
import {ReactComponent as IconGT} from "assets/flags/GT.svg"
import {ReactComponent as IconGU} from "assets/flags/GU.svg"
import {ReactComponent as IconGW} from "assets/flags/GW.svg"
import {ReactComponent as IconGY} from "assets/flags/GY.svg"
import {ReactComponent as IconHK} from "assets/flags/HK.svg"
import {ReactComponent as IconHN} from "assets/flags/HN.svg"
import {ReactComponent as IconHR} from "assets/flags/HR.svg"
import {ReactComponent as IconHT} from "assets/flags/HT.svg"
import {ReactComponent as IconHU} from "assets/flags/HU.svg"
import {ReactComponent as IconID} from "assets/flags/ID.svg"
import {ReactComponent as IconIE} from "assets/flags/IE.svg"
import {ReactComponent as IconIL} from "assets/flags/IL.svg"
import {ReactComponent as IconIM} from "assets/flags/IM.svg"
import {ReactComponent as IconIN} from "assets/flags/IN.svg"
import {ReactComponent as IconIO} from "assets/flags/IO.svg"
import {ReactComponent as IconIQ} from "assets/flags/IQ.svg"
import {ReactComponent as IconIR} from "assets/flags/IR.svg"
import {ReactComponent as IconIS} from "assets/flags/IS.svg"
import {ReactComponent as IconIT} from "assets/flags/IT.svg"
import {ReactComponent as IconJE} from "assets/flags/JE.svg"
import {ReactComponent as IconJM} from "assets/flags/JM.svg"
import {ReactComponent as IconJO} from "assets/flags/JO.svg"
import {ReactComponent as IconJP} from "assets/flags/JP.svg"
import {ReactComponent as IconKE} from "assets/flags/KE.svg"
import {ReactComponent as IconKG} from "assets/flags/KG.svg"
import {ReactComponent as IconKH} from "assets/flags/KH.svg"
import {ReactComponent as IconKI} from "assets/flags/KI.svg"
import {ReactComponent as IconKM} from "assets/flags/KM.svg"
import {ReactComponent as IconKN} from "assets/flags/KN.svg"
import {ReactComponent as IconKP} from "assets/flags/KP.svg"
import {ReactComponent as IconKR} from "assets/flags/KR.svg"
import {ReactComponent as IconKW} from "assets/flags/KW.svg"
import {ReactComponent as IconKY} from "assets/flags/KY.svg"
import {ReactComponent as IconKZ} from "assets/flags/KZ.svg"
import {ReactComponent as IconLA} from "assets/flags/LA.svg"
import {ReactComponent as IconLB} from "assets/flags/LB.svg"
import {ReactComponent as IconLC} from "assets/flags/LC.svg"
import {ReactComponent as IconLI} from "assets/flags/LI.svg"
import {ReactComponent as IconLK} from "assets/flags/LK.svg"
import {ReactComponent as IconLR} from "assets/flags/LR.svg"
import {ReactComponent as IconLS} from "assets/flags/LS.svg"
import {ReactComponent as IconLT} from "assets/flags/LT.svg"
import {ReactComponent as IconLU} from "assets/flags/LU.svg"
import {ReactComponent as IconLV} from "assets/flags/LV.svg"
import {ReactComponent as IconLY} from "assets/flags/LY.svg"
import {ReactComponent as IconMA} from "assets/flags/MA.svg"
import {ReactComponent as IconMC} from "assets/flags/MC.svg"
import {ReactComponent as IconMD} from "assets/flags/MD.svg"
import {ReactComponent as IconME} from "assets/flags/ME.svg"
import {ReactComponent as IconMG} from "assets/flags/MG.svg"
import {ReactComponent as IconMH} from "assets/flags/MH.svg"
import {ReactComponent as IconMK} from "assets/flags/MK.svg"
import {ReactComponent as IconML} from "assets/flags/ML.svg"
import {ReactComponent as IconMM} from "assets/flags/MM.svg"
import {ReactComponent as IconMN} from "assets/flags/MN.svg"
import {ReactComponent as IconMO} from "assets/flags/MO.svg"
import {ReactComponent as IconMP} from "assets/flags/MP.svg"
import {ReactComponent as IconMQ} from "assets/flags/MQ.svg"
import {ReactComponent as IconMR} from "assets/flags/MR.svg"
import {ReactComponent as IconMS} from "assets/flags/MS.svg"
import {ReactComponent as IconMT} from "assets/flags/MT.svg"
import {ReactComponent as IconMU} from "assets/flags/MU.svg"
import {ReactComponent as IconMV} from "assets/flags/MV.svg"
import {ReactComponent as IconMW} from "assets/flags/MW.svg"
import {ReactComponent as IconMX} from "assets/flags/MX.svg"
import {ReactComponent as IconMY} from "assets/flags/MY.svg"
import {ReactComponent as IconMZ} from "assets/flags/MZ.svg"
import {ReactComponent as IconNA} from "assets/flags/NA.svg"
import {ReactComponent as IconNE} from "assets/flags/NE.svg"
import {ReactComponent as IconNF} from "assets/flags/NF.svg"
import {ReactComponent as IconNG} from "assets/flags/NG.svg"
import {ReactComponent as IconNI} from "assets/flags/NI.svg"
import {ReactComponent as IconNL} from "assets/flags/NL.svg"
import {ReactComponent as IconNO} from "assets/flags/NO.svg"
import {ReactComponent as IconNP} from "assets/flags/NP.svg"
import {ReactComponent as IconNR} from "assets/flags/NR.svg"
import {ReactComponent as IconNU} from "assets/flags/NU.svg"
import {ReactComponent as IconNZ} from "assets/flags/NZ.svg"
import {ReactComponent as IconOM} from "assets/flags/OM.svg"
import {ReactComponent as IconPA} from "assets/flags/PA.svg"
import {ReactComponent as IconPE} from "assets/flags/PE.svg"
import {ReactComponent as IconPF} from "assets/flags/PF.svg"
import {ReactComponent as IconPG} from "assets/flags/PG.svg"
import {ReactComponent as IconPH} from "assets/flags/PH.svg"
import {ReactComponent as IconPK} from "assets/flags/PK.svg"
import {ReactComponent as IconPL} from "assets/flags/PL.svg"
import {ReactComponent as IconPN} from "assets/flags/PN.svg"
import {ReactComponent as IconPR} from "assets/flags/PR.svg"
import {ReactComponent as IconPS} from "assets/flags/PS.svg"
import {ReactComponent as IconPT} from "assets/flags/PT.svg"
import {ReactComponent as IconPW} from "assets/flags/PW.svg"
import {ReactComponent as IconPY} from "assets/flags/PY.svg"
import {ReactComponent as IconQA} from "assets/flags/QA.svg"
import {ReactComponent as IconRO} from "assets/flags/RO.svg"
import {ReactComponent as IconRS} from "assets/flags/RS.svg"
import {ReactComponent as IconRU} from "assets/flags/RU.svg"
import {ReactComponent as IconRW} from "assets/flags/RW.svg"
import {ReactComponent as IconSA} from "assets/flags/SA.svg"
import {ReactComponent as IconSB} from "assets/flags/SB.svg"
import {ReactComponent as IconSC} from "assets/flags/SC.svg"
import {ReactComponent as IconSE} from "assets/flags/SE.svg"
import {ReactComponent as IconSG} from "assets/flags/SG.svg"
import {ReactComponent as IconSI} from "assets/flags/SI.svg"
import {ReactComponent as IconSK} from "assets/flags/SK.svg"
import {ReactComponent as IconSL} from "assets/flags/SL.svg"
import {ReactComponent as IconSM} from "assets/flags/SM.svg"
import {ReactComponent as IconSN} from "assets/flags/SN.svg"
import {ReactComponent as IconSO} from "assets/flags/SO.svg"
import {ReactComponent as IconSR} from "assets/flags/SR.svg"
import {ReactComponent as IconSS} from "assets/flags/SS.svg"
import {ReactComponent as IconST} from "assets/flags/ST.svg"
import {ReactComponent as IconSV} from "assets/flags/SV.svg"
import {ReactComponent as IconSX} from "assets/flags/SX.svg"
import {ReactComponent as IconSY} from "assets/flags/SY.svg"
import {ReactComponent as IconSZ} from "assets/flags/SZ.svg"
import {ReactComponent as IconTC} from "assets/flags/TC.svg"
import {ReactComponent as IconTD} from "assets/flags/TD.svg"
import {ReactComponent as IconTG} from "assets/flags/TG.svg"
import {ReactComponent as IconTH} from "assets/flags/TH.svg"
import {ReactComponent as IconTJ} from "assets/flags/TJ.svg"
import {ReactComponent as IconTK} from "assets/flags/TK.svg"
import {ReactComponent as IconTL} from "assets/flags/TL.svg"
import {ReactComponent as IconTM} from "assets/flags/TM.svg"
import {ReactComponent as IconTN} from "assets/flags/TN.svg"
import {ReactComponent as IconTO} from "assets/flags/TO.svg"
import {ReactComponent as IconTR} from "assets/flags/TR.svg"
import {ReactComponent as IconTT} from "assets/flags/TT.svg"
import {ReactComponent as IconTV} from "assets/flags/TV.svg"
import {ReactComponent as IconTW} from "assets/flags/TW.svg"
import {ReactComponent as IconTZ} from "assets/flags/TZ.svg"
import {ReactComponent as IconUA} from "assets/flags/UA.svg"
import {ReactComponent as IconUG} from "assets/flags/UG.svg"
import {ReactComponent as IconUS} from "assets/flags/US.svg"
import {ReactComponent as IconUY} from "assets/flags/UY.svg"
import {ReactComponent as IconUZ} from "assets/flags/UZ.svg"
import {ReactComponent as IconVC} from "assets/flags/VC.svg"
import {ReactComponent as IconVE} from "assets/flags/VE.svg"
import {ReactComponent as IconVG} from "assets/flags/VG.svg"
import {ReactComponent as IconVI} from "assets/flags/VI.svg"
import {ReactComponent as IconVN} from "assets/flags/VN.svg"
import {ReactComponent as IconVU} from "assets/flags/VU.svg"
import {ReactComponent as IconWS} from "assets/flags/WS.svg"
import {ReactComponent as IconYE} from "assets/flags/YE.svg"
import {ReactComponent as IconZA} from "assets/flags/ZA.svg"
import {ReactComponent as IconZM} from "assets/flags/ZM.svg"
import {ReactComponent as IconZW} from "assets/flags/ZW.svg"

// Countries (custom)
import {ReactComponent as IconUN} from "assets/flags/UN.svg"
import {ReactComponent as IconUndefined} from "assets/flags/undefined.svg"

// Misc (custom)
import {ReactComponent as IconUnknown} from "assets/icons/big/other/unknown.svg"
 
// Regions
import { ReactComponent as IconRegionAA } from "assets/regions/AA.svg"
import { ReactComponent as IconRegionAF } from "assets/regions/AF.svg"
import { ReactComponent as IconRegionEA } from "assets/regions/EA.svg"
import { ReactComponent as IconRegionEU } from "assets/regions/EU.svg"
import { ReactComponent as IconRegionLA } from "assets/regions/LA.svg"
import { ReactComponent as IconRegionOC } from "assets/regions/OC.svg"
import { ReactComponent as IconRegionWA } from "assets/regions/WA.svg"
import { ReactComponent as IconRegionAN } from "assets/regions/AN.svg"

// Personas
import PicPersonaF15c from "assets/portraits/f15c.png"
import PicPersonaF20c from "assets/portraits/f20c.png"
import PicPersonaF25c from "assets/portraits/f25c.png"
import PicPersonaF30c from "assets/portraits/f30c.png"
import PicPersonaF35c from "assets/portraits/f35c.png"
import PicPersonaF40c from "assets/portraits/f40c.png"
import PicPersonaF45c from "assets/portraits/f45c.png"
import PicPersonaF50c from "assets/portraits/f50c.png"
import PicPersonaF55c from "assets/portraits/f55c.png"
import PicPersonaF60c from "assets/portraits/f60c.png"
import PicPersonaF65c from "assets/portraits/f65c.png"
import PicPersonaF70c from "assets/portraits/f70c.png"
import PicPersonaF75c from "assets/portraits/f75c.png"
import PicPersonaF80c from "assets/portraits/f80c.png"
import PicPersonaM15c from "assets/portraits/m15c.png"
import PicPersonaM20c from "assets/portraits/m20c.png"
import PicPersonaM25c from "assets/portraits/m25c.png"
import PicPersonaM30c from "assets/portraits/m30c.png"
import PicPersonaM35c from "assets/portraits/m35c.png"
import PicPersonaM40c from "assets/portraits/m40c.png"
import PicPersonaM45c from "assets/portraits/m45c.png"
import PicPersonaM50c from "assets/portraits/m50c.png"
import PicPersonaM55c from "assets/portraits/m55c.png"
import PicPersonaM60c from "assets/portraits/m60c.png"
import PicPersonaM65c from "assets/portraits/m65c.png"
import PicPersonaM70c from "assets/portraits/m70c.png"
import PicPersonaM75c from "assets/portraits/m75c.png"
import PicPersonaM80c from "assets/portraits/m80c.png"

// // Devices
// import {ReactComponent as IconAndroid} from "assets/icons/big/device/androidphone.svg"
// import {ReactComponent as IconIPhone} from "assets/icons/big/device/iphone.svg"
// import {ReactComponent as IconPC} from "assets/icons/big/device/windowspc.svg"
// import {ReactComponent as IconMac} from "assets/icons/big/device/imac.svg"
// import {ReactComponent as IconUnknown} from "assets/icons/big/other/unknown.svg"

// // Times
// import {ReactComponent as IconAm} from "assets/icons/big/other/am.svg"
// import {ReactComponent as IconPm} from "assets/icons/big/other/pm.svg"

// // Cohorts 
// import {ReactComponent as IconCohortAll} from "assets/icons/big/other/cohort.svg"

// // Custom
// import {ReactComponent as IconUN} from "assets/flags/UN.svg"
// import {ReactComponent as IconUndefined} from "assets/flags/undefined.svg"

export const MAP_LOCATIONS = {
	"ad": [<IconAD/>, "Andorra"],
	"ae": [<IconAE/>, "Emirates"/* United Arab Emirates*/],
	"af": [<IconAF/>, "Afghanistan"],
	"ag": [<IconAG/>, "Antigua"/* and Barbuda*/],
	"ai": [<IconAI/>, "Anguilla"],
	"al": [<IconAL/>, "Albania"],
	"am": [<IconAM/>, "Armenia"],
	"ao": [<IconAO/>, "Angola"],
	"ar": [<IconAR/>, "Argentina"],
	"as": [<IconAS/>, "American Samoa"],
	"at": [<IconAT/>, "Austria"],
	"au": [<IconAU/>, "Australia"],
	"aw": [<IconAW/>, "Aruba"],
	"ax": [<IconAX/>, "Aland Islands"/* Åland Islands */],
	"az": [<IconAZ/>, "Azerbaijan"],
	"ba": [<IconBA/>, "Bosnia"/* and Herzegovina*/],
	"bb": [<IconBB/>, "Barbados"],
	"bd": [<IconBD/>, "Bangladesh"],
	"be": [<IconBE/>, "Belgium"],
	"bf": [<IconBF/>, "Burkina Faso"],
	"bg": [<IconBG/>, "Bulgaria"],
	"bh": [<IconBH/>, "Bahrain"],
	"bi": [<IconBI/>, "Burundi"],
	"bj": [<IconBJ/>, "Benin"],
	"bl": [<IconBL/>, "St. Barthélemy"/* Saint Barthélemy*/],
	"bm": [<IconBM/>, "Bermuda"],
	"bn": [<IconBN/>, "Brunei"/* Brunei Darussalam */],
	"bo": [<IconBO/>, "Bolivia"/* (Plurinational State of)*/],
	"bq": [<IconBQ/>, "Bonaire"/*, Sint Eustatius and Saba*/],
	"br": [<IconBR/>, "Brazil"],
	"bs": [<IconBS/>, "Bahamas"],
	"bt": [<IconBT/>, "Bhutan"],
	"bw": [<IconBW/>, "Botswana"],
	"by": [<IconBY/>, "Belarus"],
	"bz": [<IconBZ/>, "Belize"],
	"ca": [<IconCA/>, "Canada"],
	"cc": [<IconCC/>, "Cocos Isl."/* (Keeling) Islands*/],
	"cd": [<IconCD/>, "Congo"/*, Democratic Republic of the*/],
	"cf": [<IconCF/>, "C. African Rep."/* Central African Republic*/],
	"cg": [<IconCG/>, "Congo"],
	"ch": [<IconCH/>, "Switzerland"],
	"ck": [<IconCK/>, "Cook Isl."/* Cook Islands*/],
	"cl": [<IconCL/>, "Chile"],
	"cm": [<IconCM/>, "Cameroon"],
	"cn": [<IconCN/>, "China"],
	"co": [<IconCO/>, "Colombia"],
	"cr": [<IconCR/>, "Costa Rica"],
	"cu": [<IconCU/>, "Cuba"],
	"cw": [<IconCW/>, "Curaçao"],
	"cx": [<IconCX/>, "Christmas Isl."/* Christmas Island*/],
	"cy": [<IconCY/>, "Cyprus"],
	"cz": [<IconCZ/>, "Czechia"],
	"de": [<IconDE/>, "Germany"],
	"dj": [<IconDJ/>, "Djibouti"],
	"dk": [<IconDK/>, "Denmark"],
	"dm": [<IconDM/>, "Dominica"],
	"do": [<IconDO/>, "Dominican Rep."/* Dominican Republic*/],
	"dz": [<IconDZ/>, "Algeria"],
	"ec": [<IconEC/>, "Ecuador"],
	"ee": [<IconEE/>, "Estonia"],
	"eg": [<IconEG/>, "Egypt"],
	"eh": [<IconEH/>, "Western Sahara"],
	"er": [<IconER/>, "Eritrea"],
	"es": [<IconES/>, "Spain"],
	"et": [<IconET/>, "Ethiopia"],
	"fi": [<IconFI/>, "Finland"],
	"fj": [<IconFJ/>, "Fiji"],
	"fk": [<IconFK/>, "Malvinas"/*Falkland Islands (Malvinas)*/],
	"fm": [<IconFM/>, "Micronesia"/* (Federated States of)*/],
	"fo": [<IconFO/>, "Faroe Islands"],
	"fr": [<IconFR/>, "France"],
	"ga": [<IconGA/>, "Gabon"],
	"gb": [<IconGB/>, "United Kingdom"/* of Great Britain and Northern Ireland*/],
	"gd": [<IconGD/>, "Grenada"],
	"ge": [<IconGE/>, "Georgia"],
	"gg": [<IconGG/>, "Guernsey"],
	"gh": [<IconGH/>, "Ghana"],
	"gi": [<IconGI/>, "Gibraltar"],
	"gl": [<IconGL/>, "Greenland"],
	"gm": [<IconGM/>, "Gambia"],
	"gn": [<IconGN/>, "Guinea"],
	"gq": [<IconGQ/>, "Eq. Guinea"/* Equatorial Guinea*/],
	"gr": [<IconGR/>, "Greece"],
	"gt": [<IconGT/>, "Guatemala"],
	"gu": [<IconGU/>, "Guam"],
	"gw": [<IconGW/>, "Guinea-Bissau"],
	"gy": [<IconGY/>, "Guyana"],
	"hk": [<IconHK/>, "Hong Kong"],
	"hn": [<IconHN/>, "Honduras"],
	"hr": [<IconHR/>, "Croatia"],
	"ht": [<IconHT/>, "Haiti"],
	"hu": [<IconHU/>, "Hungary"],
	"id": [<IconID/>, "Indonesia"],
	"ie": [<IconIE/>, "Ireland"],
	"il": [<IconIL/>, "Israel"],
	"im": [<IconIM/>, "Isle of Man"],
	"in": [<IconIN/>, "India"],
	"io": [<IconIO/>, "Brit. Indian"/* British Indian Ocean Territory*/],
	"iq": [<IconIQ/>, "Iraq"],
	"ir": [<IconIR/>, "Iran"/* (Islamic Republic of)*/],
	"is": [<IconIS/>, "Iceland"],
	"it": [<IconIT/>, "Italy"],
	"je": [<IconJE/>, "Jersey"],
	"jm": [<IconJM/>, "Jamaica"],
	"jo": [<IconJO/>, "Jordan"],
	"jp": [<IconJP/>, "Japan"],
	"ke": [<IconKE/>, "Kenya"],
	"kg": [<IconKG/>, "Kyrgyzstan"],
	"kh": [<IconKH/>, "Cambodia"],
	"ki": [<IconKI/>, "Kiribati"],
	"km": [<IconKM/>, "Comoros"],
	"kn": [<IconKN/>, "Saint Kitts"/* and Nevis*/],
	"kp": [<IconKP/>, "North Korea"/* Korea (Democratic People's Republic of)*/],
	"kr": [<IconKR/>, "South Korea"/* Korea, Republic of*/],
	"kw": [<IconKW/>, "Kuwait"],
	"ky": [<IconKY/>, "Cayman Isl."/* Cayman Islands*/],
	"kz": [<IconKZ/>, "Kazakhstan"],
	"la": [<IconLA/>, "Lao"/* People's Democratic Republic*/],
	"lb": [<IconLB/>, "Lebanon"],
	"lc": [<IconLC/>, "Saint Lucia"],
	"li": [<IconLI/>, "Liechtenstein"],
	"lk": [<IconLK/>, "Sri Lanka"],
	"lr": [<IconLR/>, "Liberia"],
	"ls": [<IconLS/>, "Lesotho"],
	"lt": [<IconLT/>, "Lithuania"],
	"lu": [<IconLU/>, "Luxembourg"],
	"lv": [<IconLV/>, "Latvia"],
	"ly": [<IconLY/>, "Libya"],
	"ma": [<IconMA/>, "Morocco"],
	"mc": [<IconMC/>, "Monaco"],
	"md": [<IconMD/>, "Moldova"/*, Republic of*/],
	"me": [<IconME/>, "Montenegro"],
	"mg": [<IconMG/>, "Madagascar"],
	"mh": [<IconMH/>, "Marshall Isl."/* Marshall Islands*/],
	"mk": [<IconMK/>, "N. Macedonia"/* North Macedonia*/],
	"ml": [<IconML/>, "Mali"],
	"mm": [<IconMM/>, "Myanmar"],
	"mn": [<IconMN/>, "Mongolia"],
	"mo": [<IconMO/>, "Macao"],
	"mp": [<IconMP/>, "Mariana Isl."/* Northern Mariana Islands*/],
	"mq": [<IconMQ/>, "Martinique"],
	"mr": [<IconMR/>, "Mauritania"],
	"ms": [<IconMS/>, "Montserrat"],
	"mt": [<IconMT/>, "Malta"],
	"mu": [<IconMU/>, "Mauritius"],
	"mv": [<IconMV/>, "Maldives"],
	"mw": [<IconMW/>, "Malawi"],
	"mx": [<IconMX/>, "Mexico"],
	"my": [<IconMY/>, "Malaysia"],
	"mz": [<IconMZ/>, "Mozambique"],
	"na": [<IconNA/>, "Namibia"],
	"ne": [<IconNE/>, "Niger"],
	"nf": [<IconNF/>, "Norfolk Isl."],
	"ng": [<IconNG/>, "Nigeria"],
	"ni": [<IconNI/>, "Nicaragua"],
	"nl": [<IconNL/>, "Netherlands"/*, Kingdom of the*/],
	"no": [<IconNO/>, "Norway"],
	"np": [<IconNP/>, "Nepal"],
	"nr": [<IconNR/>, "Nauru"],
	"nu": [<IconNU/>, "Niue"],
	"nz": [<IconNZ/>, "New Zealand"],
	"om": [<IconOM/>, "Oman"],
	"pa": [<IconPA/>, "Panama"],
	"pe": [<IconPE/>, "Peru"],
	"pf": [<IconPF/>, "Polynesia"/* French Polynesia*/],
	"pg": [<IconPG/>, "New Guinea"/* Papua New Guinea*/],
	"ph": [<IconPH/>, "Philippines"],
	"pk": [<IconPK/>, "Pakistan"],
	"pl": [<IconPL/>, "Poland"],
	"pn": [<IconPN/>, "Pitcairn"],
	"pr": [<IconPR/>, "Puerto Rico"],
	"ps": [<IconPS/>, "Palestine"/*, State of*/],
	"pt": [<IconPT/>, "Portugal"],
	"pw": [<IconPW/>, "Palau"],
	"py": [<IconPY/>, "Paraguay"],
	"qa": [<IconQA/>, "Qatar"],
	"ro": [<IconRO/>, "Romania"],
	"rs": [<IconRS/>, "Serbia"],
	"ru": [<IconRU/>, "Russia"/* Russian Federation*/],
	"rw": [<IconRW/>, "Rwanda"],
	"sa": [<IconSA/>, "Saudi Arabia"],
	"sb": [<IconSB/>, "Solomon Isl."/* Solomon Islands*/],
	"sc": [<IconSC/>, "Seychelles"],
	"sd": [<IconSD/>, "Sudan"/* The Republic of Sudan | North Sudan*/],
	"se": [<IconSE/>, "Sweden"],
	"sg": [<IconSG/>, "Singapore"],
	"si": [<IconSI/>, "Slovenia"],
	"sk": [<IconSK/>, "Slovakia"],
	"sl": [<IconSL/>, "Sierra Leone"],
	"sm": [<IconSM/>, "San Marino"],
	"sn": [<IconSN/>, "Senegal"],
	"so": [<IconSO/>, "Somalia"],
	"sr": [<IconSR/>, "Suriname"],
	"ss": [<IconSS/>, "South Sudan"],
	"st": [<IconST/>, "Sao Tome"/* and Principe*/],
	"sv": [<IconSV/>, "El Salvador"],
	"sx": [<IconSX/>, "Sint Maarten"/* (Dutch part)*/],
	"sy": [<IconSY/>, "Syria"/* Syrian Arab Republic*/],
	"sz": [<IconSZ/>, "Eswatini"],
	"tc": [<IconTC/>, "Turks Isl."/* and Caicos Islands*/],
	"td": [<IconTD/>, "Chad"],
	"tg": [<IconTG/>, "Togo"],
	"th": [<IconTH/>, "Thailand"],
	"tj": [<IconTJ/>, "Tajikistan"],
	"tk": [<IconTK/>, "Tokelau"],
	"tl": [<IconTL/>, "Timor-Leste"],
	"tm": [<IconTM/>, "Turkmenistan"],
	"tn": [<IconTN/>, "Tunisia"],
	"to": [<IconTO/>, "Tonga"],
	"tr": [<IconTR/>, "Turkey"/* Türkiye */],
	"tt": [<IconTT/>, "Trinidad"/* and Tobago*/],
	"tv": [<IconTV/>, "Tuvalu"],
	"tw": [<IconTW/>, "Taiwan"/*, Province of China*/],
	"tz": [<IconTZ/>, "Tanzania"/*, United Republic of*/],
	"ua": [<IconUA/>, "Ukraine"],
	"ug": [<IconUG/>, "Uganda"],
	"us": [<IconUS/>, "United States"/* of America*/],
	"uy": [<IconUY/>, "Uruguay"],
	"uz": [<IconUZ/>, "Uzbekistan"],
	"vc": [<IconVC/>, "Saint Vincent"/* and the Grenadines*/],
	"ve": [<IconVE/>, "Venezuela"/* (Bolivarian Republic of)*/],
	"vg": [<IconVG/>, "Virgin Isl. (GB)"/* Virgin Islands (British)*/],
	"vi": [<IconVI/>, "Virgin Isl. (US)"/* Virgin Islands (U.S.)*/],
	"vn": [<IconVN/>, "Viet Nam"],
	"vu": [<IconVU/>, "Vanuatu"],
	"ws": [<IconWS/>, "Samoa"],
	"ye": [<IconYE/>, "Yemen"],
	"za": [<IconZA/>, "South Africa"],
	"zm": [<IconZM/>, "Zambia"],
	"zw": [<IconZW/>, "Zimbabwe"],

	// === HIDE TO OMIT ===
	// // Countries (~non ISO 3166-1 alpha 2 and/orISO  3166-2)
	// "ci": [<IconUndefined/>, "Ivory Coast"],/* Côte d'Ivoire */
	// "cv": [<IconUndefined/>, "Cape Verde"],/* Cabo Verde */
	// "gf": [<IconUndefined/>, "Frech Guiana"],/* Guyane */
	// "gp": [<IconUndefined/>, "Guadeloupe"],
	// "mf": [<IconUndefined/>, "Saint Martin"],
	// "nc": [<IconUndefined/>, "New Caledonia"],
	// "pm": [<IconUndefined/>, "Saint Pierre"],// "Saint Pierre and Miquelon" */
	// "re": [<IconUndefined/>, "Reunion Island"],/* Réunion */
	// "sh": [<IconUndefined/>, "Saint Helena"],/* Saint Helena, Ascension and Tristan da Cunha */
	// "sj": [<IconUndefined/>, "Svalbard & Jan Mayen"],/* Svalbard and Jan Mayen */
	// "um": [<IconUndefined/>, "Pacific Islands"],/* United States Miscellaneous Pacific Islands | United States Minor Outlying Islands */
	// "wf": [<IconUndefined/>, "Wallis and Futuna"],
	// "xk": [<IconUndefined/>, "Kosovo"],
	// "yt": [<IconUndefined/>, "Mayotte"],
	// === HIDE TO OMIT ===

	// Custom 
	"un": [<IconUN/>, "World"],
	"all_locations": [<IconUN/>, "*"],/* -> ALT: "All locations" */
	"undefined": [<IconUndefined/>, "Undefined"],
	"unknown": [<IconUndefined/>, "Undefined"],	
	"unknown_location": [<IconUndefined/>, "Undefined"],
	"null": [<IconUndefined/>, "Undefined"]
	
}


export const MAP_LOCATIONS_V2 = {
	"eu_ad": [<IconAD/>, "Andorra"],
	"wa_ae": [<IconAE/>, "Emirates"/* United Arab Emirates*/],
	"wa_af": [<IconAF/>, "Afghanistan"],
	"la_ag": [<IconAG/>, "Antigua"/* and Barbuda*/],
	"la_ai": [<IconAI/>, "Anguilla"],
	"eu_al": [<IconAL/>, "Albania"],
	"wa_am": [<IconAM/>, "Armenia"],
	"af_ao": [<IconAO/>, "Angola"],
	"la_ar": [<IconAR/>, "Argentina"],
	"oc_as": [<IconAS/>, "American Samoa"],
	"eu_at": [<IconAT/>, "Austria"],
	"oc_au": [<IconAU/>, "Australia"],
	"la_aw": [<IconAW/>, "Aruba"],
	"eu_ax": [<IconAX/>, "Aland Islands"/* Åland Islands */],
	"wa_az": [<IconAZ/>, "Azerbaijan"],
	"eu_ba": [<IconBA/>, "Bosnia"/* and Herzegovina*/],
	"la_bb": [<IconBB/>, "Barbados"],
	"ea_bd": [<IconBD/>, "Bangladesh"],
	"eu_be": [<IconBE/>, "Belgium"],
	"af_bf": [<IconBF/>, "Burkina Faso"],
	"eu_bg": [<IconBG/>, "Bulgaria"],
	"wa_bh": [<IconBH/>, "Bahrain"],
	"af_bi": [<IconBI/>, "Burundi"],
	"af_bj": [<IconBJ/>, "Benin"],
	"la_bl": [<IconBL/>, "St. Barthélemy"/* Saint Barthélemy*/],
	"aa_bm": [<IconBM/>, "Bermuda"],
	"oc_bn": [<IconBN/>, "Brunei"/* Brunei Darussalam */],
	"la_bo": [<IconBO/>, "Bolivia"/* (Plurinational State of)*/],
	"la_bq": [<IconBQ/>, "Bonaire"/*, Sint Eustatius and Saba*/],
	"la_br": [<IconBR/>, "Brazil"],
	"la_bs": [<IconBS/>, "Bahamas"],
	"ea_bt": [<IconBT/>, "Bhutan"],
	"af_bw": [<IconBW/>, "Botswana"],
	"eu_by": [<IconBY/>, "Belarus"],
	"la_bz": [<IconBZ/>, "Belize"],
	"aa_ca": [<IconCA/>, "Canada"],
	"ea_cc": [<IconCC/>, "Cocos Isl."/* (Keeling) Islands*/],
	"af_cd": [<IconCD/>, "Congo"/*, Democratic Republic of the*/],
	"af_cf": [<IconCF/>, "C. African Rep."/* Central African Republic*/],
	"af_cg": [<IconCG/>, "Congo"],
	"eu_ch": [<IconCH/>, "Switzerland"],
	"oc_ck": [<IconCK/>, "Cook Isl."/* Cook Islands*/],
	"la_cl": [<IconCL/>, "Chile"],
	"af_cm": [<IconCM/>, "Cameroon"],
	"ea_cn": [<IconCN/>, "China"],
	"la_co": [<IconCO/>, "Colombia"],
	"la_cr": [<IconCR/>, "Costa Rica"],
	"la_cu": [<IconCU/>, "Cuba"],
	"la_cw": [<IconCW/>, "Curaçao"],
	"oc_cx": [<IconCX/>, "Christmas Isl."/* Christmas Island*/],
	"eu_cy": [<IconCY/>, "Cyprus"],
	"eu_cz": [<IconCZ/>, "Czechia"],
	"eu_de": [<IconDE/>, "Germany"],
	"af_dj": [<IconDJ/>, "Djibouti"],
	"eu_dk": [<IconDK/>, "Denmark"],
	"la_dm": [<IconDM/>, "Dominica"],
	"la_do": [<IconDO/>, "Dominican Rep."/* Dominican Republic*/],
	"af_dz": [<IconDZ/>, "Algeria"],
	"la_ec": [<IconEC/>, "Ecuador"],
	"eu_ee": [<IconEE/>, "Estonia"],
	"wa_eg": [<IconEG/>, "Egypt"],
	"af_eh": [<IconEH/>, "Western Sahara"],
	"af_er": [<IconER/>, "Eritrea"],
	"eu_es": [<IconES/>, "Spain"],
	"af_et": [<IconET/>, "Ethiopia"],
	"eu_fi": [<IconFI/>, "Finland"],
	"oc_fj": [<IconFJ/>, "Fiji"],
	"la_fk": [<IconFK/>, "Malvinas"/*Falkland Islands (Malvinas)*/],
	"oc_fm": [<IconFM/>, "Micronesia"/* (Federated States of)*/],
	"eu_fo": [<IconFO/>, "Faroe Islands"],
	"eu_fr": [<IconFR/>, "France"],
	"af_ga": [<IconGA/>, "Gabon"],
	"eu_gb": [<IconGB/>, "United Kingdom"/* of Great Britain and Northern Ireland*/],
	"la_gd": [<IconGD/>, "Grenada"],
	"wa_ge": [<IconGE/>, "Georgia"],
	"eu_gg": [<IconGG/>, "Guernsey"],
	"af_gh": [<IconGH/>, "Ghana"],
	"eu_gi": [<IconGI/>, "Gibraltar"],
	"eu_gl": [<IconGL/>, "Greenland"],
	"af_gm": [<IconGM/>, "Gambia"],
	"af_gn": [<IconGN/>, "Guinea"],
	"af_gq": [<IconGQ/>, "Eq. Guinea"/* Equatorial Guinea*/],
	"eu_gr": [<IconGR/>, "Greece"],
	"la_gt": [<IconGT/>, "Guatemala"],
	"aa_gu": [<IconGU/>, "Guam"],
	"af_gw": [<IconGW/>, "Guinea-Bissau"],
	"la_gy": [<IconGY/>, "Guyana"],
	"ea_hk": [<IconHK/>, "Hong Kong"],
	"la_hn": [<IconHN/>, "Honduras"],
	"eu_hr": [<IconHR/>, "Croatia"],
	"la_ht": [<IconHT/>, "Haiti"],
	"eu_hu": [<IconHU/>, "Hungary"],
	"ea_id": [<IconID/>, "Indonesia"],
	"eu_ie": [<IconIE/>, "Ireland"],
	"wa_il": [<IconIL/>, "Israel"],
	"eu_im": [<IconIM/>, "Isle of Man"],
	"ea_in": [<IconIN/>, "India"],
	"eu_io": [<IconIO/>, "Brit. Indian"/* British Indian Ocean Territory*/],
	"wa_iq": [<IconIQ/>, "Iraq"],
	"wa_ir": [<IconIR/>, "Iran"/* (Islamic Republic of)*/],
	"eu_is": [<IconIS/>, "Iceland"],
	"eu_it": [<IconIT/>, "Italy"],
	"eu_je": [<IconJE/>, "Jersey"],
	"la_jm": [<IconJM/>, "Jamaica"],
	"wa_jo": [<IconJO/>, "Jordan"],
	"ea_jp": [<IconJP/>, "Japan"],
	"af_ke": [<IconKE/>, "Kenya"],
	"wa_kg": [<IconKG/>, "Kyrgyzstan"],
	"ea_kh": [<IconKH/>, "Cambodia"],
	"oc_ki": [<IconKI/>, "Kiribati"],
	"af_km": [<IconKM/>, "Comoros"],
	"la_kn": [<IconKN/>, "Saint Kitts"/* and Nevis*/],
	"ea_kp": [<IconKP/>, "North Korea"/* Korea (Democratic People's Republic of)*/],
	"ea_kr": [<IconKR/>, "South Korea"/* Korea, Republic of*/],
	"wa_kw": [<IconKW/>, "Kuwait"],
	"aa_ky": [<IconKY/>, "Cayman Isl."/* Cayman Islands*/],
	"ea_kz": [<IconKZ/>, "Kazakhstan"],
	"ea_la": [<IconLA/>, "Lao"/* People's Democratic Republic*/],
	"wa_lb": [<IconLB/>, "Lebanon"],
	"la_lc": [<IconLC/>, "Saint Lucia"],
	"eu_li": [<IconLI/>, "Liechtenstein"],
	"af_lk": [<IconLK/>, "Sri Lanka"],
	"wa_lr": [<IconLR/>, "Liberia"],
	"af_ls": [<IconLS/>, "Lesotho"],
	"eu_lt": [<IconLT/>, "Lithuania"],
	"eu_lu": [<IconLU/>, "Luxembourg"],
	"eu_lv": [<IconLV/>, "Latvia"],
	"wa_ly": [<IconLY/>, "Libya"],
	"eu_ma": [<IconMA/>, "Morocco"],
	"eu_mc": [<IconMC/>, "Monaco"],
	"eu_md": [<IconMD/>, "Moldova"/*, Republic of*/],
	"eu_me": [<IconME/>, "Montenegro"],
	"af_mg": [<IconMG/>, "Madagascar"],
	"oc_mh": [<IconMH/>, "Marshall Isl."/* Marshall Islands*/],
	"eu_mk": [<IconMK/>, "N. Macedonia"/* North Macedonia*/],
	"af_ml": [<IconML/>, "Mali"],
	"ea_mm": [<IconMM/>, "Myanmar"],
	"ea_mn": [<IconMN/>, "Mongolia"],
	"ea_mo": [<IconMO/>, "Macao"],
	"ea_mp": [<IconMP/>, "Mariana Isl."/* Northern Mariana Islands*/],
	"la_mq": [<IconMQ/>, "Martinique"],
	"af_mr": [<IconMR/>, "Mauritania"],
	"aa_ms": [<IconMS/>, "Montserrat"],
	"eu_mt": [<IconMT/>, "Malta"],
	"af_mu": [<IconMU/>, "Mauritius"],
	"ea_mv": [<IconMV/>, "Maldives"],
	"af_mw": [<IconMW/>, "Malawi"],
	"la_mx": [<IconMX/>, "Mexico"],
	"ea_my": [<IconMY/>, "Malaysia"],
	"af_mz": [<IconMZ/>, "Mozambique"],
	"af_na": [<IconNA/>, "Namibia"],
	"af_ne": [<IconNE/>, "Niger"],
	"oc_nf": [<IconNF/>, "Norfolk Isl."],
	"af_ng": [<IconNG/>, "Nigeria"],
	"la_ni": [<IconNI/>, "Nicaragua"],
	"eu_nl": [<IconNL/>, "Netherlands"/*, Kingdom of the*/],
	"eu_no": [<IconNO/>, "Norway"],
	"ea_np": [<IconNP/>, "Nepal"],
	"oc_nr": [<IconNR/>, "Nauru"],
	"oc_nu": [<IconNU/>, "Niue"],
	"oc_nz": [<IconNZ/>, "New Zealand"],
	"wa_om": [<IconOM/>, "Oman"],
	"la_pa": [<IconPA/>, "Panama"],
	"la_pe": [<IconPE/>, "Peru"],
	"oc_pf": [<IconPF/>, "Polynesia"/* French Polynesia*/],
	"oc_pg": [<IconPG/>, "New Guinea"/* Papua New Guinea*/],
	"ea_ph": [<IconPH/>, "Philippines"],
	"wa_pk": [<IconPK/>, "Pakistan"],
	"eu_pl": [<IconPL/>, "Poland"],
	"la_pn": [<IconPN/>, "Pitcairn"],
	"la_pr": [<IconPR/>, "Puerto Rico"],
	"wa_ps": [<IconPS/>, "Palestine"/*, State of*/],
	"eu_pt": [<IconPT/>, "Portugal"],
	"ea_pw": [<IconPW/>, "Palau"],
	"la_py": [<IconPY/>, "Paraguay"],
	"wa_qa": [<IconQA/>, "Qatar"],
	"eu_ro": [<IconRO/>, "Romania"],
	"eu_rs": [<IconRS/>, "Serbia"],
	"ea_ru": [<IconRU/>, "Russia"/* Russian Federation*/],
	"af_rw": [<IconRW/>, "Rwanda"],
	"wa_sa": [<IconSA/>, "Saudi Arabia"],
	"oc_sb": [<IconSB/>, "Solomon Isl."/* Solomon Islands*/],
	"af_sc": [<IconSC/>, "Seychelles"],
	"af_sd": [<IconSD/>, "Sudan"/* The Republic of Sudan | North Sudan*/],
	"eu_se": [<IconSE/>, "Sweden"],
	"ea_sg": [<IconSG/>, "Singapore"],
	"eu_si": [<IconSI/>, "Slovenia"],
	"eu_sk": [<IconSK/>, "Slovakia"],
	"af_sl": [<IconSL/>, "Sierra Leone"],
	"eu_sm": [<IconSM/>, "San Marino"],
	"af_sn": [<IconSN/>, "Senegal"],
	"af_so": [<IconSO/>, "Somalia"],
	"la_sr": [<IconSR/>, "Suriname"],
	"af_ss": [<IconSS/>, "South Sudan"],
	"af_st": [<IconST/>, "Sao Tome"/* and Principe*/],
	"la_sv": [<IconSV/>, "El Salvador"],
	"la_sx": [<IconSX/>, "Sint Maarten"/* (Dutch part)*/],
	"wa_sy": [<IconSY/>, "Syria"/* Syrian Arab Republic*/],
	"af_sz": [<IconSZ/>, "Eswatini"],
	"la_tc": [<IconTC/>, "Turks Isl."/* and Caicos Islands*/],
	"af_td": [<IconTD/>, "Chad"],
	"af_tg": [<IconTG/>, "Togo"],
	"ea_th": [<IconTH/>, "Thailand"],
	"wa_tj": [<IconTJ/>, "Tajikistan"],
	"oc_tk": [<IconTK/>, "Tokelau"],
	"oc_tl": [<IconTL/>, "Timor-Leste"],
	"ea_tm": [<IconTM/>, "Turkmenistan"],
	"af_tn": [<IconTN/>, "Tunisia"],
	"oc_to": [<IconTO/>, "Tonga"],
	"eu_tr": [<IconTR/>, "Turkey"/* Türkiye */],
	"la_tt": [<IconTT/>, "Trinidad"/* and Tobago*/],
	"oc_tv": [<IconTV/>, "Tuvalu"],
	"ea_tw": [<IconTW/>, "Taiwan"/*, Province of China*/],
	"af_tz": [<IconTZ/>, "Tanzania"/*, United Republic of*/],
	"eu_ua": [<IconUA/>, "Ukraine"],
	"af_ug": [<IconUG/>, "Uganda"],
	"aa_us": [<IconUS/>, "United States"/* of America*/],
	"la_uy": [<IconUY/>, "Uruguay"],
	"wa_uz": [<IconUZ/>, "Uzbekistan"],
	"la_vc": [<IconVC/>, "Saint Vincent"/* and the Grenadines*/],
	"la_ve": [<IconVE/>, "Venezuela"/* (Bolivarian Republic of)*/],
	"aa_vg": [<IconVG/>, "Virgin Isl. (GB)"/* Virgin Islands (British)*/],
	"aa_vi": [<IconVI/>, "Virgin Isl. (US)"/* Virgin Islands (U.S.)*/],
	"ea_vn": [<IconVN/>, "Viet Nam"],
	"oc_vu": [<IconVU/>, "Vanuatu"],
	"oc_ws": [<IconWS/>, "Samoa"],
	"wa_ye": [<IconYE/>, "Yemen"],
	"af_za": [<IconZA/>, "South Africa"],
	"af_zm": [<IconZM/>, "Zambia"],
	"af_zw": [<IconZW/>, "Zimbabwe"]
}


/*
 ===========================================================
 REGIONS
 ===========================================================
*/
export const MAP_REGIONS = {// -> Includes 'linked' of 'proxy' group
	// "map_aa": [<IconRegionAA/>, "Anglo America"],
	"map_aa": [<IconRegionAA/>, "North America"],// Anglo America
	"map_af": [<IconRegionAF/>, "Africa"],
	"map_ea": [<IconRegionEA/>, "East Asia"],
	"map_eu": [<IconRegionEU/>, "Europe"],
	"map_la": [<IconRegionLA/>, "Latin America"],
	"map_oc": [<IconRegionOC/>, "Oceania"],
	"map_wa": [<IconRegionWA/>, "Middle East"],// West/ern Asia
	"map_an": [<IconRegionAN/>, "Antarctica"]
}

/*
 ===========================================================
 CATEGORIES (iab, tv, games, etc.)
 ===========================================================
*/
// import {ReactComponent as IconUndefined} from "assets/flags/undefined.svg"
export const MAP_CATEGORIES = {
    // "action": [<IconUndefined/>, "Action"],
    // "bike": [<IconUndefined/>, "Bike"],
    // "casual": [<IconUndefined/>, "Casual"],
    // "comedies": [<IconUndefined/>, "Comedies"],
    // "documentaries": [<IconUndefined/>, "Documentaries"],
    // "drama": [<IconUndefined/>, "Drama"],
    // "horror": [<IconUndefined/>, "Horror"],
    // "minecraft": [<IconUndefined/>, "Minecraft"],
    // "puzzle": [<IconUndefined/>, "Puzzle"],
    // "romance": [<IconUndefined/>, "Romance"],
    // "sci-fi": [<IconUndefined/>, "Sci-Fi"],
    // "shooter": [<IconUndefined/>, "Shooter"],
    // "simulator": [<IconUndefined/>, "Simulator"],
    // "single_player": [<IconUndefined/>, "Single player"],
    
		// "casual_&_arcade": [<IconUndefined/>, "Casual & Arcade"],
    // "role-playing_&_story-based": [<IconUndefined/>, "Role-Playing & Story-Based"],
    // "stylized_&_thematic": [<IconUndefined/>, "Stylized & Thematic"],
    // "simulation_&_strategy": [<IconUndefined/>, "Simulation & Strategy"],
    // "other_categories": [<IconUndefined/>, "Other categories"],
    // "action_&_adventure": [<IconUndefined/>, "Action & Adventure"],
    // "racing": [<IconUndefined/>, "Racing"],
    // "puzzle_&_board": [<IconUndefined/>, "Puzzle & Board"],
    // "sports": [<IconUndefined/>, "Sports"],
    // "multiplayer_&_social": [<IconUndefined/>, "Multiplayer & Social"],
    // "shooter_&_combat": [<IconUndefined/>, "Shooter & Combat"],
    // "educational_&_lifestyle": [<IconUndefined/>, "Educational & Lifestyle"],

		// "all_categories": [<IconUndefined/>, "*"],/* -> ALT: "All categories" */
		// "undefined": [<IconUndefined/>, "Undefined"],

		    
	"ca_ca": [<IconUnknown/>, "Casual & Arcade"],
	"ca_rs": [<IconUnknown/>, "Role-Playing & Story-Based"],
	"ca_st": [<IconUnknown/>, "Stylized & Thematic"],
	// "ca_ss": [<IconUnknown/>, "Simulation & Strategy"],
	"ca_ss": [<IconUnknown/>, "Simulation & Social"],
	"ca_oc": [<IconUnknown/>, "Other categories"],
	"ca_aa": [<IconUnknown/>, "Action & Adventure"],
	"ca_r": [<IconUnknown/>, "Racing"],
	"ca_pb": [<IconUnknown/>, "Puzzle & Board"],
	"ca_s": [<IconUnknown/>, "Sports"],
	"ca_ms": [<IconUnknown/>, "Multiplayer & Social"],
	"ca_sc": [<IconUnknown/>, "Shooter & Combat"],
	"ca_el": [<IconUnknown/>, "Educational & Lifestyle"],
	// "ca_hc": [<IconUnknown/>, "Health & Care"],
	// "ca_i": [<IconUnknown/>, "Interactive"],
	"ca_hc": [<IconUnknown/>, "Hyper casual"],
	"ca_i": [<IconUnknown/>, "Idle"],

	"ca_all": [<IconUnknown/>, "*"],/* -> ALT: "All categories" */
	"ca_none": [<IconUnknown/>, "Undefined"]

}

/*
 ===========================================================
 DEMOGRAPHICS (age, gender, etc.)
 ===========================================================
*/
// import {ReactComponent as IconUndefined} from "assets/flags/undefined.svg"
export const MAP_DEMOGRAPHICS_AGE = {
	"18-24": [<IconUndefined/>, "18to24"],
	"25-34": [<IconUndefined/>, "25to34"],
	"35-44": [<IconUndefined/>, "35to44"],
	"45-54": [<IconUndefined/>, "45to54"],
	"55-64": [<IconUndefined/>, "55to64"],
	"65+": [<IconUndefined/>, "65plus"],

	"all_ages": [<IconUndefined/>, "*"],/* -> ALT: "All ages" */
	"undefined": [<IconUndefined/>, "Undefined"]
}
export const MAP_DEMOGRAPHICS_GENDER = {
	"any": [<IconUndefined/>, "Any"],
	"male": [<IconUndefined/>, "Male"],
	"female": [<IconUndefined/>, "Female"],
	"other": [<IconUndefined/>, "Other"],

	"all_genders": [<IconUndefined/>, "*"],/* -> ALT: "All genders" */
	"undefined": [<IconUndefined/>, "Undefined"]
}

/*
 ===========================================================
 PERSONAS
 ===========================================================
*/
export const MAP_PERSONAS = {
	"all_audiences": [PicPersonaF45c, "All audiences"],
	"f15c": [PicPersonaF15c, "Jessica Miller"],
	"f20c": [PicPersonaF20c, "Emily Johnson"],
	"f25c": [PicPersonaF25c, "Sarah Brown"],
	"f30c": [PicPersonaF30c, "Jennifer Taylor"],
	"f35c": [PicPersonaF35c, "Ashley Doe"],
	"f40c": [PicPersonaF40c, "Jane Wilson"],
	"f45c": [PicPersonaF45c, "Lisa Thompson"],
	"f50c": [PicPersonaF50c, "Linda White"],
	"f55c": [PicPersonaF55c, "Carol Lewis"],
	"f60c": [PicPersonaF60c, "Ruth Walker"],
	"f65c": [PicPersonaF65c, "Sharon Hall"],
	"f70c": [PicPersonaF70c, "Patricia Anderson"],
	"f75c": [PicPersonaF75c, "Susan Harris"],
	"f80c": [PicPersonaF80c, "Betty Clark"],
	"m15c": [PicPersonaM15c, "Michael Green"],
	"m20c": [PicPersonaM20c, "David Jones"],
	"m25c": [PicPersonaM25c, "James Smith"],
	"m30c": [PicPersonaM30c, "Robert Davis"],
	"m35c": [PicPersonaM35c, "William Moore"],
	"m40c": [PicPersonaM40c, "Richard Taylor"],
	"m45c": [PicPersonaM45c, "Charles Thomas"],
	"m50c": [PicPersonaM50c, "Joseph Jackson"],
	"m55c": [PicPersonaM55c, "Christopher Harris"],
	"m60c": [PicPersonaM60c, "Daniel Martin"],
	"m65c": [PicPersonaM65c, "Paul Thompson"],
	"m70c": [PicPersonaM70c, "Mark Anderson"],
	"m75c": [PicPersonaM75c, "Donald Lewis"],
	"m80c": [PicPersonaM80c, "Jason Scott"]
}

/*
 ===========================================================
 GLOBAL INDEX
 ===========================================================
*/

export const MAP_ALL = {

	// Locations
	"eu_ad": {type: "location", icon: <IconAD/>, name: "Andorra"},
	"wa_ae": {type: "location", icon: <IconAE/>, name: "Emirates"/* United Arab Emirates*/},
	"wa_af": {type: "location", icon: <IconAF/>, name: "Afghanistan"},
	"la_ag": {type: "location", icon: <IconAG/>, name: "Antigua"/* and Barbuda*/},
	"la_ai": {type: "location", icon: <IconAI/>, name: "Anguilla"},
	"eu_al": {type: "location", icon: <IconAL/>, name: "Albania"},
	"wa_am": {type: "location", icon: <IconAM/>, name: "Armenia"},
	"af_ao": {type: "location", icon: <IconAO/>, name: "Angola"},
	"la_ar": {type: "location", icon: <IconAR/>, name: "Argentina"},
	"oc_as": {type: "location", icon: <IconAS/>, name: "American Samoa"},
	"eu_at": {type: "location", icon: <IconAT/>, name: "Austria"},
	"oc_au": {type: "location", icon: <IconAU/>, name: "Australia"},
	"la_aw": {type: "location", icon: <IconAW/>, name: "Aruba"},
	"eu_ax": {type: "location", icon: <IconAX/>, name: "Aland Islands"/* Åland Islands */},
	"wa_az": {type: "location", icon: <IconAZ/>, name: "Azerbaijan"},
	"eu_ba": {type: "location", icon: <IconBA/>, name: "Bosnia"/* and Herzegovina*/},
	"la_bb": {type: "location", icon: <IconBB/>, name: "Barbados"},
	"ea_bd": {type: "location", icon: <IconBD/>, name: "Bangladesh"},
	"eu_be": {type: "location", icon: <IconBE/>, name: "Belgium"},
	"af_bf": {type: "location", icon: <IconBF/>, name: "Burkina Faso"},
	"eu_bg": {type: "location", icon: <IconBG/>, name: "Bulgaria"},
	"wa_bh": {type: "location", icon: <IconBH/>, name: "Bahrain"},
	"af_bi": {type: "location", icon: <IconBI/>, name: "Burundi"},
	"af_bj": {type: "location", icon: <IconBJ/>, name: "Benin"},
	"la_bl": {type: "location", icon: <IconBL/>, name: "St. Barthélemy"/* Saint Barthélemy*/},
	"aa_bm": {type: "location", icon: <IconBM/>, name: "Bermuda"},
	"oc_bn": {type: "location", icon: <IconBN/>, name: "Brunei"/* Brunei Darussalam */},
	"la_bo": {type: "location", icon: <IconBO/>, name: "Bolivia"/* (Plurinational State of)*/},
	"la_bq": {type: "location", icon: <IconBQ/>, name: "Bonaire"/*, Sint Eustatius and Saba*/},
	"la_br": {type: "location", icon: <IconBR/>, name: "Brazil"},
	"la_bs": {type: "location", icon: <IconBS/>, name: "Bahamas"},
	"ea_bt": {type: "location", icon: <IconBT/>, name: "Bhutan"},
	"af_bw": {type: "location", icon: <IconBW/>, name: "Botswana"},
	"eu_by": {type: "location", icon: <IconBY/>, name: "Belarus"},
	"la_bz": {type: "location", icon: <IconBZ/>, name: "Belize"},
	"aa_ca": {type: "location", icon: <IconCA/>, name: "Canada"},
	"ea_cc": {type: "location", icon: <IconCC/>, name: "Cocos Isl."/* (Keeling) Islands*/},
	"af_cd": {type: "location", icon: <IconCD/>, name: "Congo"/*, Democratic Republic of the*/},
	"af_cf": {type: "location", icon: <IconCF/>, name: "C. African Rep."/* Central African Republic*/},
	"af_cg": {type: "location", icon: <IconCG/>, name: "Congo"},
	"eu_ch": {type: "location", icon: <IconCH/>, name: "Switzerland"},
	"oc_ck": {type: "location", icon: <IconCK/>, name: "Cook Isl."/* Cook Islands*/},
	"la_cl": {type: "location", icon: <IconCL/>, name: "Chile"},
	"af_cm": {type: "location", icon: <IconCM/>, name: "Cameroon"},
	"ea_cn": {type: "location", icon: <IconCN/>, name: "China"},
	"la_co": {type: "location", icon: <IconCO/>, name: "Colombia"},
	"la_cr": {type: "location", icon: <IconCR/>, name: "Costa Rica"},
	"la_cu": {type: "location", icon: <IconCU/>, name: "Cuba"},
	"la_cw": {type: "location", icon: <IconCW/>, name: "Curaçao"},
	"oc_cx": {type: "location", icon: <IconCX/>, name: "Christmas Isl."/* Christmas Island*/},
	"eu_cy": {type: "location", icon: <IconCY/>, name: "Cyprus"},
	"eu_cz": {type: "location", icon: <IconCZ/>, name: "Czechia"},
	"eu_de": {type: "location", icon: <IconDE/>, name: "Germany"},
	"af_dj": {type: "location", icon: <IconDJ/>, name: "Djibouti"},
	"eu_dk": {type: "location", icon: <IconDK/>, name: "Denmark"},
	"la_dm": {type: "location", icon: <IconDM/>, name: "Dominica"},
	"la_do": {type: "location", icon: <IconDO/>, name: "Dominican Rep."/* Dominican Republic*/},
	"af_dz": {type: "location", icon: <IconDZ/>, name: "Algeria"},
	"la_ec": {type: "location", icon: <IconEC/>, name: "Ecuador"},
	"eu_ee": {type: "location", icon: <IconEE/>, name: "Estonia"},
	"wa_eg": {type: "location", icon: <IconEG/>, name: "Egypt"},
	"af_eh": {type: "location", icon: <IconEH/>, name: "Western Sahara"},
	"af_er": {type: "location", icon: <IconER/>, name: "Eritrea"},
	"eu_es": {type: "location", icon: <IconES/>, name: "Spain"},
	"af_et": {type: "location", icon: <IconET/>, name: "Ethiopia"},
	"eu_fi": {type: "location", icon: <IconFI/>, name: "Finland"},
	"oc_fj": {type: "location", icon: <IconFJ/>, name: "Fiji"},
	"la_fk": {type: "location", icon: <IconFK/>, name: "Malvinas"/*Falkland Islands (Malvinas)*/},
	"oc_fm": {type: "location", icon: <IconFM/>, name: "Micronesia"/* (Federated States of)*/},
	"eu_fo": {type: "location", icon: <IconFO/>, name: "Faroe Islands"},
	"eu_fr": {type: "location", icon: <IconFR/>, name: "France"},
	"af_ga": {type: "location", icon: <IconGA/>, name: "Gabon"},
	"eu_gb": {type: "location", icon: <IconGB/>, name: "United Kingdom"/* of Great Britain and Northern Ireland*/},
	"la_gd": {type: "location", icon: <IconGD/>, name: "Grenada"},
	"wa_ge": {type: "location", icon: <IconGE/>, name: "Georgia"},
	"eu_gg": {type: "location", icon: <IconGG/>, name: "Guernsey"},
	"af_gh": {type: "location", icon: <IconGH/>, name: "Ghana"},
	"eu_gi": {type: "location", icon: <IconGI/>, name: "Gibraltar"},
	"eu_gl": {type: "location", icon: <IconGL/>, name: "Greenland"},
	"af_gm": {type: "location", icon: <IconGM/>, name: "Gambia"},
	"af_gn": {type: "location", icon: <IconGN/>, name: "Guinea"},
	"af_gq": {type: "location", icon: <IconGQ/>, name: "Eq. Guinea"/* Equatorial Guinea*/},
	"eu_gr": {type: "location", icon: <IconGR/>, name: "Greece"},
	"la_gt": {type: "location", icon: <IconGT/>, name: "Guatemala"},
	"aa_gu": {type: "location", icon: <IconGU/>, name: "Guam"},
	"af_gw": {type: "location", icon: <IconGW/>, name: "Guinea-Bissau"},
	"la_gy": {type: "location", icon: <IconGY/>, name: "Guyana"},
	"ea_hk": {type: "location", icon: <IconHK/>, name: "Hong Kong"},
	"la_hn": {type: "location", icon: <IconHN/>, name: "Honduras"},
	"eu_hr": {type: "location", icon: <IconHR/>, name: "Croatia"},
	"la_ht": {type: "location", icon: <IconHT/>, name: "Haiti"},
	"eu_hu": {type: "location", icon: <IconHU/>, name: "Hungary"},
	"ea_id": {type: "location", icon: <IconID/>, name: "Indonesia"},
	"eu_ie": {type: "location", icon: <IconIE/>, name: "Ireland"},
	"wa_il": {type: "location", icon: <IconIL/>, name: "Israel"},
	"eu_im": {type: "location", icon: <IconIM/>, name: "Isle of Man"},
	"ea_in": {type: "location", icon: <IconIN/>, name: "India"},
	"eu_io": {type: "location", icon: <IconIO/>, name: "Brit. Indian"/* British Indian Ocean Territory*/},
	"wa_iq": {type: "location", icon: <IconIQ/>, name: "Iraq"},
	"wa_ir": {type: "location", icon: <IconIR/>, name: "Iran"/* (Islamic Republic of)*/},
	"eu_is": {type: "location", icon: <IconIS/>, name: "Iceland"},
	"eu_it": {type: "location", icon: <IconIT/>, name: "Italy"},
	"eu_je": {type: "location", icon: <IconJE/>, name: "Jersey"},
	"la_jm": {type: "location", icon: <IconJM/>, name: "Jamaica"},
	"wa_jo": {type: "location", icon: <IconJO/>, name: "Jordan"},
	"ea_jp": {type: "location", icon: <IconJP/>, name: "Japan"},
	"af_ke": {type: "location", icon: <IconKE/>, name: "Kenya"},
	"wa_kg": {type: "location", icon: <IconKG/>, name: "Kyrgyzstan"},
	"ea_kh": {type: "location", icon: <IconKH/>, name: "Cambodia"},
	"oc_ki": {type: "location", icon: <IconKI/>, name: "Kiribati"},
	"af_km": {type: "location", icon: <IconKM/>, name: "Comoros"},
	"la_kn": {type: "location", icon: <IconKN/>, name: "Saint Kitts"/* and Nevis*/},
	"ea_kp": {type: "location", icon: <IconKP/>, name: "North Korea"/* Korea (Democratic People's Republic of)*/},
	"ea_kr": {type: "location", icon: <IconKR/>, name: "South Korea"/* Korea, Republic of*/},
	"wa_kw": {type: "location", icon: <IconKW/>, name: "Kuwait"},
	"aa_ky": {type: "location", icon: <IconKY/>, name: "Cayman Isl."/* Cayman Islands*/},
	"ea_kz": {type: "location", icon: <IconKZ/>, name: "Kazakhstan"},
	"ea_la": {type: "location", icon: <IconLA/>, name: "Lao"/* People's Democratic Republic*/},
	"wa_lb": {type: "location", icon: <IconLB/>, name: "Lebanon"},
	"la_lc": {type: "location", icon: <IconLC/>, name: "Saint Lucia"},
	"eu_li": {type: "location", icon: <IconLI/>, name: "Liechtenstein"},
	"af_lk": {type: "location", icon: <IconLK/>, name: "Sri Lanka"},
	"wa_lr": {type: "location", icon: <IconLR/>, name: "Liberia"},
	"af_ls": {type: "location", icon: <IconLS/>, name: "Lesotho"},
	"eu_lt": {type: "location", icon: <IconLT/>, name: "Lithuania"},
	"eu_lu": {type: "location", icon: <IconLU/>, name: "Luxembourg"},
	"eu_lv": {type: "location", icon: <IconLV/>, name: "Latvia"},
	"wa_ly": {type: "location", icon: <IconLY/>, name: "Libya"},
	"eu_ma": {type: "location", icon: <IconMA/>, name: "Morocco"},
	"eu_mc": {type: "location", icon: <IconMC/>, name: "Monaco"},
	"eu_md": {type: "location", icon: <IconMD/>, name: "Moldova"/*, Republic of*/},
	"eu_me": {type: "location", icon: <IconME/>, name: "Montenegro"},
	"af_mg": {type: "location", icon: <IconMG/>, name: "Madagascar"},
	"oc_mh": {type: "location", icon: <IconMH/>, name: "Marshall Isl."/* Marshall Islands*/},
	"eu_mk": {type: "location", icon: <IconMK/>, name: "N. Macedonia"/* North Macedonia*/},
	"af_ml": {type: "location", icon: <IconML/>, name: "Mali"},
	"ea_mm": {type: "location", icon: <IconMM/>, name: "Myanmar"},
	"ea_mn": {type: "location", icon: <IconMN/>, name: "Mongolia"},
	"ea_mo": {type: "location", icon: <IconMO/>, name: "Macao"},
	"ea_mp": {type: "location", icon: <IconMP/>, name: "Mariana Isl."/* Northern Mariana Islands*/},
	"la_mq": {type: "location", icon: <IconMQ/>, name: "Martinique"},
	"af_mr": {type: "location", icon: <IconMR/>, name: "Mauritania"},
	"aa_ms": {type: "location", icon: <IconMS/>, name: "Montserrat"},
	"eu_mt": {type: "location", icon: <IconMT/>, name: "Malta"},
	"af_mu": {type: "location", icon: <IconMU/>, name: "Mauritius"},
	"ea_mv": {type: "location", icon: <IconMV/>, name: "Maldives"},
	"af_mw": {type: "location", icon: <IconMW/>, name: "Malawi"},
	"la_mx": {type: "location", icon: <IconMX/>, name: "Mexico"},
	"ea_my": {type: "location", icon: <IconMY/>, name: "Malaysia"},
	"af_mz": {type: "location", icon: <IconMZ/>, name: "Mozambique"},
	"af_na": {type: "location", icon: <IconNA/>, name: "Namibia"},
	"af_ne": {type: "location", icon: <IconNE/>, name: "Niger"},
	"oc_nf": {type: "location", icon: <IconNF/>, name: "Norfolk Isl."},
	"af_ng": {type: "location", icon: <IconNG/>, name: "Nigeria"},
	"la_ni": {type: "location", icon: <IconNI/>, name: "Nicaragua"},
	"eu_nl": {type: "location", icon: <IconNL/>, name: "Netherlands"/*, Kingdom of the*/},
	"eu_no": {type: "location", icon: <IconNO/>, name: "Norway"},
	"ea_np": {type: "location", icon: <IconNP/>, name: "Nepal"},
	"oc_nr": {type: "location", icon: <IconNR/>, name: "Nauru"},
	"oc_nu": {type: "location", icon: <IconNU/>, name: "Niue"},
	"oc_nz": {type: "location", icon: <IconNZ/>, name: "New Zealand"},
	"wa_om": {type: "location", icon: <IconOM/>, name: "Oman"},
	"la_pa": {type: "location", icon: <IconPA/>, name: "Panama"},
	"la_pe": {type: "location", icon: <IconPE/>, name: "Peru"},
	"oc_pf": {type: "location", icon: <IconPF/>, name: "Polynesia"/* French Polynesia*/},
	"oc_pg": {type: "location", icon: <IconPG/>, name: "New Guinea"/* Papua New Guinea*/},
	"ea_ph": {type: "location", icon: <IconPH/>, name: "Philippines"},
	"wa_pk": {type: "location", icon: <IconPK/>, name: "Pakistan"},
	"eu_pl": {type: "location", icon: <IconPL/>, name: "Poland"},
	"la_pn": {type: "location", icon: <IconPN/>, name: "Pitcairn"},
	"la_pr": {type: "location", icon: <IconPR/>, name: "Puerto Rico"},
	"wa_ps": {type: "location", icon: <IconPS/>, name: "Palestine"/*, State of*/},
	"eu_pt": {type: "location", icon: <IconPT/>, name: "Portugal"},
	"ea_pw": {type: "location", icon: <IconPW/>, name: "Palau"},
	"la_py": {type: "location", icon: <IconPY/>, name: "Paraguay"},
	"wa_qa": {type: "location", icon: <IconQA/>, name: "Qatar"},
	"eu_ro": {type: "location", icon: <IconRO/>, name: "Romania"},
	"eu_rs": {type: "location", icon: <IconRS/>, name: "Serbia"},
	"ea_ru": {type: "location", icon: <IconRU/>, name: "Russia"/* Russian Federation*/},
	"af_rw": {type: "location", icon: <IconRW/>, name: "Rwanda"},
	"wa_sa": {type: "location", icon: <IconSA/>, name: "Saudi Arabia"},
	"oc_sb": {type: "location", icon: <IconSB/>, name: "Solomon Isl."/* Solomon Islands*/},
	"af_sc": {type: "location", icon: <IconSC/>, name: "Seychelles"},
	"af_sd": {type: "location", icon: <IconSD/>, name: "Sudan"/* The Republic of Sudan | North Sudan*/},
	"eu_se": {type: "location", icon: <IconSE/>, name: "Sweden"},
	"ea_sg": {type: "location", icon: <IconSG/>, name: "Singapore"},
	"eu_si": {type: "location", icon: <IconSI/>, name: "Slovenia"},
	"eu_sk": {type: "location", icon: <IconSK/>, name: "Slovakia"},
	"af_sl": {type: "location", icon: <IconSL/>, name: "Sierra Leone"},
	"eu_sm": {type: "location", icon: <IconSM/>, name: "San Marino"},
	"af_sn": {type: "location", icon: <IconSN/>, name: "Senegal"},
	"af_so": {type: "location", icon: <IconSO/>, name: "Somalia"},
	"la_sr": {type: "location", icon: <IconSR/>, name: "Suriname"},
	"af_ss": {type: "location", icon: <IconSS/>, name: "South Sudan"},
	"af_st": {type: "location", icon: <IconST/>, name: "Sao Tome"/* and Principe*/},
	"la_sv": {type: "location", icon: <IconSV/>, name: "El Salvador"},
	"la_sx": {type: "location", icon: <IconSX/>, name: "Sint Maarten"/* (Dutch part)*/},
	"wa_sy": {type: "location", icon: <IconSY/>, name: "Syria"/* Syrian Arab Republic*/},
	"af_sz": {type: "location", icon: <IconSZ/>, name: "Eswatini"},
	"la_tc": {type: "location", icon: <IconTC/>, name: "Turks Isl."/* and Caicos Islands*/},
	"af_td": {type: "location", icon: <IconTD/>, name: "Chad"},
	"af_tg": {type: "location", icon: <IconTG/>, name: "Togo"},
	"ea_th": {type: "location", icon: <IconTH/>, name: "Thailand"},
	"wa_tj": {type: "location", icon: <IconTJ/>, name: "Tajikistan"},
	"oc_tk": {type: "location", icon: <IconTK/>, name: "Tokelau"},
	"oc_tl": {type: "location", icon: <IconTL/>, name: "Timor-Leste"},
	"ea_tm": {type: "location", icon: <IconTM/>, name: "Turkmenistan"},
	"af_tn": {type: "location", icon: <IconTN/>, name: "Tunisia"},
	"oc_to": {type: "location", icon: <IconTO/>, name: "Tonga"},
	"eu_tr": {type: "location", icon: <IconTR/>, name: "Turkey"/* Türkiye */},
	"la_tt": {type: "location", icon: <IconTT/>, name: "Trinidad"/* and Tobago*/},
	"oc_tv": {type: "location", icon: <IconTV/>, name: "Tuvalu"},
	"ea_tw": {type: "location", icon: <IconTW/>, name: "Taiwan"/*, Province of China*/},
	"af_tz": {type: "location", icon: <IconTZ/>, name: "Tanzania"/*, United Republic of*/},
	"eu_ua": {type: "location", icon: <IconUA/>, name: "Ukraine"},
	"af_ug": {type: "location", icon: <IconUG/>, name: "Uganda"},
	"aa_us": {type: "location", icon: <IconUS/>, name: "United States"/* of America*/},
	"la_uy": {type: "location", icon: <IconUY/>, name: "Uruguay"},
	"wa_uz": {type: "location", icon: <IconUZ/>, name: "Uzbekistan"},
	"la_vc": {type: "location", icon: <IconVC/>, name: "Saint Vincent"/* and the Grenadines*/},
	"la_ve": {type: "location", icon: <IconVE/>, name: "Venezuela"/* (Bolivarian Republic of)*/},
	"aa_vg": {type: "location", icon: <IconVG/>, name: "Virgin Isl. (GB)"/* Virgin Islands (British)*/},
	"aa_vi": {type: "location", icon: <IconVI/>, name: "Virgin Isl. (US)"/* Virgin Islands (U.S.)*/},
	"ea_vn": {type: "location", icon: <IconVN/>, name: "Viet Nam"},
	"oc_vu": {type: "location", icon: <IconVU/>, name: "Vanuatu"},
	"oc_ws": {type: "location", icon: <IconWS/>, name: "Samoa"},
	"wa_ye": {type: "location", icon: <IconYE/>, name: "Yemen"},
	"af_za": {type: "location", icon: <IconZA/>, name: "South Africa"},
	"af_zm": {type: "location", icon: <IconZM/>, name: "Zambia"},
	"af_zw": {type: "location", icon: <IconZW/>, name: "Zimbabwe"},
	"all_l": {type: "location", icon: <IconUN/>, name: "World"},// ALT: "un"

	// Personas
	"f15_c": {type: "persona", icon: PicPersonaF15c, name: "Jessica Miller"},
	"f20_c": {type: "persona", icon: PicPersonaF20c, name: "Emily Johnson"},
	"f25_c": {type: "persona", icon: PicPersonaF25c, name: "Sarah Brown"},
	"f30_c": {type: "persona", icon: PicPersonaF30c, name: "Jennifer Taylor"},
	"f35_c": {type: "persona", icon: PicPersonaF35c, name: "Ashley Doe"},
	"f40_c": {type: "persona", icon: PicPersonaF40c, name: "Jane Wilson"},
	"f45_c": {type: "persona", icon: PicPersonaF45c, name: "Lisa Thompson"},
	"f50_c": {type: "persona", icon: PicPersonaF50c, name: "Linda White"},
	"f55_c": {type: "persona", icon: PicPersonaF55c, name: "Carol Lewis"},
	"f60_c": {type: "persona", icon: PicPersonaF60c, name: "Ruth Walker"},
	"f65_c": {type: "persona", icon: PicPersonaF65c, name: "Sharon Hall"},
	"f70_c": {type: "persona", icon: PicPersonaF70c, name: "Patricia Anderson"},
	"f75_c": {type: "persona", icon: PicPersonaF75c, name: "Susan Harris"},
	"f80_c": {type: "persona", icon: PicPersonaF80c, name: "Betty Clark"},
	"m15_c": {type: "persona", icon: PicPersonaM15c, name: "Michael Green"},
	"m20_c": {type: "persona", icon: PicPersonaM20c, name: "David Jones"},
	"m25_c": {type: "persona", icon: PicPersonaM25c, name: "James Smith"},
	"m30_c": {type: "persona", icon: PicPersonaM30c, name: "Robert Davis"},
	"m35_c": {type: "persona", icon: PicPersonaM35c, name: "William Moore"},
	"m40_c": {type: "persona", icon: PicPersonaM40c, name: "Richard Taylor"},
	"m45_c": {type: "persona", icon: PicPersonaM45c, name: "Charles Thomas"},
	"m50_c": {type: "persona", icon: PicPersonaM50c, name: "Joseph Jackson"},
	"m55_c": {type: "persona", icon: PicPersonaM55c, name: "Christopher Harris"},
	"m60_c": {type: "persona", icon: PicPersonaM60c, name: "Daniel Martin"},
	"m65_c": {type: "persona", icon: PicPersonaM65c, name: "Paul Thompson"},
	"m70_c": {type: "persona", icon: PicPersonaM70c, name: "Mark Anderson"},
	"m75_c": {type: "persona", icon: PicPersonaM75c, name: "Donald Lewis"},
	"m80_c": {type: "persona", icon: PicPersonaM80c, name: "Jason Scott"},
	"all_p": {type: "persona", icon: PicPersonaF45c, name: "All personas"},

	// Shared
	"undef": {type: "undefined", icon: <IconUndefined/>, name: "Undefined"},


	// !!!! LEGACY (TEMP DEL) -------------------------------------------------------
	// Locations (legacy)
	"ad": {type: "location", icon: <IconAD/>, name: "Andorra"},
	"ae": {type: "location", icon: <IconAE/>, name: "Emirates"/* United Arab Emirates*/},
	"af": {type: "location", icon: <IconAF/>, name: "Afghanistan"},
	"ag": {type: "location", icon: <IconAG/>, name: "Antigua"/* and Barbuda*/},
	"ai": {type: "location", icon: <IconAI/>, name: "Anguilla"},
	"al": {type: "location", icon: <IconAL/>, name: "Albania"},
	"am": {type: "location", icon: <IconAM/>, name: "Armenia"},
	"ao": {type: "location", icon: <IconAO/>, name: "Angola"},
	"ar": {type: "location", icon: <IconAR/>, name: "Argentina"},
	"as": {type: "location", icon: <IconAS/>, name: "American Samoa"},
	"at": {type: "location", icon: <IconAT/>, name: "Austria"},
	"au": {type: "location", icon: <IconAU/>, name: "Australia"},
	"aw": {type: "location", icon: <IconAW/>, name: "Aruba"},
	"ax": {type: "location", icon: <IconAX/>, name: "Aland Islands"/* Åland Islands */},
	"az": {type: "location", icon: <IconAZ/>, name: "Azerbaijan"},
	"ba": {type: "location", icon: <IconBA/>, name: "Bosnia"/* and Herzegovina*/},
	"bb": {type: "location", icon: <IconBB/>, name: "Barbados"},
	"bd": {type: "location", icon: <IconBD/>, name: "Bangladesh"},
	"be": {type: "location", icon: <IconBE/>, name: "Belgium"},
	"bf": {type: "location", icon: <IconBF/>, name: "Burkina Faso"},
	"bg": {type: "location", icon: <IconBG/>, name: "Bulgaria"},
	"bh": {type: "location", icon: <IconBH/>, name: "Bahrain"},
	"bi": {type: "location", icon: <IconBI/>, name: "Burundi"},
	"bj": {type: "location", icon: <IconBJ/>, name: "Benin"},
	"bl": {type: "location", icon: <IconBL/>, name: "St. Barthélemy"/* Saint Barthélemy*/},
	"bm": {type: "location", icon: <IconBM/>, name: "Bermuda"},
	"bn": {type: "location", icon: <IconBN/>, name: "Brunei"/* Brunei Darussalam */},
	"bo": {type: "location", icon: <IconBO/>, name: "Bolivia"/* (Plurinational State of)*/},
	"bq": {type: "location", icon: <IconBQ/>, name: "Bonaire"/*, Sint Eustatius and Saba*/},
	"br": {type: "location", icon: <IconBR/>, name: "Brazil"},
	"bs": {type: "location", icon: <IconBS/>, name: "Bahamas"},
	"bt": {type: "location", icon: <IconBT/>, name: "Bhutan"},
	"bw": {type: "location", icon: <IconBW/>, name: "Botswana"},
	"by": {type: "location", icon: <IconBY/>, name: "Belarus"},
	"bz": {type: "location", icon: <IconBZ/>, name: "Belize"},
	"ca": {type: "location", icon: <IconCA/>, name: "Canada"},
	"cc": {type: "location", icon: <IconCC/>, name: "Cocos Isl."/* (Keeling) Islands*/},
	"cd": {type: "location", icon: <IconCD/>, name: "Congo"/*, Democratic Republic of the*/},
	"cf": {type: "location", icon: <IconCF/>, name: "C. African Rep."/* Central African Republic*/},
	"cg": {type: "location", icon: <IconCG/>, name: "Congo"},
	"ch": {type: "location", icon: <IconCH/>, name: "Switzerland"},
	"ck": {type: "location", icon: <IconCK/>, name: "Cook Isl."/* Cook Islands*/},
	"cl": {type: "location", icon: <IconCL/>, name: "Chile"},
	"cm": {type: "location", icon: <IconCM/>, name: "Cameroon"},
	"cn": {type: "location", icon: <IconCN/>, name: "China"},
	"co": {type: "location", icon: <IconCO/>, name: "Colombia"},
	"cr": {type: "location", icon: <IconCR/>, name: "Costa Rica"},
	"cu": {type: "location", icon: <IconCU/>, name: "Cuba"},
	"cw": {type: "location", icon: <IconCW/>, name: "Curaçao"},
	"cx": {type: "location", icon: <IconCX/>, name: "Christmas Isl."/* Christmas Island*/},
	"cy": {type: "location", icon: <IconCY/>, name: "Cyprus"},
	"cz": {type: "location", icon: <IconCZ/>, name: "Czechia"},
	"de": {type: "location", icon: <IconDE/>, name: "Germany"},
	"dj": {type: "location", icon: <IconDJ/>, name: "Djibouti"},
	"dk": {type: "location", icon: <IconDK/>, name: "Denmark"},
	"dm": {type: "location", icon: <IconDM/>, name: "Dominica"},
	"do": {type: "location", icon: <IconDO/>, name: "Dominican Rep."/* Dominican Republic*/},
	"dz": {type: "location", icon: <IconDZ/>, name: "Algeria"},
	"ec": {type: "location", icon: <IconEC/>, name: "Ecuador"},
	"ee": {type: "location", icon: <IconEE/>, name: "Estonia"},
	"eg": {type: "location", icon: <IconEG/>, name: "Egypt"},
	"eh": {type: "location", icon: <IconEH/>, name: "Western Sahara"},
	"er": {type: "location", icon: <IconER/>, name: "Eritrea"},
	"es": {type: "location", icon: <IconES/>, name: "Spain"},
	"et": {type: "location", icon: <IconET/>, name: "Ethiopia"},
	"fi": {type: "location", icon: <IconFI/>, name: "Finland"},
	"fj": {type: "location", icon: <IconFJ/>, name: "Fiji"},
	"fk": {type: "location", icon: <IconFK/>, name: "Malvinas"/*Falkland Islands (Malvinas)*/},
	"fm": {type: "location", icon: <IconFM/>, name: "Micronesia"/* (Federated States of)*/},
	"fo": {type: "location", icon: <IconFO/>, name: "Faroe Islands"},
	"fr": {type: "location", icon: <IconFR/>, name: "France"},
	"ga": {type: "location", icon: <IconGA/>, name: "Gabon"},
	"gb": {type: "location", icon: <IconGB/>, name: "United Kingdom"/* of Great Britain and Northern Ireland*/},
	"gd": {type: "location", icon: <IconGD/>, name: "Grenada"},
	"ge": {type: "location", icon: <IconGE/>, name: "Georgia"},
	"gg": {type: "location", icon: <IconGG/>, name: "Guernsey"},
	"gh": {type: "location", icon: <IconGH/>, name: "Ghana"},
	"gi": {type: "location", icon: <IconGI/>, name: "Gibraltar"},
	"gl": {type: "location", icon: <IconGL/>, name: "Greenland"},
	"gm": {type: "location", icon: <IconGM/>, name: "Gambia"},
	"gn": {type: "location", icon: <IconGN/>, name: "Guinea"},
	"gq": {type: "location", icon: <IconGQ/>, name: "Eq. Guinea"/* Equatorial Guinea*/},
	"gr": {type: "location", icon: <IconGR/>, name: "Greece"},
	"gt": {type: "location", icon: <IconGT/>, name: "Guatemala"},
	"gu": {type: "location", icon: <IconGU/>, name: "Guam"},
	"gw": {type: "location", icon: <IconGW/>, name: "Guinea-Bissau"},
	"gy": {type: "location", icon: <IconGY/>, name: "Guyana"},
	"hk": {type: "location", icon: <IconHK/>, name: "Hong Kong"},
	"hn": {type: "location", icon: <IconHN/>, name: "Honduras"},
	"hr": {type: "location", icon: <IconHR/>, name: "Croatia"},
	"ht": {type: "location", icon: <IconHT/>, name: "Haiti"},
	"hu": {type: "location", icon: <IconHU/>, name: "Hungary"},
	"id": {type: "location", icon: <IconID/>, name: "Indonesia"},
	"ie": {type: "location", icon: <IconIE/>, name: "Ireland"},
	"il": {type: "location", icon: <IconIL/>, name: "Israel"},
	"im": {type: "location", icon: <IconIM/>, name: "Isle of Man"},
	"in": {type: "location", icon: <IconIN/>, name: "India"},
	"io": {type: "location", icon: <IconIO/>, name: "Brit. Indian"/* British Indian Ocean Territory*/},
	"iq": {type: "location", icon: <IconIQ/>, name: "Iraq"},
	"ir": {type: "location", icon: <IconIR/>, name: "Iran"/* (Islamic Republic of)*/},
	"is": {type: "location", icon: <IconIS/>, name: "Iceland"},
	"it": {type: "location", icon: <IconIT/>, name: "Italy"},
	"je": {type: "location", icon: <IconJE/>, name: "Jersey"},
	"jm": {type: "location", icon: <IconJM/>, name: "Jamaica"},
	"jo": {type: "location", icon: <IconJO/>, name: "Jordan"},
	"jp": {type: "location", icon: <IconJP/>, name: "Japan"},
	"ke": {type: "location", icon: <IconKE/>, name: "Kenya"},
	"kg": {type: "location", icon: <IconKG/>, name: "Kyrgyzstan"},
	"kh": {type: "location", icon: <IconKH/>, name: "Cambodia"},
	"ki": {type: "location", icon: <IconKI/>, name: "Kiribati"},
	"km": {type: "location", icon: <IconKM/>, name: "Comoros"},
	"kn": {type: "location", icon: <IconKN/>, name: "Saint Kitts"/* and Nevis*/},
	"kp": {type: "location", icon: <IconKP/>, name: "North Korea"/* Korea (Democratic People's Republic of)*/},
	"kr": {type: "location", icon: <IconKR/>, name: "South Korea"/* Korea, Republic of*/},
	"kw": {type: "location", icon: <IconKW/>, name: "Kuwait"},
	"ky": {type: "location", icon: <IconKY/>, name: "Cayman Isl."/* Cayman Islands*/},
	"kz": {type: "location", icon: <IconKZ/>, name: "Kazakhstan"},
	"la": {type: "location", icon: <IconLA/>, name: "Lao"/* People's Democratic Republic*/},
	"lb": {type: "location", icon: <IconLB/>, name: "Lebanon"},
	"lc": {type: "location", icon: <IconLC/>, name: "Saint Lucia"},
	"li": {type: "location", icon: <IconLI/>, name: "Liechtenstein"},
	"lk": {type: "location", icon: <IconLK/>, name: "Sri Lanka"},
	"lr": {type: "location", icon: <IconLR/>, name: "Liberia"},
	"ls": {type: "location", icon: <IconLS/>, name: "Lesotho"},
	"lt": {type: "location", icon: <IconLT/>, name: "Lithuania"},
	"lu": {type: "location", icon: <IconLU/>, name: "Luxembourg"},
	"lv": {type: "location", icon: <IconLV/>, name: "Latvia"},
	"ly": {type: "location", icon: <IconLY/>, name: "Libya"},
	"ma": {type: "location", icon: <IconMA/>, name: "Morocco"},
	"mc": {type: "location", icon: <IconMC/>, name: "Monaco"},
	"md": {type: "location", icon: <IconMD/>, name: "Moldova"/*, Republic of*/},
	"me": {type: "location", icon: <IconME/>, name: "Montenegro"},
	"mg": {type: "location", icon: <IconMG/>, name: "Madagascar"},
	"mh": {type: "location", icon: <IconMH/>, name: "Marshall Isl."/* Marshall Islands*/},
	"mk": {type: "location", icon: <IconMK/>, name: "N. Macedonia"/* North Macedonia*/},
	"ml": {type: "location", icon: <IconML/>, name: "Mali"},
	"mm": {type: "location", icon: <IconMM/>, name: "Myanmar"},
	"mn": {type: "location", icon: <IconMN/>, name: "Mongolia"},
	"mo": {type: "location", icon: <IconMO/>, name: "Macao"},
	"mp": {type: "location", icon: <IconMP/>, name: "Mariana Isl."/* Northern Mariana Islands*/},
	"mq": {type: "location", icon: <IconMQ/>, name: "Martinique"},
	"mr": {type: "location", icon: <IconMR/>, name: "Mauritania"},
	"ms": {type: "location", icon: <IconMS/>, name: "Montserrat"},
	"mt": {type: "location", icon: <IconMT/>, name: "Malta"},
	"mu": {type: "location", icon: <IconMU/>, name: "Mauritius"},
	"mv": {type: "location", icon: <IconMV/>, name: "Maldives"},
	"mw": {type: "location", icon: <IconMW/>, name: "Malawi"},
	"mx": {type: "location", icon: <IconMX/>, name: "Mexico"},
	"my": {type: "location", icon: <IconMY/>, name: "Malaysia"},
	"mz": {type: "location", icon: <IconMZ/>, name: "Mozambique"},
	"na": {type: "location", icon: <IconNA/>, name: "Namibia"},
	"ne": {type: "location", icon: <IconNE/>, name: "Niger"},
	"nf": {type: "location", icon: <IconNF/>, name: "Norfolk Isl."},
	"ng": {type: "location", icon: <IconNG/>, name: "Nigeria"},
	"ni": {type: "location", icon: <IconNI/>, name: "Nicaragua"},
	"nl": {type: "location", icon: <IconNL/>, name: "Netherlands"/*, Kingdom of the*/},
	"no": {type: "location", icon: <IconNO/>, name: "Norway"},
	"np": {type: "location", icon: <IconNP/>, name: "Nepal"},
	"nr": {type: "location", icon: <IconNR/>, name: "Nauru"},
	"nu": {type: "location", icon: <IconNU/>, name: "Niue"},
	"nz": {type: "location", icon: <IconNZ/>, name: "New Zealand"},
	"om": {type: "location", icon: <IconOM/>, name: "Oman"},
	"pa": {type: "location", icon: <IconPA/>, name: "Panama"},
	"pe": {type: "location", icon: <IconPE/>, name: "Peru"},
	"pf": {type: "location", icon: <IconPF/>, name: "Polynesia"/* French Polynesia*/},
	"pg": {type: "location", icon: <IconPG/>, name: "New Guinea"/* Papua New Guinea*/},
	"ph": {type: "location", icon: <IconPH/>, name: "Philippines"},
	"pk": {type: "location", icon: <IconPK/>, name: "Pakistan"},
	"pl": {type: "location", icon: <IconPL/>, name: "Poland"},
	"pn": {type: "location", icon: <IconPN/>, name: "Pitcairn"},
	"pr": {type: "location", icon: <IconPR/>, name: "Puerto Rico"},
	"ps": {type: "location", icon: <IconPS/>, name: "Palestine"/*, State of*/},
	"pt": {type: "location", icon: <IconPT/>, name: "Portugal"},
	"pw": {type: "location", icon: <IconPW/>, name: "Palau"},
	"py": {type: "location", icon: <IconPY/>, name: "Paraguay"},
	"qa": {type: "location", icon: <IconQA/>, name: "Qatar"},
	"ro": {type: "location", icon: <IconRO/>, name: "Romania"},
	"rs": {type: "location", icon: <IconRS/>, name: "Serbia"},
	"ru": {type: "location", icon: <IconRU/>, name: "Russia"/* Russian Federation*/},
	"rw": {type: "location", icon: <IconRW/>, name: "Rwanda"},
	"sa": {type: "location", icon: <IconSA/>, name: "Saudi Arabia"},
	"sb": {type: "location", icon: <IconSB/>, name: "Solomon Isl."/* Solomon Islands*/},
	"sc": {type: "location", icon: <IconSC/>, name: "Seychelles"},
	"sd": {type: "location", icon: <IconSD/>, name: "Sudan"/* The Republic of Sudan | North Sudan*/},
	"se": {type: "location", icon: <IconSE/>, name: "Sweden"},
	"sg": {type: "location", icon: <IconSG/>, name: "Singapore"},
	"si": {type: "location", icon: <IconSI/>, name: "Slovenia"},
	"sk": {type: "location", icon: <IconSK/>, name: "Slovakia"},
	"sl": {type: "location", icon: <IconSL/>, name: "Sierra Leone"},
	"sm": {type: "location", icon: <IconSM/>, name: "San Marino"},
	"sn": {type: "location", icon: <IconSN/>, name: "Senegal"},
	"so": {type: "location", icon: <IconSO/>, name: "Somalia"},
	"sr": {type: "location", icon: <IconSR/>, name: "Suriname"},
	"ss": {type: "location", icon: <IconSS/>, name: "South Sudan"},
	"st": {type: "location", icon: <IconST/>, name: "Sao Tome"/* and Principe*/},
	"sv": {type: "location", icon: <IconSV/>, name: "El Salvador"},
	"sx": {type: "location", icon: <IconSX/>, name: "Sint Maarten"/* (Dutch part)*/},
	"sy": {type: "location", icon: <IconSY/>, name: "Syria"/* Syrian Arab Republic*/},
	"sz": {type: "location", icon: <IconSZ/>, name: "Eswatini"},
	"tc": {type: "location", icon: <IconTC/>, name: "Turks Isl."/* and Caicos Islands*/},
	"td": {type: "location", icon: <IconTD/>, name: "Chad"},
	"tg": {type: "location", icon: <IconTG/>, name: "Togo"},
	"th": {type: "location", icon: <IconTH/>, name: "Thailand"},
	"tj": {type: "location", icon: <IconTJ/>, name: "Tajikistan"},
	"tk": {type: "location", icon: <IconTK/>, name: "Tokelau"},
	"tl": {type: "location", icon: <IconTL/>, name: "Timor-Leste"},
	"tm": {type: "location", icon: <IconTM/>, name: "Turkmenistan"},
	"tn": {type: "location", icon: <IconTN/>, name: "Tunisia"},
	"to": {type: "location", icon: <IconTO/>, name: "Tonga"},
	"tr": {type: "location", icon: <IconTR/>, name: "Turkey"/* Türkiye */},
	"tt": {type: "location", icon: <IconTT/>, name: "Trinidad"/* and Tobago*/},
	"tv": {type: "location", icon: <IconTV/>, name: "Tuvalu"},
	"tw": {type: "location", icon: <IconTW/>, name: "Taiwan"/*, Province of China*/},
	"tz": {type: "location", icon: <IconTZ/>, name: "Tanzania"/*, United Republic of*/},
	"ua": {type: "location", icon: <IconUA/>, name: "Ukraine"},
	"ug": {type: "location", icon: <IconUG/>, name: "Uganda"},
	"us": {type: "location", icon: <IconUS/>, name: "United States"/* of America*/},
	"uy": {type: "location", icon: <IconUY/>, name: "Uruguay"},
	"uz": {type: "location", icon: <IconUZ/>, name: "Uzbekistan"},
	"vc": {type: "location", icon: <IconVC/>, name: "Saint Vincent"/* and the Grenadines*/},
	"ve": {type: "location", icon: <IconVE/>, name: "Venezuela"/* (Bolivarian Republic of)*/},
	"vg": {type: "location", icon: <IconVG/>, name: "Virgin Isl. (GB)"/* Virgin Islands (British)*/},
	"vi": {type: "location", icon: <IconVI/>, name: "Virgin Isl. (US)"/* Virgin Islands (U.S.)*/},
	"vn": {type: "location", icon: <IconVN/>, name: "Viet Nam"},
	"vu": {type: "location", icon: <IconVU/>, name: "Vanuatu"},
	"ws": {type: "location", icon: <IconWS/>, name: "Samoa"},
	"ye": {type: "location", icon: <IconYE/>, name: "Yemen"},
	"za": {type: "location", icon: <IconZA/>, name: "South Africa"},
	"zm": {type: "location", icon: <IconZM/>, name: "Zambia"},
	"zw": {type: "location", icon: <IconZW/>, name: "Zimbabwe"},
	"un": {type: "location", icon: <IconUN/>, name: "World"},
	"all_locations": {type: "location", icon: <IconUN/>, name: "All locations"},
	"undefined": {type: "location", icon: <IconUndefined/>, name: "Undefined"},
	"unknown": {type: "location", icon: <IconUndefined/>, name: "Undefined"},
	"null": {type: "location", icon: <IconUndefined/>, name: "Undefined"},

	// Personas (legacy)
	"f15c": {type: "persona", icon: PicPersonaF15c, name: "Jessica Miller"},
	"f20c": {type: "persona", icon: PicPersonaF20c, name: "Emily Johnson"},
	"f25c": {type: "persona", icon: PicPersonaF25c, name: "Sarah Brown"},
	"f30c": {type: "persona", icon: PicPersonaF30c, name: "Jennifer Taylor"},
	"f35c": {type: "persona", icon: PicPersonaF35c, name: "Ashley Doe"},
	"f40c": {type: "persona", icon: PicPersonaF40c, name: "Jane Wilson"},
	"f45c": {type: "persona", icon: PicPersonaF45c, name: "Lisa Thompson"},
	"f50c": {type: "persona", icon: PicPersonaF50c, name: "Linda White"},
	"f55c": {type: "persona", icon: PicPersonaF55c, name: "Carol Lewis"},
	"f60c": {type: "persona", icon: PicPersonaF60c, name: "Ruth Walker"},
	"f65c": {type: "persona", icon: PicPersonaF65c, name: "Sharon Hall"},
	"f70c": {type: "persona", icon: PicPersonaF70c, name: "Patricia Anderson"},
	"f75c": {type: "persona", icon: PicPersonaF75c, name: "Susan Harris"},
	"f80c": {type: "persona", icon: PicPersonaF80c, name: "Betty Clark"},
	"m15c": {type: "persona", icon: PicPersonaM15c, name: "Michael Green"},
	"m20c": {type: "persona", icon: PicPersonaM20c, name: "David Jones"},
	"m25c": {type: "persona", icon: PicPersonaM25c, name: "James Smith"},
	"m30c": {type: "persona", icon: PicPersonaM30c, name: "Robert Davis"},
	"m35c": {type: "persona", icon: PicPersonaM35c, name: "William Moore"},
	"m40c": {type: "persona", icon: PicPersonaM40c, name: "Richard Taylor"},
	"m45c": {type: "persona", icon: PicPersonaM45c, name: "Charles Thomas"},
	"m50c": {type: "persona", icon: PicPersonaM50c, name: "Joseph Jackson"},
	"m55c": {type: "persona", icon: PicPersonaM55c, name: "Christopher Harris"},
	"m60c": {type: "persona", icon: PicPersonaM60c, name: "Daniel Martin"},
	"m65c": {type: "persona", icon: PicPersonaM65c, name: "Paul Thompson"},
	"m70c": {type: "persona", icon: PicPersonaM70c, name: "Mark Anderson"},
	"m75c": {type: "persona", icon: PicPersonaM75c, name: "Donald Lewis"},
	"m80c": {type: "persona", icon: PicPersonaM80c, name: "Jason Scott"},
	"all_audiences": {type: "persona", icon: PicPersonaF45c, name: "All audiences"}
	// !!!! LEGACY (TEMP DEL) -------------------------------------------------------
	
}