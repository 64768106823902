import {
  useRef,
  useState,
  useEffect
} from "react"

import {
  useFetch
} from "hooks/fetch"

import {
  BrowserRouter,
  Route,
  Routes,
  Navigate
} from "react-router-dom"

import {
  ProvideGlobals
} from "contexts"

// Views
import {
  Help,
  Settings,
  Home,
  Campaigns,
  Content,
  Catalog,
  Calculator,
  Summary
} from "views"

import {
  CampaignsAudiences,
  CampaignsLocations,
  CampaignsCategory,
  CampaignsIAB,
  CampaignsTiming,
  CampaignsDevices
} from "views/Campaigns"

import {
  ContentAudiences,
  ContentLocations,
  ContentCategory,
  ContentIAB,
  ContentTiming,
  ContentDevices,
  ContentPlatform
} from "views/Content"

// Utils
import {
  ISO8601
} from "util_v2/UtilityFunctions"

// Util
import {
  ROUTE_PREFIX,
  ROUTE_HOME,
  ROUTE_CATALOG,
  ROUTE_CAMPAIGNS,
  ROUTE_CAMPAIGNS_01,
  ROUTE_CAMPAIGNS_02,
  ROUTE_CAMPAIGNS_03,
  ROUTE_CAMPAIGNS_04,
  ROUTE_CAMPAIGNS_05,
  ROUTE_CAMPAIGNS_06,

  ROUTE_CONTENT,
  ROUTE_CONTENT_01,
  ROUTE_CONTENT_02,
  ROUTE_CONTENT_03,
  ROUTE_CONTENT_04,
  ROUTE_CONTENT_05,
  ROUTE_CONTENT_06,
  ROUTE_CONTENT_07,

  ROUTE_SUMMARY,

  ROUTE_HELP,
  ROUTE_SETTINGS,
  ROUTE_CALCULATOR,
  ROUTE_API// -> https://demo.gonucleus.io/api 
} from "util/Routes.const"

import {
  SHOW_N_ENABLE_ALL,
  ENABLE_ROUTE_HOME,

  ENABLE_ROUTE_HELP,
  ENABLE_ROUTE_SETTINGS,
  ENABLE_CATALOG,
  ENABLE_ROUTE_CALCULATOR,
  ROTANA_DEMO_MODE,

  // Campaigns
  ENABLE_ROUTE_CAMPAIGNS,
  ENABLE_ROUTE_CAMPAIGNS_OVERVIEW,
  ENABLE_ROUTE_CAMPAIGNS_LOCATION,
  ENABLE_ROUTE_CAMPAIGNS_AUDIENCE,
  ENABLE_ROUTE_CAMPAIGNS_CATEGORY,
  ENABLE_ROUTE_CAMPAIGNS_TIMING,
  ENABLE_ROUTE_CAMPAIGNS_DEVICE,
  ENABLE_ROUTE_CAMPAIGNS_IAB,
  SHOW_ROUTE_CAMPAIGNS_OVERVIEW,
  SHOW_ROUTE_CAMPAIGNS_LOCATION,
  SHOW_ROUTE_CAMPAIGNS_AUDIENCE,
  SHOW_ROUTE_CAMPAIGNS_CATEGORY,
  SHOW_ROUTE_CAMPAIGNS_TIMING,
  SHOW_ROUTE_CAMPAIGNS_DEVICE,
  SHOW_ROUTE_CAMPAIGNS_IAB,

  // Content
  ENABLE_ROUTE_CONTENT,
  ENABLE_ROUTE_CONTENT_OVERVIEW,
  ENABLE_ROUTE_CONTENT_LOCATION,
  ENABLE_ROUTE_CONTENT_AUDIENCE,
  ENABLE_ROUTE_CONTENT_CATEGORY,
  ENABLE_ROUTE_CONTENT_TIMING,
  ENABLE_ROUTE_CONTENT_DEVICE,
  ENABLE_ROUTE_CONTENT_IAB,
  ENABLE_ROUTE_CONTENT_PLATFORM,
  SHOW_ROUTE_CONTENT_OVERVIEW,
  SHOW_ROUTE_CONTENT_LOCATION,
  SHOW_ROUTE_CONTENT_AUDIENCE,
  SHOW_ROUTE_CONTENT_CATEGORY,
  SHOW_ROUTE_CONTENT_TIMING,
  SHOW_ROUTE_CONTENT_DEVICE,
  SHOW_ROUTE_CONTENT_IAB,
  SHOW_ROUTE_CONTENT_PLATFORM,

  // Summary
  ENABLE_ROUTE_SUMMARY,
  SHOW_ROUTE_SUMMARY

} from "util/const"

// Styling
import "./App.css"
import "./constants.css"

// --- Static JSON example 1/2
// Static JSON file (until the final endpoint is ready)
// import local_json from `./_${ROTANA_DEMO_MODE ? "rotana" : "adverty"}.json`// ALT (can be dynamic w/ '${name}'): let JSON = require("_.json")
import local_json_adverty from "./_adverty.json"// ALT (can be dynamic w/ '${name}'): let JSON = require("_.json")
import local_json_rotana from "./_rotana.json"
import local_json_stc from "./_stc.json"
import local_json_stc_v2 from "./_stc_v2.json"
// --- Static JSON example 1/2

const globals = {
  theme: "light",
  data: {reach: {}},
  data_json_test: {}
}

// Component
export const App = () => {

  const [isReady, setReady] = useState()

  const renderTime = useRef(Date.now().toString().slice(0, 8)/* e.g. 17098824 (unix time w/o the trailing 0's [seconds and below] b/c timestamp always a rounded multiple of 3600) */)
  const fetch = useFetch()
  const loadSetup = async (/* token */) => {

    // --- API LOADER (landing) -> Duplicated at Dropdown.jsx b/c we are waiting for the new GraphQL single-call solution suggested by Jeff
    // Default params
    const params = `?from_date=${ISO8601([2024, 3, 31], 30)}&to_date=${ISO8601()}&tier=all&campaign_id=${"all"}&format_fe=1`
    
    // API calls
    const responses = []
    const endpoints = [

      // Campaigns (post) - https://adverty-post-campaign-api-mzfn5.ondigitalocean.app/docs#/
      "/overall",
      "/campaigns",
      "/devices/chart",
      "/devices/table",
      "/timing/chart",
      "/timing/table",
      "/categories/chart",
      "/categories/table",
      "/locations/chart",
      "/locations/table",

      // Campaigns (pre -> calculator) - https://adverty-reach-ks8ud.ondigitalocean.app/docs#
      "/list/locations",
      "/list/categories"
    ]
    for (const endpoint of endpoints) {
      const isReach = endpoint.startsWith("/list")
      responses.push(await fetch({url: `${ROUTE_API/*${publicKey}*/}${(isReach ? "/reach" : "/post-campaign") + endpoint}${isReach ? "" : params}`}))
    } 

    // Payloads
    const [

      // Campaigns
      overall, 
      campaigns, 
      devices_chart, 
      devices_table, 
      timing_chart,
      timing_table,
      categories_chart, 
      categories_table, 
      locations_chart, 
      locations_table,

      // Calculator
      location,
      category

    ] = responses
    // --- API LOADER (landing)

    // Opening session defaults -> Legacy local JSON recreation for gradual data blending & testing (with fallback support until production-ready)
    const __LOCAL_JSON__ = ROUTE_SUMMARY ? local_json_stc_v2["john.doe@acme.com"] : (ROUTE_CONTENT ? local_json_stc["john.doe@acme.com"] : local_json_adverty["john.doe@acme.com"])
    const __LOCAL_CAMP__ = __LOCAL_JSON__.postcampaigns.defaults 
    const dataset = {
      "john.doe@acme.com": {
        // --- STC v2
        "summary": __LOCAL_JSON__?.summary,
        // --- STC v2
        "filters": {
          locations: location.payload.locations,
          categories: category.payload.categories,
          // "demographics": null,
          // "length": null,
          // "budget": null,
          // "keywords": null,
          // "format": null,
          // "iab": null  
        },
        "precampaigns": __LOCAL_JSON__.precampaigns,
        "postcampaigns": {
          "registry": Object.assign({
              all: {
                "about": "",
                "brand": "",
                "dbref": "ALL_CAMPAIGNS",
                "gender": [],
                "period": [],
                "poster": "https://via.placeholder.com/846x1198",
                "title": "All campaigns"
              }
          }, campaigns.payload.campaigns || __LOCAL_JSON__.postcampaigns?.registry),
          "defaults": {
            details: __LOCAL_CAMP__.details,
            // PRE: overall: !ROUTE_CONTENT && ENABLE_ROUTE_CONTENT ? __LOCAL_CAMP__.overall : (overall.payload.overall || __LOCAL_CAMP__.overall), 
            overall:  !ROUTE_CONTENT ? overall.payload.overall : __LOCAL_CAMP__.overall, 
            devices_chart: !ROUTE_CONTENT ? devices_chart.payload.devices : __LOCAL_CAMP__.devices_chart,
            devices_table: !ROUTE_CONTENT ? devices_table.payload.devices : __LOCAL_CAMP__.devices_table,
            timing_chart: !ROUTE_CONTENT ? timing_chart.payload.timing : __LOCAL_CAMP__.timing_chart,
            timing_table: !ROUTE_CONTENT ? timing_table.payload.timing : __LOCAL_CAMP__.timing_table,
            audiences_chart: __LOCAL_CAMP__.audiences_chart,
            audiences_table: __LOCAL_CAMP__.audiences_table,
            categories_chart: !ROUTE_CONTENT ? categories_chart.payload.categories : __LOCAL_CAMP__.categories_chart,
            categories_table: !ROUTE_CONTENT ? categories_table.payload.categories : __LOCAL_CAMP__.categories_table,
            locations_chart: !ROUTE_CONTENT ? locations_chart.payload.locations : __LOCAL_CAMP__.locations_chart,
            locations_table: !ROUTE_CONTENT ? locations_table.payload.locations : __LOCAL_CAMP__.locations_table
          }

          
        }
      }
    }

    const output = {
      "status": undefined, 
      "payload": {}
    }
    if (responses.every((response) => response.status === "success")) {
      output.status = "success"
      output.payload = (ROTANA_DEMO_MODE ? local_json_rotana : dataset)["john.doe@acme.com"/* dummy-token-test */]
    }
    // --- Static JSON example 2/2
    return new Promise((resolve, reject) => {// -> Explicit promise [vs equivalent b/c async 'return output?.payload'] for better resolve/reject management
      if (output?.status === "success") {
        resolve(output?.payload || {})
      } else {
        reject("Error: not all data was retrieved")
      }
    })
  }

  const loader = useRef(null)
  useEffect(() => {

    // DEV --- 
    localStorage.clear()// -> Start over (alt: 'removeItem()' [or 'key()' to read by index])
    // DEV ---

    const cache = JSON.parse(localStorage.getItem("john.doe@acme.com"))
    if (!cache || (cache?.latest < (renderTime.current - 36)/* One hour [+ 1 minute] has passed since the last BE snapshot */)) {

      loadSetup(/* token */).then((response) => {
        localStorage.setItem("john.doe@acme.com", JSON.stringify(response)) 

        // --- LOADER 1/2 ---
        // setTimeout(() => {// -> TEMP: Fetchless lag simulation
        globals.data_json_test = Object.assign(globals.data_json_test, response)// -> Deep copy (otherwise 'context' won't be overriden [unless direct [sub]target as in 'globals.data_json_test.newkey = newvalue'])
        loader.current.classList.add("loader-wrapper--hide")
        setReady(true)
        // }, 3000)
        // --- LOADER 1/2 ---

      }).catch((error) => {
        console.error(error)
      })
    }
  }, [/* Once */])

  return (
    <ProvideGlobals globals={globals}>

        {/* --- LOADER 2/2 --- */}
        {/* TODO: Make timer configurable & convert into a component */}
        <div ref={loader} className={`loader-wrapper`}>
          <div className="loader">
            <div className="loader-bgs">
              <div className="loader-bg1"></div>
              <div className="loader-bg2"></div>
            </div>
            <div className="loader-fgs">
              <div className="loader-fg1"></div>
              <div className="loader-fg2"></div>
              <div className="loader-fg3"></div>
              <div className="loader-fg4"></div>
            </div>
          </div>
        </div>
        {/* --- LOADER 2/2 --- */}

        <BrowserRouter basename={ROUTE_PREFIX}>
            {ENABLE_CATALOG
            ? (
              <Routes>
                <Route path={`/${ROUTE_CATALOG}`} element={<Catalog ready={isReady}/>}/>
                <Route path="*" element={<Navigate to={`/${ROUTE_CATALOG}`} replace/>}/>
              </Routes>
            )
            : 
            (
              <Routes>

                {/* Home */}
                {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_HOME) && (
                  <Route path={`/${ROUTE_HOME}`} element={<Home ready={isReady}/>}/>
                )}

                {/* Campaigns */}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CAMPAIGNS_OVERVIEW && ENABLE_ROUTE_CAMPAIGNS_OVERVIEW) || ROTANA_DEMO_MODE) && (
                  <Route path={`/${ROUTE_CAMPAIGNS}`} element={<Campaigns ready={isReady}/>}/>
                )}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CAMPAIGNS_AUDIENCE && ENABLE_ROUTE_CAMPAIGNS_AUDIENCE) || ROTANA_DEMO_MODE) && (
                  <Route path={`/${ROUTE_CAMPAIGNS_01}`} element={<CampaignsAudiences ready={isReady}/>}/>
                )}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CAMPAIGNS_LOCATION && ENABLE_ROUTE_CAMPAIGNS_LOCATION) || ROTANA_DEMO_MODE) && (
                  <Route path={`/${ROUTE_CAMPAIGNS_02}`} element={<CampaignsLocations ready={isReady}/>}/>
                )}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CAMPAIGNS_CATEGORY && ENABLE_ROUTE_CAMPAIGNS_CATEGORY) || ROTANA_DEMO_MODE) && (
                  <Route path={`/${ROUTE_CAMPAIGNS_03}`} element={<CampaignsCategory ready={isReady}/>}/>
                )}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CAMPAIGNS_IAB && ENABLE_ROUTE_CAMPAIGNS_IAB) || ROTANA_DEMO_MODE) && (
                  <Route path={`/${ROUTE_CAMPAIGNS_04}`} element={<CampaignsIAB ready={isReady}/>}/>
                )}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CAMPAIGNS_TIMING && ENABLE_ROUTE_CAMPAIGNS_TIMING) || ROTANA_DEMO_MODE) && (
                  <Route path={`/${ROUTE_CAMPAIGNS_05}`} element={<CampaignsTiming ready={isReady}/>}/>
                )}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CAMPAIGNS_DEVICE && ENABLE_ROUTE_CAMPAIGNS_DEVICE) || ROTANA_DEMO_MODE) && (
                  <Route path={`/${ROUTE_CAMPAIGNS_06}`} element={<CampaignsDevices ready={isReady}/>}/>
                )}

                {/* Content */}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CONTENT_OVERVIEW && ENABLE_ROUTE_CONTENT_OVERVIEW)) && (
                  <Route path={`/${ROUTE_CONTENT}`} element={<Content ready={isReady}/>}/>
                )}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CONTENT_AUDIENCE && ENABLE_ROUTE_CONTENT_AUDIENCE)) && (
                  <Route path={`/${ROUTE_CONTENT_01}`} element={<ContentAudiences ready={isReady}/>}/>
                )}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CONTENT_LOCATION && ENABLE_ROUTE_CONTENT_LOCATION)) && (
                  <Route path={`/${ROUTE_CONTENT_02}`} element={<ContentLocations ready={isReady}/>}/>
                )}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CONTENT_CATEGORY && ENABLE_ROUTE_CONTENT_CATEGORY)) && (
                  <Route path={`/${ROUTE_CONTENT_03}`} element={<ContentCategory ready={isReady}/>}/>
                )}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CONTENT_IAB && ENABLE_ROUTE_CONTENT_IAB)) && (
                  <Route path={`/${ROUTE_CONTENT_04}`} element={<ContentIAB ready={isReady}/>}/>
                )}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CONTENT_TIMING && ENABLE_ROUTE_CONTENT_TIMING)) && (
                  <Route path={`/${ROUTE_CONTENT_05}`} element={<ContentTiming ready={isReady}/>}/>
                )}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CONTENT_DEVICE && ENABLE_ROUTE_CONTENT_DEVICE)) && (
                  <Route path={`/${ROUTE_CONTENT_06}`} element={<ContentDevices ready={isReady}/>}/>
                )}
                {(SHOW_N_ENABLE_ALL || (SHOW_ROUTE_CONTENT_PLATFORM && ENABLE_ROUTE_CONTENT_PLATFORM)) && (
                  <Route path={`/${ROUTE_CONTENT_07}`} element={<ContentDevices ready={isReady}/>}/>
                )}

                {/* Help */}
                {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_HELP) && (
                  <Route path={`/${ROUTE_HELP}`} element={<Help ready={isReady}/>}/>
                )}

                {/* Settings */}
                {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_SETTINGS) && (
                  <Route path={`/${ROUTE_SETTINGS}`} element={<Settings ready={isReady}/>}/>
                )}
                
                {/* Calculator */}
                {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CALCULATOR) && (
                  <Route path={`/${ROUTE_CALCULATOR}`} element={<Calculator ready={isReady}/>}/>
                )}

                {/* Summary (starting w/ STC demo v2) */}
                {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_SUMMARY) && (
                  <Route path={`/${ROUTE_SUMMARY}`} element={<Summary ready={isReady}/>}/>
                )}

                {/* Default (fallback) */}
                <Route path="*" element={<Navigate to={`/${ROUTE_CONTENT}`} replace/>}/>
              </Routes>
            )}
        </BrowserRouter>

      </ProvideGlobals>
  )
}
export default App