import React, {
  useRef, 
  createRef
} from "react"

import "./Persona.css"

// Assets
import PicPersonaF15c from "assets/portraits/f15c.png"
import PicPersonaF20c from "assets/portraits/f20c.png"
import PicPersonaF25c from "assets/portraits/f25c.png"
import PicPersonaF30c from "assets/portraits/f30c.png"
import PicPersonaF35c from "assets/portraits/f35c.png"
import PicPersonaF40c from "assets/portraits/f40c.png"
import PicPersonaF45c from "assets/portraits/f45c.png"
import PicPersonaF50c from "assets/portraits/f50c.png"
import PicPersonaF55c from "assets/portraits/f55c.png"
import PicPersonaF60c from "assets/portraits/f60c.png"
import PicPersonaF65c from "assets/portraits/f65c.png"
import PicPersonaF70c from "assets/portraits/f70c.png"
import PicPersonaF75c from "assets/portraits/f75c.png"
import PicPersonaF80c from "assets/portraits/f80c.png"
import PicPersonaM15c from "assets/portraits/m15c.png"
import PicPersonaM20c from "assets/portraits/m20c.png"
import PicPersonaM25c from "assets/portraits/m25c.png"
import PicPersonaM30c from "assets/portraits/m30c.png"
import PicPersonaM35c from "assets/portraits/m35c.png"
import PicPersonaM40c from "assets/portraits/m40c.png"
import PicPersonaM45c from "assets/portraits/m45c.png"
import PicPersonaM50c from "assets/portraits/m50c.png"
import PicPersonaM55c from "assets/portraits/m55c.png"
import PicPersonaM60c from "assets/portraits/m60c.png"
import PicPersonaM65c from "assets/portraits/m65c.png"
import PicPersonaM70c from "assets/portraits/m70c.png"
import PicPersonaM75c from "assets/portraits/m75c.png"
import PicPersonaM80c from "assets/portraits/m80c.png"
const personas = {
  "f15c": [PicPersonaF15c, "Jessica Miller"],
  "f20c": [PicPersonaF20c, "Emily Johnson"],
  "f25c": [PicPersonaF25c, "Sarah Brown"],
  "f30c": [PicPersonaF30c, "Jennifer Taylor"],
  "f35c": [PicPersonaF35c, "Ashley Doe"],
  "f40c": [PicPersonaF40c, "Jane Wilson"],
  "f45c": [PicPersonaF45c, "Lisa Thompson"],
  "f50c": [PicPersonaF50c, "Linda White"],
  "f55c": [PicPersonaF55c, "Carol Lewis"],
  "f60c": [PicPersonaF60c, "Ruth Walker"],
  "f65c": [PicPersonaF65c, "Sharon Hall"],
  "f70c": [PicPersonaF70c, "Patricia Anderson"],
  "f75c": [PicPersonaF75c, "Susan Harris"],
  "f80c": [PicPersonaF80c, "Betty Clark"],
  "m15c": [PicPersonaM15c, "Michael Green"],
  "m20c": [PicPersonaM20c, "David Jones"],
  "m25c": [PicPersonaM25c, "James Smith"],
  "m30c": [PicPersonaM30c, "Robert Davis"],
  "m35c": [PicPersonaM35c, "William Moore"],
  "m40c": [PicPersonaM40c, "Richard Taylor"],
  "m45c": [PicPersonaM45c, "Charles Thomas"],
  "m50c": [PicPersonaM50c, "Joseph Jackson"],
  "m55c": [PicPersonaM55c, "Christopher Harris"],
  "m60c": [PicPersonaM60c, "Daniel Martin"],
  "m65c": [PicPersonaM65c, "Paul Thompson"],
  "m70c": [PicPersonaM70c, "Mark Anderson"],
  "m75c": [PicPersonaM75c, "Donald Lewis"],
  "m80c": [PicPersonaM80c, "Jason Scott"]
}

export const Persona = ({id}) => {
//export const Persona = ({id, alt=personas[id][1]}, ref={42}) => {

  // TODO: Export unique name too from here
  return (
    <>
    {/* // <div className="persona"> */}
      <img src={personas[id][0]} alt={personas[id][1]}/>
      {/* <span className="text-xs">{personas[id][1]}</span> */}
    {/* // </div> */}
    </>
  )

  // return ([{
  //   src: personas[id][0],
  //   alt: personas[id][1],
  //   img: <img src={personas[id][0]} alt={personas[id][1]}/>
  // }])
  // ref = createRef()
  // return <img ref={ref} src={personas[id][0]} alt={personas[id][1]}/>

}