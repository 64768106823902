/*
  v240515 - Franc Jerez
	v240523 - Franc Jerez - Upperize support
*/

// Use commas as thousands separator
export const Commaize = (number) => {
	return number?.toString()?.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")
}

// Date formatter for API filtering
export const ISO8601 = (usrDate, offset=0) => {
	const [year, month, day] = Array.isArray(usrDate) ? usrDate : []

	// Normalize date (whatever the input is)
	const date = day ? 
		Date.UTC(year, month - 1, day) : 
		+("" + usrDate).padEnd(13, "0") || Date.now() // -> Unix time
	
	// Offset
	let newDate = new Date(date)
	if (offset) {
		newDate = new Date(newDate.setDate(newDate.getDate() - offset))
	}

	// Return ISO 8601 format
	return newDate?.toISOString().slice(0, 10)/*OR '.split("T")[0]'*/// -> ALT: date.toLocaleDateString("en-ca") || Intl.DateTimeFormat("en-ca", {}).format(date) || date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1).toString().padStart(2, "0") + '-' + (date.getUTCDate()).toString().padStart(2, "0")
}
/* Examples
   ISO8601()
   > "2024-05-03"
   ISO8601([2024, 05, 03])
	 > "2024-05-03"
	 ISO8601(1714733227000)
	 > "2024-05-03"
	 ISO8601("1714733227")
	 > "2024-05-03"
	 ISO8601(__ANY__, 2)
   > "2024-05-01"
*/

// Number formatting
const Convert = (numbers, mode, decimals) => {
	const isArray = Array.isArray(numbers)
	const numbersConverted = []
	let output
	for (const number of [numbers].flat()) {//ALT: Array.isArray(numbers) ? numbers : [numbers]'
		
		// Number to shortened/abbreviated number
		if (mode == 0) {
			const numberLength = Math["ceil"/* -> Can be changed to "floor" for 'thousands' instead */](Math.log10(number + 1))// LEGACY (~99% [vs ~97%] support): Math.ceil(Math.log(number) / Math.LN10 ) + 1// -> WHY: 'number.toString().length' has a limit of 22 digits b/c the number is collapsed into exponential notation before its bytes are counted as a string
			const wholeLength = (numberLength % 3) || 3
			const wholeSuffix = {
				"3": "K",
				"6": "M",
				"9": "B",
				"12": "T"
			}[numberLength - wholeLength] || ""
			output = (number / (10**(numberLength - wholeLength))).toFixed(decimals || 0) + wholeSuffix
		} else 
		
		// Number to CSS values
		if (mode == 1) {
			output = isNaN(number) ? "auto" : (number < 1 ? ((numbers * 100) + "%") : (numbers + "px"))
		}
		numbersConverted.push(output)
	}
	return isArray ? numbersConverted : numbersConverted?.pop()
}

export const Shorten = (numbers, decimals=1) => {
	return Convert(numbers, 0, decimals)
}
// Examples
/* 
   Shorten(42000000, 0, 1)
   > "42.0M"
   Shorten("42K", 0)
   > "42K"
*/

export const CSSize = (numbers) => {
	return Convert(numbers, 1)
}
/* Examples
   CSSize(42, 1)
   > "42px"
   CSSize(.4, 1)
   > "40%"
*/

export const Typeize = (string) => {
	const number = (((("" + string).split(" ")?.[0]).replaceAll(/[,$:a-zA-Z]+/g, "")).match(/^-?\d*\.?\d+/g, "")?.[0]) * 1
	return isNaN(number) ? string : number// ALT: (number || number === 0) || string
}
/* Examples
   Typeize("42.0M")
   > 42.0
   Typeize("$42")
   > 42
*/

export const Upperize = (string) => {
	let output = ""
	if (string) {
		for (const part of string.split("_")) {
			if (!["in", "at", "on", "of", "to", "and", "by", "off", "with"].includes(part)) {
				output += part[0].toUpperCase() 
			} else {
				output += part[0]
			}
			output += part.slice(1, part.length) + " "
		}
		output.trimEnd()
	}
	return output || string
}
/* Examples
   Upperize("foo_and_bar")
   > Foo and Bar
*/