// Contexts
import {
  useGlobals
} from "contexts"

// Containers
import {
    Grid,
    Layout
} from "containers"

// Components
import {
  Chart,
  TableSort
} from "components"

import {
  ROTANA_DEMO_MODE
} from "util/const"

// Audiences-wide styling
import "./CampaignsAudiences.css"

// Audiences assembly
export const CampaignsAudiences = ({ready}) => {

    // --- Shared ---
    const globals = useGlobals() 

    // Unpack engagement grouping settings
    const group = globals?.context?.dropdown_selection || "_"

    // let picks = globals.get("checkboxed") || (ROTANA_DEMO_MODE 
    //   ? ["f35c", "f80c", "f55c", "m45c", "m55c", "m60c", "m20c", "m75c", "f20c", "m25c", "f40c", "m80c", "f30c", "f15c", "m50c", "f50c", "f45c", "m70c", "m15c", "f70c", "m65c", "m40c", "m30c", "f60c", "f25c", "f65c", "f75c", "m35c"]
    //   : ["m25c", "m30c", "m35c", "m40c", "m45c", "m50c", "m55c", "f25c", "f30c", "f35c", "f40c", "f45c", "f50c", "f55c"]
    // )

    const jsondata = ROTANA_DEMO_MODE 
    ? 
    globals?.context?.data_json_test?.campaigns
    : 
    globals?.context?.data_json_test?.postcampaigns?.["defaults"/*campaign*/]
  
    // const arr_all_obj = ROTANA_DEMO_MODE ? jsondata?.categories_chart?.[group] : jsondata?.categories_chart
    const names = (ROTANA_DEMO_MODE ? jsondata?.audiences_chart?.[group] : jsondata?.audiences_chart) || []
    const picks = globals.get("checkboxed") || (

    /* First access */
      ROTANA_DEMO_MODE 
      ?
      ["f35c", "f80c", "f55c", "m45c", "m55c", "m60c", "m20c", "m75c", "f20c", "m25c", "f40c", "m80c", "f30c", "f15c", "m50c", "f50c", "f45c", "m70c", "m15c", "f70c", "m65c", "m40c", "m30c", "f60c", "f25c", "f65c", "f75c", "m35c"]
      :
      // ["m25c", "m30c", "m35c", "m40c", "m45c", "m50c", "m55c", "f25c", "f30c", "f35c", "f40c", "f45c", "f50c", "f55c"]
      Object.keys(names)
      )


    let arr_all = {}
    let arr_dat = []
    if (ready) {
      /*JSON*///const names = ROTANA_DEMO_MODE ? jsondata?.audiences_chart?.[group] : jsondata?.audiences_chart

      for (let i = 0, j = 0; i < picks?.length; i++) {
        let text = picks[i].replaceAll("checkboxgroup-", "")

        // TODO: Avoid remapping of any kind [after harmonizing all the data for all sections]
        if (names?.[text]) {
          arr_all[names?.[text]?.[2]] = [names?.[text]?.[0], names?.[text]?.[1], text]
        } 
        // TODO (Original values Rotana [forced to be consecutive at _rotana.json]): 
        // "_": {
        //   "f35c": [194, 122, 42.0],
        //   "f80c": [193, 66, 44.0 ],
        //   "f55c": [456, 79, 47.0],
        //   "m45c": [174, 62, 47.0],
        //   "m55c": [68, 47, 42.0],
        //   "m60c": [153, 86, 41.0],
        //   "m20c": [126, 74, 63.0],
        //   "m75c": [106, 39, 47.0],
        //   "f20c": [173, 129, 66.0],
        //   "m25c": [1855, 86, 69.0],
        //   "f40c": [4743, 2014, 71.0],
        //   "m80c": [74, 54, 41.0],
        //   "f30c": [1634, 532, 72.0],
        //   "f15c": [1119, 564, 70.0],
        //   "m50c": [231, 152, 69.0],
        //   "f50c": [654, 355, 59.0],
        //   "f45c": [4152, 1617, 71.0],
        //   "m70c": [62, 30, 47.0],
        //   "m15c": [5130, 2106, 68.0],
        //   "f70c": [649, 378, 74.0],
        //   "m65c": [74, 29, 47.0],
        //   "m40c": [550, 400, 72.0],
        //   "m30c": [78, 58, 44.0],
        //   "f60c": [114, 62, 46.0],
        //   "f25c": [7215, 2196, 72.0],
        //   "f65c": [404, 253, 68.0],
        //   "f75c": [336, 270, 39.0],
        //   "m35c": [69, 27, 45.0]
        // },
        // "high": {
        //   "f40c": [4743, 2014, 71.0],
        //   "f30c": [1634, 532, 72.0],
        //   "f45c": [4152, 1617, 71.0],
        //   "f70c": [649, 378, 74.0],
        //   "m40c": [550, 400, 72.0],
        //   "f25c": [7215, 2196, 72.0]
        // },
        // "medium": {
        //   "f55c": [456, 79, 47.0],
        //   "m45c": [174, 62, 47.0],
        //   "m20c": [126, 74, 63.0],
        //   "m75c": [106, 39, 47.0],
        //   "f20c": [173, 129, 66.0],
        //   "m25c": [1855, 86, 69.0],
        //   "f15c": [1119, 564, 70.0],
        //   "m50c": [231, 152, 69.0],
        //   "f50c": [654, 355, 59.0],
        //   "m70c": [62, 30, 47.0],
        //   "m15c": [5130, 2106, 68.0],
        //   "m65c": [74, 29, 47.0],
        //   "f60c": [114, 62, 46.0],
        //   "f65c": [404, 253, 68.0],
        //   "m35c": [69, 27, 45.0]
        // },
        // "low": {
        //   "f35c": [194, 122, 42.0],
        //   "f80c": [193, 66, 44.0 ],
        //   "m55c": [68, 47, 42.0],
        //   "m60c": [153, 86, 41.0],
        //   "m80c": [74, 54, 41.0],
        //   "m30c": [78, 58, 44.0],
        //   "f75c": [336, 270, 39.0]
        // }
      }
      // arr_dat = rows
      /*JSON*/ arr_dat = [...jsondata?.audiences_table[group]] || []

   
    }

    return (

    // ---------------- DUPLICATED (to be converted into a separated layout component)

      <Layout header={{
        "title": "Audiences",
        "search": ""
      }}>

      {!ROTANA_DEMO_MODE && ![undefined, "allcampaigns"].includes(globals?.context?.dropdown_selection_campaigns) ? <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">
        <span onClick={() => {
          globals.set("dropdown_selection_campaigns", "allcampaigns")
        }}>Audiences</span>
        {/* <span>{globals?.context?.data_json_test?.postcampaigns?.overall?.details?.name}</span>
        <span>{obj_top?.name}</span> */}
        {/* <span>{globals?.context?.data_json_test?.postcampaigns?.defaults?.overall?.[group]?.details?.name}</span> */}
        <span>{globals?.context?.data_json_test?.postcampaigns?.defaults?.details?.name}</span>
      </div> : <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">Audiences</div>}

      <Grid 
        content={[
          <Chart 
          text={{
            title: "",// "Impressions by Engagement",
            // subtitle: "",
            x_label: "Engagement"         
          }}
          data={arr_all}
          mode={2}
        />,
        
          ]}
          template={`
              0
              `}
          cellSize={{
            w: 800,
            h: 400
          }}
        />
        <Grid
          content={[
            <TableSort 
              data={arr_dat}
              stick={true}// !!!!TODO: "All" to be automatically generated if selected
              undim={{"high": 4, "medium": 5, "low": 6}[group]}
              mode={ROTANA_DEMO_MODE ? 2 : 2}
            />
          ]}
          template={`
              0
              `}
          cellSize={{
            w: 800, 
            h: arr_dat?.length * 56 //800
          }}
        />

      </Layout>
)}