import {
  useNavigate
} from "react-router-dom"

import {
  useEffect,
  useState,
  useRef
} from "react"

// Contexts
import { 
  useGlobals
} from "contexts"

// Elements
import { 
  Orb,
  Insert,
  Tick/* TODO: Improve support for clock-like times (preferred b/c of uniqueness [this CG component will break otherwise] & consistency) OR give full support for ticks */
} from "elements"

import {
  ROUTE_CONTENT
} from "util/Routes.const"

// Catalog-wide styling
import "./CheckboxGroup.css" 

// All-inclusive keywords (compute once)
const selectAllId = [/*"total",*/ "all", "un"]
for (let i = 0; i < selectAllId.length; i++) {
  selectAllId[i] = "checkboxgroup-" + selectAllId[i]
}

export const CheckboxGroup = ({label, checked=true/* All selected by default */, active=true, namespace=true/*'auto' if true*/, callback, highlight=false, ifttt, type="checkbox"}) => {
  const navigate = useNavigate()

  label = isNaN(label) ? label : "" + label// -> Numbers are converted to strings (to be able to use them as keys)

  // TODO: Support icon groups if needed (odd use case anyway)
  const isString = typeof label === "string"

  const globals = useGlobals() 
  const id = "checkboxgroup-" + (isString 
    ? (
      label.slice(0, 4).toLowerCase() === "all " 
        ? "all" 
        : label?.replaceAll(/\s/g, "_").toLowerCase()
      ) 
    : null)
  // const selectAllId = "checkboxgroup-total"// -> Legacy

  let picks = [] 
  const input = useRef()
  const selectSomeClass = "checkboxgroup__pick--checkbox-some"
  const [_, reRender] = useState(false) 

  const processPick = (autoSelect) => {
    const thisInput = input.current
    
    if (thisInput.name === "_" && isString) {

      // Auto-detect group
      let items, parent
      let depth = 3 // -> Ok b/c up to ~10000 calls w/o any noticeable lag
      while (depth--) {
        if (depth === 3) parent = thisInput.parentElement.parentElement
        if (depth === 2) parent = thisInput.parentElement.parentElement?.parentElement
        if (depth === 1) parent = thisInput.parentElement.parentElement?.parentElement?.parentElement
        items = parent.querySelectorAll("input[name='_'][id^='checkboxgroup-'")
        if (items.length > 1) break
      }

      // Every time a new checkbox is clicked we need to update the picks array
      let total
      for (const item of items) {
        if (!selectAllId.includes(item.id)) {
          item.checked && picks.push(item.id)// Register the ones already checked
        } else {
          total = item
        }
      }

      // A - The 'All' checkbox is checked/unchecked 
      if (selectAllId.includes(thisInput.id)) {// -> Only 'All'
        picks = []
        const allToBeTicked = thisInput.checked
        if (allToBeTicked) {
          total.classList.remove(selectSomeClass) 
        }
        for (const item of items) {
          item.checked = allToBeTicked
          if (!selectAllId.includes(item.id)) {
            item.checked && picks.push(item.id)// Buffer checkbox (all at once)
          }
        }
      } 
      // B - Some non-'All' checkbox is checked/unchecked
      else if (total) {// -> All except 'All'
        if (picks.length) {
          total.checked = true
          total.classList[picks.length === (items.length - 1) ? "remove" : "add"](selectSomeClass)
        } else {
          total.classList.remove(selectSomeClass)
          total.checked = false
        }
      }

      if (ifttt) {
        // checked = autoSelect ? checked : !checked
        const iftttIds = []
        const iftttKey = Object.keys(ifttt)?.[0]
        const iftttArr = ifttt[iftttKey]
        const lookFrom = parent.parentElement.parentElement// ALT: document (full section scope)
        for (let iftttCode of iftttArr) {
          const iftttId = "checkboxgroup-" + iftttCode
          const iftttInput = lookFrom.querySelector(`input[name='_'][id='${iftttId}']`)// ALT (less safe): '"#" + iftttId'
          if (iftttInput) {
            iftttInput.checked = !checked
            iftttIds.push(iftttId)
          } /* else {
            console.warn(`Unsupported '${iftttKey}' item '${iftttCode}'`)
          } */ 
        }

        // TODO: Create 'globals.add("locations")' instead w/o re-rendering (and create empty object WITHIN the globals context if no available)
        const iftttContext = globals.context[iftttKey] = (globals.context?.[iftttKey] || {})
        if (checked) {
          // Remove 
          iftttContext.checkboxed = iftttContext.checkboxed?.filter(item => !(new Set(iftttArr)).has(item.replace("checkboxgroup-", "")) )
        } else {
          // Add
          iftttContext.checkboxed = [...new Set([...(iftttContext.checkboxed || []), ...iftttIds])]// -> Merge & then remove dups)
        }
      }

      // Set main/host checkbox/es (default behavior)
      globals.set("checkboxed", picks, namespace) 
      // autoSelect && (input.current.checked = checked)
    }
  }

  useEffect(() => {

      // Default checkbox state (recall from previous choices if stored in globals)
      const buffer = globals.get("checkboxed", namespace)
      const labelId = "checkboxgroup-" + label?.replaceAll(" ", "_")?.toLowerCase()
      const isAll = buffer !== undefined && selectAllId.includes(labelId.split("_")[0])
      const isChecked = checked && (!buffer || buffer?.includes(labelId) || isAll)
      if (isChecked) {
        input.current.checked = isChecked
        // processPick(true)

        // TODO: Remove after making sure not needed anymore (no conditional row-highlighting conditional classes anymore)
        // reRender()// -> Force re-render to allow conditional classes to refresh too (only on first load))
      } 
      // TODO: We need [sortable so chart x-values can be sortened too] null/unchecked remembered values too (otherwise we can't know beforehand if 'All' should be full or partial)
      // > This is why 'All' gets 'selectSomeClass'd after returning to the section even if all checkboxes are checked  
      isAll && input.current.classList.add(selectSomeClass)
  }, [label])

  return ROUTE_CONTENT && !highlight
  ? 

  // v2 - Label & checkbox have different functions (temp until new table support checkboxes)
  <div className={`checkboxgroup checkboxgroup--${active === null ? "hidden" : (active === false ? "disabled" : (input.current?.checked && highlight ? "select" : ""))}  ${typeof highlight === "number" ? "checkboxgroup--transparent" : ""}       `} onClick={(e) => {
    
    // Alt to the one at 'insert.jsx'
    e.preventDefault()
    
    // const path = window.location?.pathname
    // const key = ["", "audience", "location", "", "", "time", "device"][Number(path?.split("/")?.pop())]
    // const value = label
    // navigate(path + (value.startsWith("All ") ? "" : "?" + key + "=" + value))

  }
  }>
    <input ref={input} className={`checkboxgroup__pick checkboxgroup__pick--${type}`} value={label} type={type} name="_" id={id} /*disabled={index === 2}*/ onClick={(e) => {
      e.stopPropagation()
      processPick()
    }}/>
    <label className={`checkboxgroup__text`} htmlFor={id}>{isNaN(label) ? <Insert what={label}/> : <Tick value={label + ":00"} tag="1"/>}</label>
  </div>
  :

  // v1 - The whole cell is the checkbox
  <div className={`checkboxgroup ${highlight ? "checkboxgroup--full" : ""} checkboxgroup--${active === null ? "hidden" : (active === false ? "disabled" : (input.current?.checked && highlight ? "select" : ""))}  ${typeof highlight === "number" ? "checkboxgroup--transparent" : ""}      `} onClick={(e) => {
    e.stopPropagation()
    processPick()
  }
  }>
    <input ref={input} className={`checkboxgroup__pick checkboxgroup__pick--${type}`} value={label} type={type} name="_" id={id} /*disabled={index === 2}*//>
    
    {/* v2 (w/ the new Orb component instead) */}
    
    {/* <label className={`checkboxgroup__text`} htmlFor={id}>{isNaN(label) ? <Orb what={label} over={false}/> : <Tick value={label + ":00"} tag="1"/>}</label> */}
  
    {/* v1 */}
    <label className={`checkboxgroup__text`} htmlFor={id}>{isNaN(label) ? <Insert what={label}/> : <Tick value={label + ":00"} tag="1"/>}</label>
    
  </div>
  
  

}

