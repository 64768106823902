/*
  v240513 - Franc Jerez
  v240521 - Franc Jerez - Relative paths support
  v240522 - Franc Jerez - Underlined links support
*/

// Core
import {
    Link
  } from "react-router-dom"
  
// Util
import {
    BADGES
} from "util_v2/Mappings.const"

// Styling
import "./Orb.css" 

/* TODO:
   - Support for "how" arrangements (i.e. icon on t/r/b/l) for cards & right-aligned cell tables
*/
// Component
const getItems = (what/*, how*/) => {
    const check = (type) => {
        const rules = {
            "orb": ["persona", "location"],
            "dim": ["other"],
            "pad": ["url"]
        }
        return "_-" + Object.keys(rules).filter((x) => rules[x].includes(type))?.toString()?.replace(",", "_-")
    }
    try {
        // Normalize path protocol
        const norm = (url) => url?.[0] === "/" ? window.location.origin + url : "https://" + url.replace(/^https?:\/\//, "")

        // Default orbs
        const link = new URL(norm(what.link || what))// -> Triggers an error (into the 'catch' block for local assets) if not a real url 
        const host = link.hostname
        const favicon = () => "https://www.google.com/s2/favicons?sz=64&domain=" + host// ALT (yet older DB): "http://logo.clearbit.com/" + host + "?size=128" OR (yet favicon cache issues) 'url.protocol + host + "/favicon.ico"'
        return {flag: check("url"), icon: norm(what.icon) || favicon(), name: what?.name || host, link: link.href}
    } catch (_) {
        const type = Object.keys(BADGES).find(key => BADGES[key][what]) || "other"
        return Object.assign({flag: check(type)}, BADGES[type][type === "other" ? (what ? "ko_uk_al" : "ko_ud_al") : what])
    }
}

// Component
export const Orb = ({what, /*, how*/}) => {// -> Previously known as "Insert" (Orb beta)
    const {flag, icon, name, link} = getItems(what)
    return (
        <div className={`orb orb--${flag}`}>
            {icon?.type ? icon : <img src={icon} alt={name} onError={(event) => {
                event.target.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"// ALT: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'></svg>"
            }}/>}
            <span className={`orb__link text-m`}>{link ? <Link to={link}>{name}</Link> : name}</span>
        </div>
    )
}
/* Example
  <Orb 
    //what={"eu_dk_al"}
    what={{
      name: "Google", 
      link: "google.com",
      icon: "picsum.photos/32"
    }}
    //how="inline"
  />
*/