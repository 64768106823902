import {
  useRef,
  useState,
  useEffect
} from "react"

import {
  useNavigate,
  useSearchParams
} from "react-router-dom"

// Contexts
import {
  useGlobals
} from "contexts"

// Containers
import {
    Grid,
    Layout
} from "containers"

// Components
import {
  Chart,
  Details,
  TableSort,
  Wheel,
  ChartLine,
  PieArc,
  Topbar
} from "components"
import {
  Table
} from "components_v2"
// import {
//   Tick
// } from "elements"
import {
  Orb,
  Bar,
  Score,
  Tick
} from "elements_v2"

import {
  Commaize,
  Upperize
} from "util_v2/UtilityFunctions"
import {
  ROTANA_DEMO_MODE
} from "util/const"
import {
  BADGES_STC
} from "util_v2/Mappings.const"
import {
  ROUTE_CONTENT
} from "util/Routes.const"

// Audiences-wide styling
import "./ContentAudiences.css"

// Audiences assembly
export const ContentAudiences = ({ready}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [more, seeMore] = useState([false, false])
    const navigate = useNavigate()

    const previousUrl = useRef()
    useEffect(() => {
      previousUrl.current = window.location.href
    }, [window.location.href])
    const urlChange =  window.location.href != previousUrl.current

    // --- Shared ---
    const globals = useGlobals() 

    // Unpack engagement grouping settings
    const group = globals?.context?.dropdown_selection || "_"

    // let picks = globals.get("checkboxed") || (ROTANA_DEMO_MODE 
    //   ? ["f35c", "f80c", "f55c", "m45c", "m55c", "m60c", "m20c", "m75c", "f20c", "m25c", "f40c", "m80c", "f30c", "f15c", "m50c", "f50c", "f45c", "m70c", "m15c", "f70c", "m65c", "m40c", "m30c", "f60c", "f25c", "f65c", "f75c", "m35c"]
    //   : ["m25c", "m30c", "m35c", "m40c", "m45c", "m50c", "m55c", "f25c", "f30c", "f35c", "f40c", "f45c", "f50c", "f55c"]
    // )

    const jsondata = globals?.context?.data_json_test?.postcampaigns?.["defaults"/*campaign*/]
  
    // const arr_all_obj = ROTANA_DEMO_MODE ? jsondata?.categories_chart?.[group] : jsondata?.categories_chart
    const names = (jsondata?.audiences_chart) || []
    const picks = globals.get("checkboxed") || Object.keys(names)

    let arr_all = {}
    let arr_dat = []
    if (ready) {
      /*JSON*///const names = ROTANA_DEMO_MODE ? jsondata?.audiences_chart?.[group] : jsondata?.audiences_chart

      for (let i = 0, j = 0; i < picks?.length; i++) {
        let text = picks[i].replaceAll("checkboxgroup-", "")

        // TODO: Avoid remapping of any kind [after harmonizing all the data for all sections]
        if (names?.[text]) {
          arr_all[names?.[text]?.[2]] = [names?.[text]?.[0], names?.[text]?.[1], text]
        } 
      }
      // arr_dat = rows
      /*JSON*/ arr_dat = [...jsondata?.audiences_table[group]] || []
    }




  // ------
  // Duplicated from "Content.jsx" for subsections (temp)
  let arr_arc = []
  let arr_bar = []
  let arr_top = new Array(5).fill([0, 0])
  let arr_dat_mini = []
  let obj_top = {}
  let obj_top_new = {}
  let obj_val1 = []
  let obj_val2 = []
  let obj_val3 = []
  const selectedCampaign = globals?.context?.dropdown_selection_campaigns || "all"
  const undefinedCampaign = [undefined, "allcampaigns", "all"].includes(selectedCampaign)
  const selectedSubview = globals?.context?.selected_subview || "all"
  if (ready) {
    let data = ROTANA_DEMO_MODE 
    ? 
    globals?.context?.data_json_test?.overall 
    : 
    globals?.context?.data_json_test?.postcampaigns?.defaults?.overall?.[group]  
    arr_bar = ROTANA_DEMO_MODE ? data?.scores : data?.engagement
    arr_arc = [[arr_bar[0], -1]/*, [35, 0]*/]
    const cards = data.cards
    arr_top = ROTANA_DEMO_MODE 
      ? [cards.reach, cards.sessions, cards.session_len, cards.viz_pct]
      // : [cards.sessions, cards.reach, cards.avg_view_time, cards.viewability, cards.cpm]
      : [cards.impressions, cards.unique_reach, cards.frequency, cards.avg_view_time, cards.viewability]
    arr_dat_mini = ROTANA_DEMO_MODE ? data.table : data.minitables
    obj_top = globals?.context?.data_json_test?.postcampaigns?.defaults?.details
    obj_top_new = globals?.context?.data_json_test?.postcampaigns?.registry?.[globals?.context?.dropdown_selection_campaigns]
    obj_val1 = /* Get last values from the historical series */[data?.engagement?.[data?.engagement?.length - 1], data?.ad_attention?.[data?.ad_attention?.length - 1]]
    obj_val2 = [data?.ads_sent?.[0], data?.ads_requested?.[0], data?.fill_rate?.[0]]
    obj_val3 = (new Array(/* (7days * 4weeks) + 1trailingday = */29).fill(0).concat(data?.total_sessions.sort((a, b) => a - b/* sort numerically [vs alphabetically] */))).slice(data?.total_sessions.length)
  }
  // ------


    const audience = searchParams.get("audience")
    const audience_= BADGES_STC?.audience_01?.[audience]
    const details = {
        image: audience_?.icon,
        name: audience_?.name,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.",
        percentages: [76, 76, 24]
    }


    return (

    // ---------------- DUPLICATED (to be converted into a separated layout component)

      <Layout autoscroll={urlChange} header={{
        "title": "Audiences",
        "search": ""
      }}>

      {/* {!ROTANA_DEMO_MODE && ![undefined, "allcampaigns"].includes(globals?.context?.dropdown_selection_campaigns) ? <div className="_tempDummyHeader _tempDummyHeader--v2 text-l"> */}
      {/* {![undefined, "allcampaigns"] 
      ? 
      <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">
        <span onClick={() => {
          globals.set("dropdown_selection_campaigns", "allcampaigns")
        }}>Audiences</span>
        <span>{globals?.context?.data_json_test?.postcampaigns?.defaults?.details?.name}</span>
      </div> : <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">Audiences</div>} */}


     {/* Breadcrumbs header (defaults to "All campaigns") */}
     {audience ? <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">
        <span onClick={() => {
          navigate("/content/01")// ALT (w/ non-shareable global vars instead): globals.set("dropdown_selection_campaigns", "allcampaigns")
        }}>Audiences</span>
        {audience ? <span>{audience_?.name}</span> : ""}
      </div> : <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">Audiences</div>}









      {!audience 
      ?
      <> 
      <Grid 
        content={[
          <Chart 
          text={{
            title: "",// "Impressions by Engagement",
            // subtitle: "",
            x_label: "Engagement"         
          }}
          data={arr_all}
          mode={2}
        />,
        
          ]}
          template={`
              0
              `}
          cellSize={{
            w: 800,
            h: 400
          }}
        />
        
        <Grid
          content={[
            <TableSort 
              data={arr_dat}
              stick={true}// !!!!TODO: "All" to be automatically generated if selected
              undim={{"high": 4, "medium": 5, "low": 6}[group]}
              mode={ROTANA_DEMO_MODE ? 2 : (ROUTE_CONTENT ? 5 : 2)}
              headerLabels={["Engagement score", "Top genre", "Unique visits", "Avg watch time", "Subscription rate", "Frequency of visits"]}
            />
          ]}
          template={`
              0
              `}
          cellSize={{
            w: 800, 
            h: arr_dat?.length * 56 //800
          }}
        />
      </>
      :




      

      







      <>
      <Grid
        content={[
          <Details data={details} mode={3}/>
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 0,
          h: 240
        }}
        flat={true}
      /> 
      <Grid
        content={[
          <div className="minibox-wrapper">
            <div className="minibox-head">
              <span className="text-xs-b">Engagement score</span>
              <Tick value={"10%"}/>
            </div>
            <div className={"minibox-body text-xs"}>
              {ready ? <Wheel
                data={[[69,-1]]}
                mode={1}
              /> : ""}
            </div>  
          </div>,
          <div className="minibox-wrapper minibox-wrapper-alt">
            <div className="minibox-head">
              <span className="text-xs-b">Total sessions</span>
              <Tick value={"4%"}/>
            </div>
            <div className={"minibox-body text-xs"}>
              <ChartLine data={/* TODO: Decouple text from data (the same way we do for the newer components) */
                {
                  scale: ["W1", "", "", "", "", "", "", "W2", "", "", "", "", "", "", "W3", "", "", "", "", "", "", "W4", "", "", "", "", "", "", "W5"],
                  curve: !ready ? (new Array(29).fill(-1)) : obj_val3
                }
              }/>
            </div>
          </div>,
          !ready ? "" : 
            <div className="minibox-wrapper">
              <div className="minibox-head">
                <span className="text-xs-b">Subscribers</span>
                <Tick value={"11%"}/>
              </div>
              <div className={"minibox-body"}>
                <PieArc /* pct={obj_frt[0]} val={obj_frt[1]} */ values={["62%","38%"]} percentage={obj_val2?.[2]} mode={2}/>
              </div>
            </div>
        ]} 
        template={`
            0 1 2
            `}
        cellSize={{
          w: 255,
          h: 320
        }}
      />
      
      {/*       
      <Grid
        content={[
          <div className="minibox-wrapper">
            <div className="minibox-head text-xs-b">
              <span>Top genres</span>
              <div className="minibox-head__more" onClick={() => {
                seeMore([!more?.[0], more?.[1]])
              }}>{!more?.[0] 
              ? 
                <span>See more</span> 
              : 
                <>
                  <input type="text" className="text-xs" placeholder="Search" disabled/>
                  <span>See less</span>
                </>
              }</div>  
            </div>
            <Table
              head={{
                "ticks": [],
                "width": [.19, .11, .13, .13, .13, .13, .19],
                "place": ["l", "r", "r", "r", "r", "r", "r"],
                "names": ["Titles", "Engagement", "Unique visits", "Avg watch time", "New subscribers", "Visits frequency", "Paid / Promo"]
              }}
              body={{
                "stick": false,
                "lines": !more?.[0]
                ?
                [
                  [<Orb what={{name: "Drama", link: "/content?genre=drama", icon: "https://visualpharm.com/assets/338/Drama-595b40b65ba036ed117d1b62.svg"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Thriller", link: "/content?genre=thriller", icon: "https://visualpharm.com/assets/936/Thriller-595b40b65ba036ed117d25dc.svg"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Romance", link: "/content?genre=romance", icon: "https://visualpharm.com/assets/690/Novel-595b40b65ba036ed117d20b7.svg"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>]
                ]
                :
                [
                  [<Orb what={{name: "Drama", link: "/content?genre=drama", icon: "https://visualpharm.com/assets/338/Drama-595b40b65ba036ed117d1b62.svg"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Thriller", link: "/content?genre=thriller", icon: "https://visualpharm.com/assets/936/Thriller-595b40b65ba036ed117d25dc.svg"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Romance", link: "/content?genre=romance", icon: "https://visualpharm.com/assets/690/Novel-595b40b65ba036ed117d20b7.svg"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>],
                  [<Orb what={{name: "Drama", link: "/content?genre=drama", icon: "https://visualpharm.com/assets/338/Drama-595b40b65ba036ed117d1b62.svg"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Thriller", link: "/content?genre=thriller", icon: "https://visualpharm.com/assets/936/Thriller-595b40b65ba036ed117d25dc.svg"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Romance", link: "/content?genre=romance", icon: "https://visualpharm.com/assets/690/Novel-595b40b65ba036ed117d20b7.svg"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>]
                ]
                ,
                "limit": 6
              }}
              foot={{
                "split": more?.[0]
              }}
            />
          </div>
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 320,
          h: 0
        }}
      /> */}

      <Grid
        content={[
          <div className="minibox-wrapper">
            <div className="minibox-head text-xs-b">
              <span>Top titles</span>
              <div className="minibox-head__more" onClick={() => {
                  seeMore([more?.[0], !more?.[1]])
              }}>{!more?.[1] 
              ? 
                <span>See more</span> 
              : 
                <>
                  <input type="text" className="text-xs" placeholder="Search" disabled/>
                  <span>See less</span>
                </>
              }</div>  
            </div>
            <Table
              head={{
                "ticks": [],
                "width": [.19, .10, .13, .13, .13, .13, .19],
                "place": ["l", "r", "r", "r", "r", "r", "r"],
                "names": ["Titles", "Engagement", "Unique visits", "Avg watch time", "New subscribers", "Visits frequency", "Paid / Promo"]
              }}
              body={{
                "stick": false,
                "lines": !more?.[1]
                ? 
                [
                  [<Orb what={{name: "Squid Game", link: "/content?title=squid_game", icon: "https://www.looper.com/img/gallery/the-surprising-origins-of-squid-games-iconic-doll/intro-1634060083.webp"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Game of Thrones", link: "/content?title=game_of_thrones", icon: "https://a1cf74336522e87f135f-2f21ace9a6cf0052456644b80fa06d4f.ssl.cf2.rackcdn.com/images/characters/large/2800/Tyrion-Lannister.Game-of-Thrones.webp"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Breaking Bad", link: "/content?title=breaking_bad", icon: "https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2018/01/breaking_bad_vince_gilligan_amc.jpg?w=1000&quality=55&strip=all&ssl=1"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>]
                ]
                :
                [
                  [<Orb what={{name: "Squid Game", link: "/content?title=squid_game", icon: "https://www.looper.com/img/gallery/the-surprising-origins-of-squid-games-iconic-doll/intro-1634060083.webp"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Game of Thrones", link: "/content?title=game_of_thrones", icon: "https://a1cf74336522e87f135f-2f21ace9a6cf0052456644b80fa06d4f.ssl.cf2.rackcdn.com/images/characters/large/2800/Tyrion-Lannister.Game-of-Thrones.webp"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Breaking Bad", link: "/content?title=breaking_bad", icon: "https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2018/01/breaking_bad_vince_gilligan_amc.jpg?w=1000&quality=55&strip=all&ssl=1"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>],
                  [<Orb what={{name: "Squid Game", link: "/content?title=squid_game", icon: "https://www.looper.com/img/gallery/the-surprising-origins-of-squid-games-iconic-doll/intro-1634060083.webp"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Game of Thrones", link: "/content?title=game_of_thrones", icon: "https://a1cf74336522e87f135f-2f21ace9a6cf0052456644b80fa06d4f.ssl.cf2.rackcdn.com/images/characters/large/2800/Tyrion-Lannister.Game-of-Thrones.webp"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Breaking Bad", link: "/content?title=breaking_bad", icon: "https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2018/01/breaking_bad_vince_gilligan_amc.jpg?w=1000&quality=55&strip=all&ssl=1"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>]
                ]
                ,
                "limit": 6
              }}
              foot={{
                "split": more?.[1]
              }}
            />
          </div>
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 320,
          h: 0
        }}
      />
      {/* <Grid
        content={[
          <div className="minibox-wrapper">
            <div className="minibox-head text-xs-b">
              <span>Top genres</span>
              <span className="minibox-head__more" onClick={() => navigate("/content/01")}>See more</span>  
            </div>
            <Table
              head={{
                "ticks": [],
                "width": [.19, .11, .13, .13, .13, .13, .19],
                "place": ["l", "r", "r", "r", "r", "r", "r"],
                "names": ["Titles", "Engagement", "Unique visits", "Avg watch time", "New subscribers", "Visits frequency", "Paid / Promo"]
              }}
              body={{
                "stick": false,
                "lines": [
                  [<Orb what={{name: "Drama", link: "/content?genre=drama", icon: "https://visualpharm.com/assets/338/Drama-595b40b65ba036ed117d1b62.svg"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Thriller", link: "/content?genre=thriller", icon: "https://visualpharm.com/assets/936/Thriller-595b40b65ba036ed117d25dc.svg"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Romance", link: "/content?genre=romance", icon: "https://visualpharm.com/assets/690/Novel-595b40b65ba036ed117d20b7.svg"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>]
                ],
                "limit": 3
              }}
              foot={{
                "split": false
              }}
            />
          </div>
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 320,
          h: 0
        }}
      />
      <Grid
        content={[
          <div className="minibox-wrapper">
            <div className="minibox-head text-xs-b">
              <span>Top titles</span>
              <span className="minibox-head__more" onClick={() => navigate("/content/01")}>See more</span>  
            </div>
            <Table
              head={{
                "ticks": [],
                "width": [.19, .10, .13, .13, .13, .13, .19],
                "place": ["l", "r", "r", "r", "r", "r", "r"],
                "names": ["Titles", "Engagement", "Unique visits", "Avg watch time", "New subscribers", "Visits frequency", "Paid / Promo"]
              }}
              body={{
                "stick": false,
                "lines": [
                  [<Orb what={{name: "Squid Game", link: "/content?title=squid_game", icon: "https://www.looper.com/img/gallery/the-surprising-origins-of-squid-games-iconic-doll/intro-1634060083.webp"}}/>, <Score value={28}/>, Commaize(200283), "0:53", "28%", 1.2, <Bar value={52}/>], 
                  [<Orb what={{name: "Game of Thrones", link: "/content?title=game_of_thrones", icon: "https://a1cf74336522e87f135f-2f21ace9a6cf0052456644b80fa06d4f.ssl.cf2.rackcdn.com/images/characters/large/2800/Tyrion-Lannister.Game-of-Thrones.webp"}}/>, <Score value={42}/>, Commaize(32419), "0:37", "35%", 2, <Bar value={22}/>], 
                  [<Orb what={{name: "Breaking Bad", link: "/content?title=breaking_bad", icon: "https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2018/01/breaking_bad_vince_gilligan_amc.jpg?w=1000&quality=55&strip=all&ssl=1"}}/>, <Score value={53}/>, Commaize(21604), "0:34", "33%", 3, <Bar value={22}/>]
                ],
                "limit": 3
              }}
              foot={{
                "split": false
              }}
            />
          </div>
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 320,
          h: 0
        }}
      /> */}

      <Grid
        content={[
          <Topbar 
            data={[
              {
                "name": "Unique visits",
                "data": [79000, 71411],//arr_top[0],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Avg watch time",
                "data": [89, 91],//arr_top[1],
                "unit": "s"//""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Avg content visited",
                "data": [3.31, 3],//arr_top[2],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Frequency of visits",
                "data": [1.66, 1.5],//arr_top[3],
                "unit": ""//s
              }
            ]}
          />
        ]}
        template={`
            0 1 2 3
            `}
        cellSize={{
          w: 1,
          h: 80
        }}
      />
      <Grid
        content={[
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top audiences</span>
              <span className="minibox-head__more text-l" onClick={() => navigate("/content/01")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.audiences} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top locations</span>
              <span className="minibox-head__more text-l" onClick={() => navigate("/content/02")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.locations} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top category</span>
              <span className="minibox-head__more text-l" onClick={() => navigate("/content/03")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.category} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top times</span>
              <span className="minibox-head__more text-l" onClick={() => navigate("/content/05")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.times} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top platforms</span>
              <span className="minibox-head__more text-l" onClick={() => navigate("/content/04")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.platforms} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top devices</span>
              <span className="minibox-head__more text-l" onClick={() => navigate("/content/06")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.devices} mode={3} stick={false} label={"Name"}/>
          </div>,
        ]}
        template={`
           !0 1!2
            3!4 5
            `}
        cellSize={{
          w: 1, 
          h: 300
        }}
      /> 
      </>












        }

      </Layout>
)}