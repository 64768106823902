/*
  v240513 - Franc Jerez
*/

// Personas
import PicPersonaF15c from "assets/portraits/f15c.png"
import PicPersonaF20c from "assets/portraits/f20c.png"
import PicPersonaF25c from "assets/portraits/f25c.png"
import PicPersonaF30c from "assets/portraits/f30c.png"
import PicPersonaF35c from "assets/portraits/f35c.png"
import PicPersonaF40c from "assets/portraits/f40c.png"
import PicPersonaF45c from "assets/portraits/f45c.png"
import PicPersonaF50c from "assets/portraits/f50c.png"
import PicPersonaF55c from "assets/portraits/f55c.png"
import PicPersonaF60c from "assets/portraits/f60c.png"
import PicPersonaF65c from "assets/portraits/f65c.png"
import PicPersonaF70c from "assets/portraits/f70c.png"
import PicPersonaF75c from "assets/portraits/f75c.png"
import PicPersonaF80c from "assets/portraits/f80c.png"
import PicPersonaM15c from "assets/portraits/m15c.png"
import PicPersonaM20c from "assets/portraits/m20c.png"
import PicPersonaM25c from "assets/portraits/m25c.png"
import PicPersonaM30c from "assets/portraits/m30c.png"
import PicPersonaM35c from "assets/portraits/m35c.png"
import PicPersonaM40c from "assets/portraits/m40c.png"
import PicPersonaM45c from "assets/portraits/m45c.png"
import PicPersonaM50c from "assets/portraits/m50c.png"
import PicPersonaM55c from "assets/portraits/m55c.png"
import PicPersonaM60c from "assets/portraits/m60c.png"
import PicPersonaM65c from "assets/portraits/m65c.png"
import PicPersonaM70c from "assets/portraits/m70c.png"
import PicPersonaM75c from "assets/portraits/m75c.png"
import PicPersonaM80c from "assets/portraits/m80c.png"
// OSs
import {ReactComponent as IconLinuxOS} from "assets/icons/big/os/linux.svg"
import {ReactComponent as IconMacOS} from "assets/icons/big/os/macos.svg"
import {ReactComponent as IconWindowsOS} from "assets/icons/big/os/windows.svg"
import {ReactComponent as IconAndroidOS} from "assets/icons/big/os/android.svg"
import {ReactComponent as IconIOS} from "assets/icons/big/os/apple.svg"

// Countries (ISO 3166-1 alpha 2 and/orISO  3166-2)
import {ReactComponent as IconSA} from "assets/flags/SA.svg"
import {ReactComponent as IconAE} from "assets/flags/AE.svg"
import {ReactComponent as IconDK} from "assets/flags/DK.svg"
import {ReactComponent as IconES} from "assets/flags/ES.svg"
import {ReactComponent as IconUS} from "assets/flags/US.svg"
import PicSA from "assets/icons_256/sa.webp"
import PicAE from "assets/icons_256/ae.webp"
import PicDK from "assets/icons_256/dk.webp"
import PicES from "assets/icons_256/es.webp"
import PicUS from "assets/icons_256/us.webp"
import PicDE from "assets/icons_256/de.webp"
import PicCH from "assets/icons_256/ch.webp"
import PicGB from "assets/icons_256/gb.webp"
import PicIQ from "assets/icons_256/iq.webp"
import PicKW from "assets/icons_256/kw.webp"
import PicMA from "assets/icons_256/ma.webp"

// Other
import {ReactComponent as IconUndefined} from "assets/icons/big/other/undefined.svg"

export const BADGES = {

		"persona": {
			"fe_15_ca": {icon: PicPersonaF15c, name: "Jessica Miller"},
			"fe_20_ca": {icon: PicPersonaF20c, name: "Emily Johnson"},
			"fe_25_ca": {icon: PicPersonaF25c, name: "Sarah Brown"},
			"fe_30_ca": {icon: PicPersonaF30c, name: "Jennifer Taylor"},
			"fe_35_ca": {icon: PicPersonaF35c, name: "Ashley Doe"},
		},

		"platform": {
			"al_an_al": {icon: <IconAndroidOS/>, name: "Android"},
			"al_io_al": {icon: <IconIOS/>, name: "iOS"},
			"de_wi_al": {icon: <IconWindowsOS/>, name: "Windows"},
			"de_ma_al": {icon: <IconMacOS/>, name: "MacOS"},
			"de_li_al": {icon: <IconLinuxOS/>, name: "Linux"}
		},

		"location": {
			"wa_sa_al": {icon: <IconSA/>, name: "Saudi Arabia"},
			"wa_ae_al": {icon: <IconAE/>, name: "Emirates"/* United Arab Emirates*/},
			"eu_dk_al": {icon: <IconDK/>, name: "Denmark"},
			"eu_es_al": {icon: <IconES/>, name: "Spain"},
			"aa_us_al": {icon: <IconUS/>, name: "United States", image: PicUS}
		},

		"other": {
			"ko_uk_al": {icon: <IconUndefined/>, name: "Unknown"},
			"ko_ud_al": {icon: <IconUndefined/>, name: "Undefined"}
		}
}

// TODO: Merge with shared dictionary ('BADGES' has a more consistent syntax for this yet 'BADGES_STC' is the perfect format for [from API next] extended details; similar to the "campaign registry")
export const BADGES_STC = {
	"audience_01": {
		"f15c": {"icon": PicPersonaF15c, "name": "Jessica Miller"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"f20c": {"icon": PicPersonaF20c, "name": "Emily Johnson"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"f25c": {"icon": PicPersonaF25c, "name": "Sarah Brown"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"f30c": {"icon": PicPersonaF30c, "name": "Jennifer Taylor"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"f35c": {"icon": PicPersonaF35c, "name": "Ashley Doe"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"f40c": {"icon": PicPersonaF40c, "name": "Jane Wilson"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"f45c": {"icon": PicPersonaF45c, "name": "Lisa Thompson"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"f50c": {"icon": PicPersonaF50c, "name": "Linda White"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"f55c": {"icon": PicPersonaF55c, "name": "Carol Lewis"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"f60c": {"icon": PicPersonaF60c, "name": "Ruth Walker"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"f65c": {"icon": PicPersonaF65c, "name": "Sharon Hall"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"f70c": {"icon": PicPersonaF70c, "name": "Patricia Anderson"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"f75c": {"icon": PicPersonaF75c, "name": "Susan Harris"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"f80c": {"icon": PicPersonaF80c, "name": "Betty Clark"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m15c": {"icon": PicPersonaM15c, "name": "Michael Green"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m20c": {"icon": PicPersonaM20c, "name": "David Jones"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m25c": {"icon": PicPersonaM25c, "name": "James Smith"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m30c": {"icon": PicPersonaM30c, "name": "Robert Davis"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m35c": {"icon": PicPersonaM35c, "name": "William Moore"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m40c": {"icon": PicPersonaM40c, "name": "Richard Taylor"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m45c": {"icon": PicPersonaM45c, "name": "Charles Thomas"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m50c": {"icon": PicPersonaM50c, "name": "Joseph Jackson"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m55c": {"icon": PicPersonaM55c, "name": "Christopher Harris"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m60c": {"icon": PicPersonaM60c, "name": "Daniel Martin"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m65c": {"icon": PicPersonaM65c, "name": "Paul Thompson"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m70c": {"icon": PicPersonaM70c, "name": "Mark Anderson"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m75c": {"icon": PicPersonaM75c, "name": "Donald Lewis"},// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
		"m80c": {"icon": PicPersonaM80c, "name": "Jason Scott"}// "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. Mauris venenatis, felis scelerisque aliquet lacinia, nulla nisi venenatis odio, id blandit mauris ipsum id sapien. Vestibulum malesuada orci sit amet pretium facilisis.", "metrics": [76, 76, 24]},
	},
	"location_02": {
		"sa": {"icon": PicSA, "name": "Saudi Arabia"},
		"ae": {"icon": PicAE, "name": "Arab Emirates"},
		"gb": {"icon": PicGB, "name": "United Kingdom"},
		"iq": {"icon": PicIQ, "name": "Iraq"},
		"kw": {"icon": PicKW, "name": "Kuwait"},
		"us": {"icon": PicUS, "name": "United States"},
		"ma": {"icon": PicMA, "name": "Morocco"},
		"de": {"icon": PicDE, "name": "Germany"},
		"ch": {"icon": PicCH, "name": "Switzerland"},
		"es": {"icon": PicES, "name": "Spain"},
		"dk": {"icon": PicDK, "name": "Denmark"}
	},
	"timing_05": {
		"1am": {"icon": null, "name": "1 AM"},
		"2am": {"icon": null, "name": "2 AM"},
		"3am": {"icon": null, "name": "3 AM"},
		"4am": {"icon": null, "name": "4 AM"},
		"5am": {"icon": null, "name": "5 AM"},
		"6am": {"icon": null, "name": "6 AM"},
		"7am": {"icon": null, "name": "7 AM"},
		"8am": {"icon": null, "name": "8 AM"},
		"9am": {"icon": null, "name": "9 AM"},
		"10am": {"icon": null, "name": "10 AM"},
		"11am": {"icon": null, "name": "11 AM"},
		"12am": {"icon": null, "name": "12 AM"},
		"1pm": {"icon": null, "name": "1 PM"},
		"2pm": {"icon": null, "name": "2 PM"},
		"3pm": {"icon": null, "name": "3 PM"},
		"4pm": {"icon": null, "name": "4 PM"},
		"5pm": {"icon": null, "name": "5 PM"},
		"6pm": {"icon": null, "name": "6 PM"},
		"7pm": {"icon": null, "name": "7 PM"},
		"8pm": {"icon": null, "name": "8 PM"},
		"9pm": {"icon": null, "name": "9 PM"},
		"10pm": {"icon": null, "name": "10 PM"},
		"11pm": {"icon": null, "name": "11 PM"},
		"12pm": {"icon": null, "name": "12 PM"},
	},
	"device_06": {
		"android": {"icon": "https://upload.wikimedia.org/wikipedia/commons/f/f9/Android_logo_2.svg", "name": "Android"},
		"other_device": {"icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Round_Landmark_Icon_Unknown_Manmade.svg/2048px-Round_Landmark_Icon_Unknown_Manmade.svg.png", "name": "Other device"},
		"iphone": {"icon": "https://upload.wikimedia.org/wikipedia/commons/a/a5/Apple_gray_logo.png", "name": "iPhone"},
		"windows_pc": {"icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/Gorilla-Computer-Desktop.svg/1024px-Gorilla-Computer-Desktop.svg.png", "name": "Windows PC"}
  }
}
