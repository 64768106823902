// Home-wide styling
import "./Home.css"

// Containers
import { 
    Layout
} from "containers"

let iii = 0

// Home assembly
export const Home = () => {

  console.log("Renders", iii++)
  return (
    <Layout header={{
      "title": "Home",
      "search": ""
    }}>
      <b>Work in progress...</b><br/>
      <small>This section will be hidden before a new commit is pushed</small>
    </Layout>
  )
}