import {
  useState
} from "react"

// Contexts
import {
  useGlobals
} from "contexts"

// Containers
import { 
    Grid,
    Layout
} from "containers"

// Components
import {
  TableSort,
  Chart
} from "components"

import {
  ROTANA_DEMO_MODE
} from "util/const"

// Content-wide styling
import "./ContentCategory.css"

// Content assembly
export const ContentCategory = ({ready}) => {

  // --- Shared ---
  const globals = useGlobals() 

  // Unpack engagement grouping settings
  // const group = globals?.context?.selected?.[0] || "_"
  // const group = globals.get("dropdown_selection") || "_"
  const group = globals?.context?.dropdown_selection || "_"

  // TODO: Prevent making this mapping redundant (we should either use "Foo Bar" formats at all times for labeling or have some kind of external mapping file for all components)
  const names = [{
    // "all_genres": "All Genres",
    // "action": "Action",
    // "drama": "Drama",
    // "horror": "Horror",
    // "documentaries": "Documentaries",
    // "comedies": "Comedies",
    // "romance": "Romance",
    // "sci-fi": "Sci-Fi",
    // "sports": "Sports",

    "all_content": "All content",

    "action": "Action",
    "bike": "Bike",
    "casual": "Casual",
    "minecraft": "Minecraft",
    // "other_category": "Other category",
    "puzzle": "Puzzle",
    // "racing": "Racing",
    "shooter": "Shooter",
    "simulator": "Simulator",
    "single_player": "Single player",
    "sport": "Sport",
    "unknown": "Unknown",


    "action_&_adventure": "Action & Adventure", 
    "hyper_casual": "Hyper casual", 
    "idle": "Idle", 
    "racing": "Racing", 
    "shooter_&_combat": "Shooter & Combat", 
    "simulation_&_social": "Simulation & Social", 
    "sports": "Sports", 
    "other_category": "Other category"


  },
  {
    "all_categories": "Categories",
    "unknown_category": "Unknown Category",
    "channel_overview": "Channel Overview",
    "rotana_khalijea_hd": "Rotana Khalijea HD",
    "rotana_cinema_ksa": "Rotana Cinema KSA",
    "rotana_cinema_masr": "Rotana Cinema MASR",
    "homepage": "Homepage",
    "rotana_classic": "Rotana Classic",
    "rotana_comedy": "Rotana Comedy",
    "lbc": "LBC",
    "rotana_drama": "Rotana Drama",
    "rotana_clip": "Rotana Clip",
    "dahk_wa_bass": "Dahk Wa Bass",
    "romance": "Romance",
    "al_resalah": "Al Resalah",
    "el_ressalah": "El Ressalah",
    "sayedat_alshasha": "Sayedat Alshasha",
    "al_resalah_international": "Al Resalah International"    
  }][ROTANA_DEMO_MODE ? 1 : 0]

  const jsondata = ROTANA_DEMO_MODE 
  ? 
  globals?.context?.data_json_test?.campaigns
  : 
  globals?.context?.data_json_test?.postcampaigns?.["defaults"/*campaign*/]

  let txt_sel = []
  let arr_sel = []
  let arr_dat = []

  // const arr_all_obj = ROTANA_DEMO_MODE ? jsondata?.categories_chart?.[group] : jsondata?.categories_chart
  const arr_all_obj = jsondata?.categories_chart?.[group] || []
  const picks = globals.get("checkboxed") || (

      /* First access */
      ROTANA_DEMO_MODE 
      ?
      ["unknown_category", "channel_overview", "rotana_khalijea_hd", "rotana_cinema_ksa", "rotana_cinema_masr", "homepage", "rotana_classic", "rotana_comedy", "lbc", "rotana_drama", "rotana_clip", "dahk_wa_bass", "al_resalah", "el_ressalah", "sayedat_alshasha", "al_resalah_international"]
      :
      // ["action_&_adventure", "hyper_casual", "idle", "racing", "shooter_&_combat", "simulation_&_social", "sports", "other_category", "action", "bike", "casual", "minecraft", "puzzle", "shooter", "simulator", "single_player", "sport", "unknown"]
      Object.keys(arr_all_obj)
      )

  if (ready) {

    for (let i = 0; i < picks?.length; i++) {
      let text = names?.[picks?.[i].replace("checkboxgroup-", "")]
      txt_sel.push(text)
      arr_sel.push(arr_all_obj[text.toLowerCase().replaceAll(" ", "_")])
    }
    //arr_dat = rows
    arr_dat = [...jsondata?.categories_table?.[group]] 
  }
  // --- Shared ---

  return (

    <Layout header={{
      "title": "Categories",
      "search": ""
    }}>
    
      {/* TODO: Make header component out of this */}
      {/* <div className={`text-xl _tempDummyHeader _tempDummyHeader--${group ? "link" : ""}`}>
        <span onClick={() => setGroup()}>Categories </span>
        {group &&
        <>
          <span> → </span>
          <span>{group + " engagement groups"}</span>
        </>}
      </div> */}

      {!ROTANA_DEMO_MODE && ![undefined, "allcampaigns"].includes(globals?.context?.dropdown_selection_campaigns) ? <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">
        <span onClick={() => {
          globals.set("dropdown_selection_campaigns", "allcampaigns")
        }}>Categories</span>
        {/* <span>{globals?.context?.data_json_test?.postcampaigns?.overall?.details?.name}</span>
        <span>{obj_top?.name}</span> */}
        {/* <span>{globals?.context?.data_json_test?.postcampaigns?.defaults?.overall?.[group]?.details?.name}</span> */}
        <span>{globals?.context?.data_json_test?.postcampaigns?.defaults?.details?.name}</span>
      </div> : <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">Categories</div>}


    <Grid
        content={[
          ready/* Here too to animate bars on 1st load */ ? <Chart 
          text={{
            // title: "Revenue Impact",
            // subtitle: "Statistics",
            //x_label: "",
            x_flags: txt_sel,
            y_label: ROTANA_DEMO_MODE ? "Visits" : "Reach"
          }}
          data={arr_sel}
          mode={7}
        /> : "",
        ]}
        template={`
          0
        `}
        cellSize={{
          w: 800, 
          h: 400
        }}
      />
      <Grid
        content={[
          <TableSort 
            data={arr_dat}
            stick={true}
            undim={{"high": 4, "medium": 5, "low": 6}[group]}
            mode={ROTANA_DEMO_MODE ? 2 : 2}
          />
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 800, 
          h: arr_dat?.length * 56 //680
        }}
      />

    </Layout>
)}