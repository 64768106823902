/*
  v240513 - Franc Jerez
  v240523 - Franc Jerez - More compact ticks
  v240524 - Franc Jerez - Tagless bug fix
  v240528 - Franc Jerez - Dot prefix support
*/

import {
  Counter
} from "elements"

// Styling
import "./Tick.css"

/* TODO:
   - Improve support for clock-like times (preferred b/c of uniqueness [this CG component will break otherwise] & consistency) OR give full support for ticks
*/
// Component
export const Tick = ({value, tag, dot}) => {
  const num = !Number(value) && value !== 0 ? Number(value?.replace(/[^0-9.-]/g, "")) : value
  const [gtz, ltz] = !tag ? [num > 0, num < 0] : []
  return (
    <div className={`${"tick--tag-" + tag} tick ${gtz ? "tick--up" : (ltz ? "tick--dn" : "")} text-xs`}>
      {
      tag 
      ? 
      <>
        {dot ? <span className={"tick--dot tick--dot-" + tag}></span> : ""}
        <span>{value}</span>
      </>
      :
      <>
        <span className={`tick-arrow`}>{`${gtz ? "↑" : (ltz ? "↓" : "")}`}</span>
        <Counter value={value}/>
      </>
      }
    </div>
  )
}
/* Example
*/