import {
    ArcAlt,
    Insert
} from "elements"

// Sytling
import "./WheelAltFull.css"

// TODO: Merge w/ Wheel as 'mode = 3'
export const WheelAltFull = ({data}) => {
    if (!data.length) return null

    return (
    <div className={"wheelaltfull"}>
        {/* <div className={"wheelaltfull-twins-wrapper"}> */}
            <div className={`wheelaltfull-twins`}>
                <div className="wheelaltfull-twins-arc">
                    <ArcAlt data={data}/>
                </div>
                <div className={`wheelaltfull-twins-counter text-l-b`}>
                    <Insert what={data?.[0]}/>
                    <Insert what={data?.[1]} mode={1}/>
                </div>
            </div>
        {/* </div> */}
        <div className="wheelaltfull-row text-s">
            <div></div>
            <div>Engagement</div>
            <div>{data?.[0]}</div>
            <div></div>
            <div></div>
            <div>Ad attention</div>
            <div>{data?.[1]}</div>
        </div>
    </div>
)}