import {
  useEffect,
  useState,
  useRef
} from "react"

import "./ArcAlt.css"

const trace = (angle, radius = 50) => {
  const radians = (((angle < 360 ? angle : 359.999) - 90) * Math.PI / 180.0)
  return {
    x: radius + (radius * Math.cos(radians)),
    y: radius + (radius * Math.sin(radians)),
    f: angle > 180 ? 1 : 0,
  }
}

// TODO: Merge w/ Arc when ready
export const ArcAlt = ({data}) => {

  const [coords1, setcoords1] = useState({x: 50, y: 0, f: 0})
  const [coords2, setcoords2] = useState({x: 40, y: 0, f: 0})

  const arcalt = useRef()
  const spokes = useRef()

  useEffect(() => {
    const factor = 360 / 100/* divisions */
    let startTimestamp = null
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp
      const progress = Math.min((timestamp - startTimestamp) / 300/* duration */, 1)
      setcoords1(trace(progress * data?.[0] * factor, 50))
      setcoords2(trace(progress * data?.[1] * factor, 40))
      if (progress < 1) {
        window.requestAnimationFrame(step)
      }
    }
    window.requestAnimationFrame(step)

  }, [data])

  return (
    <div className="arcalt-wrapper">
      <div ref={spokes} className={`arcalt__spokes`}>
        <svg viewBox="0 0 325 325">
          <path d="M162.539 0.809452V28.2184"/>
          <path d="M182.768 2.0821L179.333 29.275"/>
          <path d="M202.679 5.88039L195.863 32.4283"/>
          <path d="M221.957 12.1441L211.867 37.6283"/>
          <path d="M240.298 20.7747L227.093 44.7933"/>
          <path d="M257.412 31.6358L241.302 53.8101"/>
          <path d="M273.031 44.5564L254.268 64.5367"/>
          <path d="M286.906 59.3324L265.787 76.8036"/>
          <path d="M298.821 75.7311L275.678 90.4176"/>
          <path d="M308.586 93.4938L283.785 105.164"/>
          <path d="M316.048 112.34L289.98 120.81"/>
          <path d="M321.088 131.973L294.165 137.109"/>
          <path d="M323.629 152.083L296.274 153.804"/>
          <path d="M323.629 172.353L296.274 170.632"/>
          <path d="M321.089 192.463L294.165 187.327"/>
          <path d="M316.047 212.097L289.98 203.627"/>
          <path d="M308.586 230.943L283.785 219.273"/>
          <path d="M298.821 248.706L275.678 234.019"/>
          <path d="M286.906 265.104L265.787 247.633"/>
          <path d="M273.031 279.881L254.268 259.9"/>
          <path d="M257.412 292.801L241.302 270.627"/>
          <path d="M240.298 303.662L227.094 279.644"/>
          <path d="M221.957 312.293L211.867 286.809"/>
          <path d="M202.679 318.556L195.863 292.009"/>
          <path d="M182.768 322.355L179.333 295.162"/>
          <path d="M162.538 323.627V296.218"/>
          <path d="M142.308 322.355L145.744 295.162"/>
          <path d="M122.398 318.556L129.214 292.009"/>
          <path d="M103.12 312.293L113.21 286.808"/>
          <path d="M84.779 303.662L97.9834 279.644"/>
          <path d="M67.6645 292.801L83.7751 270.627"/>
          <path d="M52.0463 279.881L70.8091 259.9"/>
          <path d="M38.1706 265.104L59.2896 247.633"/>
          <path d="M26.2563 248.706L49.3984 234.019"/>
          <path d="M16.491 230.943L41.2914 219.273"/>
          <path d="M9.02918 212.097L35.0967 203.627"/>
          <path d="M3.98848 192.463L30.912 187.328"/>
          <path d="M1.44771 172.353L28.8026 170.632"/>
          <path d="M1.44795 152.083L28.8029 153.804"/>
          <path d="M3.98824 131.973L30.9117 137.109"/>
          <path d="M9.02918 112.34L35.0967 120.81"/>
          <path d="M16.4912 93.4939L41.2916 105.164"/>
          <path d="M26.256 75.7311L49.3982 90.4176"/>
          <path d="M38.1706 59.3324L59.2896 76.8036"/>
          <path d="M52.0463 44.5563L70.8091 64.5366"/>
          <path d="M67.6645 31.6358L83.7751 53.8101"/>
          <path d="M84.779 20.7746L97.9834 44.7933"/>
          <path d="M103.12 12.1442L113.21 37.6284"/>
          <path d="M122.398 5.88033L129.214 32.4282"/>
          <path d="M142.308 2.08222L145.744 29.2751"/>
        </svg>
      </div>
      <svg ref={arcalt} className={`arcalt arcalt-m`} width="100%" viewBox="0 0 100 100">

        {/* Outer curve */}
        <g>
          <path className="arcalt__path" d={`M ${50 - 0.1} 0 A 50 50 0 1 0 50 0`}/>
          <path className="arcalt__line" d={`M ${coords1?.x} ${coords1?.y} A 50 50 0 ${coords1?.f} 0 50 0`}/>
          <path className={`arcalt__dot-fg`} d={`M ${coords1?.x - 0.1} ${coords1?.y} A 50 50 0 0 0 ${coords1?.x} ${coords1?.y}`}/>
          <path className={`arcalt__dot-bg`} d={`M ${coords1?.x - 0.1} ${coords1?.y} A 50 50 0 0 0 ${coords1?.x} ${coords1?.y}`}/>
        </g>

        {/* Inner curve */}
        <g>
          <path className="arcalt__path" d={`M ${40 - 0.1} 0 A 40 40 0 1 0 40 0`}/>
          <path className="arcalt__line" d={`M ${coords2?.x} ${coords2?.y} A 40 40 0 ${coords2?.f} 0 40 0`}/>
          <path className={`arcalt__dot-fg`} d={`M ${coords2?.x - 0.1} ${coords2?.y} A 40 40 0 0 0 ${coords2?.x} ${coords2?.y}`}/>
          <path className={`arcalt__dot-bg`} d={`M ${coords2?.x - 0.1} ${coords2?.y} A 40 40 0 0 0 ${coords2?.x} ${coords2?.y}`}/>
        </g>
      </svg>
    </div>
  )}