// Help-wide styling
import "./Help.css"

// Containers
import { 
    Layout
} from "containers"

// Help assembly
export const Help = () => {
    return (
      <Layout header={{
        "title": "Help",
        "search": ""
      }}>
        <b>Work in progress...</b><br/>
        <small>This section will be hidden before a new commit is pushed</small>
      </Layout>
)}