import { 
    useEffect,
    useState,
    useRef
} from "react"

import { 
    Dropdown
} from "elements"

import {
    ROTANA_DEMO_MODE,
    ENABLE_ROUTE_CONTENT
} from "util/const"

// Contexts
import {
    useGlobals
  } from "contexts"

// import { ReactComponent as MenuIcon } from 'assets/Menu.svg'
import { ReactComponent as NucleusIcon } from 'assets/logo-icon.svg'

// // Legacy
// import dummySearchBar from "assets/_poc/1296x68_search_1_k.png"

// Styling
import "./Header.css"

// TODO: If selection is waiting, set everyone 'disabled' flag to 'true'
export const Header = ({ content, callback, mode }) => {
    const sidebarHidden = useRef(true)
    const mutableButton = useRef(null)

    const globals = useGlobals()
    // const postcampaigns = globals?.context?.data_json_test?.postcampaigns

    // console.log("CAMBIO", globals?.context?.dropdown_selection_campaigns)

    // useEffect(() => {

    //     console.log("CHANGED", globals?.context?.xxx, mode)
    //     // const [xxx, setXXX] = useState()
      
    //     // console.log("TEST 1", postcampaigns?.registry)
    //     // useEffect(() => {
    //     //     console.log("TEST 2!!!", postcampaigns?.registry)
    //     //     setXXX(Date.now())
    //     // }, [content])
  
        
    //   }, [globals?.context?.xxx])

      
    return (
        <div className={`header ${content.search === "breadcrumbs-temp" ? "" : "header--temp-dropdown-filters"}`}>
            <div className='header__icon__wrapper'>
                <NucleusIcon/>
            </div>
            <div className="header__h2__wrapper">
                {
                typeof content.title === "string"
                ? 
                <div className={"header__h2__wrapper__options"}>
                    {ENABLE_ROUTE_CONTENT
                    ?
                    <>
                        <Dropdown mode={0}/>
                        <Dropdown mode={1}/>
                        <Dropdown mode={2}/>
                        <Dropdown mode={9}/>
                        <Dropdown mode={3}/>
                        <Dropdown mode={4}/>
                        <Dropdown mode={5} disabled={true}/>
                        <Dropdown mode={6}/>
                        <Dropdown mode={7} disabled={true}/>
                    </>
                    :
                    <>
                        <Dropdown mode={0}/>
                        <Dropdown mode={1}/>
                        {ROTANA_DEMO_MODE ? "" : <Dropdown mode={8} restart={globals?.context?.xxx}/>/* TODO: pass 'data' prop from campaigns static JSON's collection */}
                        <Dropdown mode={2}/>
                        <Dropdown mode={3} disabled={true}/>
                        <Dropdown mode={4} disabled={true}/>
                        <Dropdown mode={5} disabled={true}/>
                        <Dropdown mode={6} disabled={true}/>
                        <Dropdown mode={7} disabled={true}/>
                    </>
                    }

                </div> 
                // ALT 1: <img className="_tempDummyHeaderImage" src={dummySearchBar/*content.search*/}/>
                // ALT 2: <h2 className='header__content'>{content.title}</h2>
                :
                content.title
                }
            </div>
            <div className={`header__menu__wrapper ${sidebarHidden.current ? "header__menu__wrapper--toggle" : ""}`} onClick={() => {
                mutableButton.current.classList = "burger-animate " + (mutableButton.current.classList.contains("burger-close") ? "burger-menu" : "burger-close")
                callback(sidebarHidden.current = !sidebarHidden.current)
                }}>

                {/* /* TODO: Make a element component out of this block */}
                <div className="burger">
                    <div className={sidebarHidden.current ? "" : "burger-overlay"}></div>
                    <div ref={mutableButton} className="burger-menu"></div>
                </div>
            </div>
        </div>
    )
}

// === Legacy
// export const Header = ({ content, callback }) => {

//     return (
//         <div className="header">
//             <div className="header__h2__wrapper">
//                 {/* <h2 className={`header__content`}>{content.content}</h2>  */}
//                 {dummySearchBar //content.search
//                     ? 
//                     // <img className="_tempDummyHeaderImage" src={dummySearchBar/*content.search*/}/>
//                     <></>
//                     // <h2 className={`header__content header__content__loading`}>Loading...</h2> 
//                     : 
//                     <h2 className={`__header__content`}>{content.content}</h2> 
//                 }
//             </div>
//         </div>
//     )
// }




