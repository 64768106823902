const {
	REACT_APP_GA_CLIENT_ID,
	REACT_APP_TYPE,
	REACT_APP_WITH_CONSENT,
	REACT_APP_WITH_CUSTOM_USER_ID,
	REACT_APP_WITH_IS_SPA,

	REACT_APP_SHOW_ROUTE_HIGHLIGHTS,
	REACT_APP_ENABLE_ROUTE_HIGHLIGHTS,
	REACT_APP_SHOW_ROUTE_AUDIENCES,
	REACT_APP_ENABLE_ROUTE_AUDIENCES,
	REACT_APP_SHOW_ROUTE_PAYWALL,
	REACT_APP_ENABLE_ROUTE_PAYWALL,
	REACT_APP_SHOW_ROUTE_CSV,
	REACT_APP_ENABLE_ROUTE_CSV,
	REACT_APP_SHOW_ROUTE_UPLOAD_PERSONA,
	REACT_APP_ENABLE_ROUTE_UPLOAD_PERSONA,
	REACT_APP_SHOW_ROUTE_STATISTICS_REPORT,
	REACT_APP_ENABLE_ROUTE_STATISTICS_REPORT,
	REACT_APP_SHOW_N_ENABLE_FILTER,
	REACT_APP_SHOW_N_ENABLE_ALL,
	REACT_APP_ENABLE_ROUTE_LEGACY_LOGIN,
	REACT_APP_ENABLE_STRICT_MODE,
	REACT_APP_ENABLE_CATALOG,

	// V2 routes
	REACT_APP_ENABLE_ROUTE_HOME,
	REACT_APP_ENABLE_ROUTE_SUBSCRIPTIONS,
	REACT_APP_ENABLE_ROUTE_VIDEO,
	REACT_APP_ENABLE_ROUTE_HELP,
	REACT_APP_ENABLE_ROUTE_SETTINGS,
	REACT_APP_ENABLE_ROUTE_CALCULATOR,

	// Temp
	REACT_APP_ROTANA_DEMO_MODE,

	// Subsections (campaigns)
	REACT_APP_ENABLE_ROUTE_CAMPAIGNS,
	REACT_APP_ENABLE_ROUTE_CAMPAIGNS_OVERVIEW,
	REACT_APP_ENABLE_ROUTE_CAMPAIGNS_LOCATION,
	REACT_APP_ENABLE_ROUTE_CAMPAIGNS_AUDIENCES,
	REACT_APP_ENABLE_ROUTE_CAMPAIGNS_INTEREST_CATEGORIES,
	REACT_APP_ENABLE_ROUTE_CAMPAIGNS_TIMING,
	REACT_APP_ENABLE_ROUTE_CAMPAIGNS_DEVICE_PERFORMANCE,
	REACT_APP_ENABLE_ROUTE_CAMPAIGNS_IAB,
	REACT_APP_SHOW_ROUTE_CAMPAIGNS,
	REACT_APP_SHOW_ROUTE_CAMPAIGNS_OVERVIEW,
	REACT_APP_SHOW_ROUTE_CAMPAIGNS_LOCATION,
	REACT_APP_SHOW_ROUTE_CAMPAIGNS_AUDIENCES,
	REACT_APP_SHOW_ROUTE_CAMPAIGNS_INTEREST_CATEGORIES,
	REACT_APP_SHOW_ROUTE_CAMPAIGNS_TIMING,
	REACT_APP_SHOW_ROUTE_CAMPAIGNS_DEVICE_PERFORMANCE,
	REACT_APP_SHOW_ROUTE_CAMPAIGNS_IAB,

	// Subsections (content)
	REACT_APP_ENABLE_ROUTE_CONTENT,
	REACT_APP_ENABLE_ROUTE_CONTENT_OVERVIEW,
	REACT_APP_ENABLE_ROUTE_CONTENT_LOCATION,
	REACT_APP_ENABLE_ROUTE_CONTENT_AUDIENCES,
	REACT_APP_ENABLE_ROUTE_CONTENT_INTEREST_CATEGORIES,
	REACT_APP_ENABLE_ROUTE_CONTENT_TIMING,
	REACT_APP_ENABLE_ROUTE_CONTENT_DEVICE_PERFORMANCE,
	REACT_APP_ENABLE_ROUTE_CONTENT_IAB,
	REACT_APP_ENABLE_ROUTE_CONTENT_PLATFORM,
	REACT_APP_SHOW_ROUTE_CONTENT,
	REACT_APP_SHOW_ROUTE_CONTENT_OVERVIEW,
	REACT_APP_SHOW_ROUTE_CONTENT_LOCATION,
	REACT_APP_SHOW_ROUTE_CONTENT_AUDIENCES,
	REACT_APP_SHOW_ROUTE_CONTENT_INTEREST_CATEGORIES,
	REACT_APP_SHOW_ROUTE_CONTENT_TIMING,
	REACT_APP_SHOW_ROUTE_CONTENT_DEVICE_PERFORMANCE,
	REACT_APP_SHOW_ROUTE_CONTENT_IAB,
	REACT_APP_SHOW_ROUTE_CONTENT_PLATFORM,

	// Summary
	REACT_APP_ENABLE_ROUTE_SUMMARY,
	REACT_APP_SHOW_ROUTE_SUMMARY

} = process.env

export const GA_CLIENT_ID = REACT_APP_GA_CLIENT_ID

// App types
export const APP_TYPE = REACT_APP_TYPE

export const APP_TYPE_ADMIN = "ADMIN"

// Expose routes
// Show = visible
// Enable = active
export const SHOW_ROUTE_HIGHLIGHTS = +REACT_APP_SHOW_ROUTE_HIGHLIGHTS === 1
export const ENABLE_ROUTE_HIGHLIGHTS = +REACT_APP_ENABLE_ROUTE_HIGHLIGHTS === 1
export const SHOW_ROUTE_AUDIENCES = +REACT_APP_SHOW_ROUTE_AUDIENCES === 1
export const ENABLE_ROUTE_AUDIENCES = +REACT_APP_ENABLE_ROUTE_AUDIENCES === 1
export const SHOW_ROUTE_PAYWALL = +REACT_APP_SHOW_ROUTE_PAYWALL === 1
export const ENABLE_ROUTE_PAYWALL = +REACT_APP_ENABLE_ROUTE_PAYWALL === 1
export const SHOW_ROUTE_CSV = +REACT_APP_SHOW_ROUTE_CSV === 1
export const ENABLE_ROUTE_CSV = +REACT_APP_ENABLE_ROUTE_CSV === 1
export const ENABLE_ROUTE_UPLOAD_PERSONA = +REACT_APP_SHOW_ROUTE_UPLOAD_PERSONA === 1
export const SHOW_ROUTE_UPLOAD_PERSONA = +REACT_APP_ENABLE_ROUTE_UPLOAD_PERSONA === 1
export const ENABLE_ROUTE_STATISTICS_REPORT = +REACT_APP_SHOW_ROUTE_STATISTICS_REPORT === 1
export const SHOW_ROUTE_STATISTICS_REPORT = +REACT_APP_ENABLE_ROUTE_STATISTICS_REPORT === 1
export const SHOW_N_ENABLE_FILTER = +REACT_APP_SHOW_N_ENABLE_FILTER === 1
export const SHOW_N_ENABLE_ALL = +REACT_APP_SHOW_N_ENABLE_ALL === 1
export const ENABLE_ROUTE_LEGACY_LOGIN = +REACT_APP_ENABLE_ROUTE_LEGACY_LOGIN === 1

// V2 routes
export const ENABLE_ROUTE_HOME = +REACT_APP_ENABLE_ROUTE_HOME === 1
// export const ENABLE_ROUTE_CAMPAIGNS_INTEREST_CATEGORIES = +REACT_APP_ENABLE_ROUTE_CAMPAIGNS_INTEREST_CATEGORIES === 1
// export const ENABLE_ROUTE_CAMPAIGNS_DEVICE_PERFORMANCE = +REACT_APP_ENABLE_ROUTE_CAMPAIGNS_DEVICE_PERFORMANCE === 1

export const ENABLE_ROUTE_VIDEO = +REACT_APP_ENABLE_ROUTE_VIDEO === 1
export const ENABLE_ROUTE_HELP = +REACT_APP_ENABLE_ROUTE_HELP === 1
export const ENABLE_ROUTE_SETTINGS = +REACT_APP_ENABLE_ROUTE_SETTINGS === 1
export const ENABLE_ROUTE_CALCULATOR = +REACT_APP_ENABLE_ROUTE_CALCULATOR === 1

// Util
export const WITH_CONSENT = +REACT_APP_WITH_CONSENT === 1
export const WITH_CUSTOM_USER_ID = +REACT_APP_WITH_CUSTOM_USER_ID === 1
export const WITH_SPA = +REACT_APP_WITH_IS_SPA === 1

// Misc
export const ENABLE_STRICT_MODE = +REACT_APP_ENABLE_STRICT_MODE === 1
export const ENABLE_CATALOG = +REACT_APP_ENABLE_CATALOG === 1
export const ENABLE_ROUTE_SUBSCRIPTIONS = +REACT_APP_ENABLE_ROUTE_SUBSCRIPTIONS === 1

// Temp
export const ROTANA_DEMO_MODE = +REACT_APP_ROTANA_DEMO_MODE === 1

// Subsections (campaigns)
export const ENABLE_ROUTE_CAMPAIGNS = +REACT_APP_ENABLE_ROUTE_CAMPAIGNS === 1
export const ENABLE_ROUTE_CAMPAIGNS_OVERVIEW = +REACT_APP_ENABLE_ROUTE_CAMPAIGNS_OVERVIEW === 1
export const ENABLE_ROUTE_CAMPAIGNS_LOCATION = +REACT_APP_ENABLE_ROUTE_CAMPAIGNS_LOCATION === 1
export const ENABLE_ROUTE_CAMPAIGNS_AUDIENCE = +REACT_APP_ENABLE_ROUTE_CAMPAIGNS_AUDIENCES === 1
export const ENABLE_ROUTE_CAMPAIGNS_CATEGORY = +REACT_APP_ENABLE_ROUTE_CAMPAIGNS_INTEREST_CATEGORIES === 1
export const ENABLE_ROUTE_CAMPAIGNS_TIMING = +REACT_APP_ENABLE_ROUTE_CAMPAIGNS_TIMING === 1
export const ENABLE_ROUTE_CAMPAIGNS_DEVICE = +REACT_APP_ENABLE_ROUTE_CAMPAIGNS_DEVICE_PERFORMANCE === 1
export const ENABLE_ROUTE_CAMPAIGNS_IAB = +REACT_APP_ENABLE_ROUTE_CAMPAIGNS_IAB === 1
export const SHOW_ROUTE_CAMPAIGNS = +REACT_APP_SHOW_ROUTE_CAMPAIGNS === 1
export const SHOW_ROUTE_CAMPAIGNS_OVERVIEW = +REACT_APP_SHOW_ROUTE_CAMPAIGNS_OVERVIEW === 1
export const SHOW_ROUTE_CAMPAIGNS_LOCATION = +REACT_APP_SHOW_ROUTE_CAMPAIGNS_LOCATION === 1
export const SHOW_ROUTE_CAMPAIGNS_AUDIENCE = +REACT_APP_SHOW_ROUTE_CAMPAIGNS_AUDIENCES === 1
export const SHOW_ROUTE_CAMPAIGNS_CATEGORY = +REACT_APP_SHOW_ROUTE_CAMPAIGNS_INTEREST_CATEGORIES === 1
export const SHOW_ROUTE_CAMPAIGNS_TIMING = +REACT_APP_SHOW_ROUTE_CAMPAIGNS_TIMING === 1
export const SHOW_ROUTE_CAMPAIGNS_DEVICE = +REACT_APP_SHOW_ROUTE_CAMPAIGNS_DEVICE_PERFORMANCE === 1
export const SHOW_ROUTE_CAMPAIGNS_IAB = +REACT_APP_SHOW_ROUTE_CAMPAIGNS_IAB === 1

// Subsections (content)
export const ENABLE_ROUTE_CONTENT = +REACT_APP_ENABLE_ROUTE_CONTENT === 1
export const ENABLE_ROUTE_CONTENT_OVERVIEW = +REACT_APP_ENABLE_ROUTE_CONTENT_OVERVIEW === 1
export const ENABLE_ROUTE_CONTENT_LOCATION = +REACT_APP_ENABLE_ROUTE_CONTENT_LOCATION === 1
export const ENABLE_ROUTE_CONTENT_AUDIENCE = +REACT_APP_ENABLE_ROUTE_CONTENT_AUDIENCES === 1
export const ENABLE_ROUTE_CONTENT_CATEGORY = +REACT_APP_ENABLE_ROUTE_CONTENT_INTEREST_CATEGORIES === 1
export const ENABLE_ROUTE_CONTENT_TIMING = +REACT_APP_ENABLE_ROUTE_CONTENT_TIMING === 1
export const ENABLE_ROUTE_CONTENT_DEVICE = +REACT_APP_ENABLE_ROUTE_CONTENT_DEVICE_PERFORMANCE === 1
export const ENABLE_ROUTE_CONTENT_IAB = +REACT_APP_ENABLE_ROUTE_CONTENT_IAB === 1
export const ENABLE_ROUTE_CONTENT_PLATFORM = +REACT_APP_ENABLE_ROUTE_CONTENT_PLATFORM === 1
export const SHOW_ROUTE_CONTENT = +REACT_APP_SHOW_ROUTE_CONTENT === 1
export const SHOW_ROUTE_CONTENT_OVERVIEW = +REACT_APP_SHOW_ROUTE_CONTENT_OVERVIEW === 1
export const SHOW_ROUTE_CONTENT_LOCATION = +REACT_APP_SHOW_ROUTE_CONTENT_LOCATION === 1
export const SHOW_ROUTE_CONTENT_AUDIENCE = +REACT_APP_SHOW_ROUTE_CONTENT_AUDIENCES === 1
export const SHOW_ROUTE_CONTENT_CATEGORY = +REACT_APP_SHOW_ROUTE_CONTENT_INTEREST_CATEGORIES === 1
export const SHOW_ROUTE_CONTENT_TIMING = +REACT_APP_SHOW_ROUTE_CONTENT_TIMING === 1
export const SHOW_ROUTE_CONTENT_DEVICE = +REACT_APP_SHOW_ROUTE_CONTENT_DEVICE_PERFORMANCE === 1
export const SHOW_ROUTE_CONTENT_IAB = +REACT_APP_SHOW_ROUTE_CONTENT_IAB === 1
export const SHOW_ROUTE_CONTENT_PLATFORM = +REACT_APP_SHOW_ROUTE_CONTENT_PLATFORM === 1

// All in one (summary)
export const ENABLE_ROUTE_SUMMARY = +REACT_APP_ENABLE_ROUTE_SUMMARY === 1
export const SHOW_ROUTE_SUMMARY = +REACT_APP_SHOW_ROUTE_SUMMARY === 1