// Contexts
import {
  useGlobals
} from "contexts"

// Containers
import { 
    Grid,
    Layout
} from "containers"

// Components
import {
  TableSort,
  Chart
} from "components"

import {
  ROTANA_DEMO_MODE
} from "util/const"

// Timing-wide styling
import "./CampaignsTiming.css"

// Timing assembly
export const CampaignsTiming = ({ready}) => {

  // --- Shared ---
  const globals = useGlobals() 

  // Unpack engagement grouping settings
  // const [group, period] = globals?.context?.selected || ["_", "am"]
  //const group = globals.get("dropdown_selection") || "_"
  const group = globals?.context?.dropdown_selection || "_"

  //const period = globals.get("temp_selected") || "am"
  const period = globals?.context?.temp_selected || "am"

  // const group = globals?.context?.checkboxed || "_"

  // ...
  const periodShift = period === "am" ? 0 : 12

  // Repack data for chart
  // const picks = globals.get("checkboxed") || ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]/* First access */
  /*JSON*/
  const picks = globals.get("checkboxed") || ["1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", "10am", "11am", "12am", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", "8pm", "9pm", "10pm", "11pm", "12pm"]/* First access */
  //const picks = globals?.context?.checkboxed || ["1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", "10am", "11am", "12am", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", "8pm", "9pm", "10pm", "11pm", "12pm"]/* First access */

  const jsondata = ROTANA_DEMO_MODE 
  ? 
  globals?.context?.data_json_test?.campaigns
  : 
  globals?.context?.data_json_test?.postcampaigns?.["defaults"/*campaign*/]

  let flg_sel = []
  let arr_sel = []
  let arr_dat = []
  if (ready) {
    /*JSON*/const arr_all = ROTANA_DEMO_MODE 
      ? jsondata?.timing_chart?.[group].slice(0 + periodShift, 12 + periodShift)
      : jsondata?.timing_chart?.[group].slice(0 + periodShift, 12 + periodShift)
    for (let i = 0; i < picks?.length; i++) {
      let code = picks[i].replaceAll(/[^\d]/g, "")
      if (picks[i].includes(period)) {
        flg_sel.push(code)
        arr_sel.push(arr_all[code - 1])
      }
    }
    // arr_dat = rows
    /*JSON*/ arr_dat = [...jsondata?.timing_table?.[group]]/*?.reverse()*/
  }

  return (


    <Layout header={{
      "title": "Timing",
      "search": ""
    }}>

      {!ROTANA_DEMO_MODE && ![undefined, "allcampaigns"].includes(globals?.context?.dropdown_selection_campaigns) ? <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">
        <span onClick={() => {
          globals.set("dropdown_selection_campaigns", "allcampaigns")
        }}>Timing</span>
        {/* <span>{globals?.context?.data_json_test?.postcampaigns?.overall?.details?.name}</span>
        <span>{obj_top?.name}</span> */}
        {/* <span>{globals?.context?.data_json_test?.postcampaigns?.defaults?.overall?.[group]?.details?.name}</span> */}
        <span>{globals?.context?.data_json_test?.postcampaigns?.defaults?.details?.name}</span>
      </div> : <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">Timing</div>}

      
      <Grid
        content={[
              
          <Chart 
          text={{
            // title: "Timing",
            // subtitle: "", 
            x_label: "Time of day",
            x_flags: flg_sel, // ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"], 
            y_label: "Day of week",
            y_flags: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
          }}
          data={arr_sel}
          mode={3}

          />,
        ]}
        template={`
          0
        `}
        cellSize={{
          w: 800, 
          h: 500// ORI: 400
        }}
        disabled={ROTANA_DEMO_MODE}
      />
      <Grid
        content={[
          <TableSort 
          
          data={arr_dat}

          stick={true}
          // undim={{"high": 4, "medium": 5, "low": 6}[group]}
          slice={{"am": [0, 12], "pm": [12, 24]}[period]} // TODO: Just list the number of items per page
          mode={ROTANA_DEMO_MODE ? 2 : 2}
          split={12}
          />
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 800, 
          h: 700
        }}
      />

    </Layout>
)}